import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../Components/Header';
import Content from './Content';
import MainLayout from '../../Components/MainLayout';

export default function CreateAccount() {
  return (
    <MainLayout>
      <div className="w-11/12 lg:w-1/3 sm:w-1/2 mt-5 grow">
        <Content />
      </div>
    </MainLayout>
  );
}
