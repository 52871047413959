import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { GetProductDetails, GetVendorDetails } from '../../../API/APIRequests';
import Footer from '../../../Components/Footer';
import Header from '../../../Components/Header';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import Content from './Content';
import MainLayout from '../../../Components/MainLayout';

function ProductDetails(props) {
  const { i18n } = useTranslation();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const [ProductDetails, SetVendorDetails] = useState({});
  const { id } = useParams();

  useEffect(() => {
    if (UserSelectedData.Country) {
      GetProductDetails(
        {
          countryID: UserSelectedData.Country.id,
          data: { item_id: id },
        },
        (res) => {
          SetVendorDetails(res);
        }
      );
    }
  }, [i18n.language, UserSelectedData.Country]);

  useEffect(() => {
    console.log('ProductDetails', ProductDetails);
  }, [ProductDetails]);

  return (
    <MainLayout showCart={true} Cat='Store'>
      {ProductDetails.success ? (
        <div className="lg:w-1/3 grow">
          <Content data={ProductDetails} />
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </MainLayout>
  );
}

export default ProductDetails;
