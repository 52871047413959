import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import LoadingSpinner from '../../Components/LoadingSpinner';
import { ToggleShowHideAction } from '../../Redux/Actions';
import { mainUrl } from '../../Redux/BaseUrls';
import { CheckDateIsNotPassed, ModifyDateFormat } from '../../Utils/Utils';
import Content from './Content';
import MainLayout from '../../Components/MainLayout';

export default function ReviewOrder() {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const [Data, SetData] = useState({ VenueDetails: {}, OrderDetails: {} });

  const GetOrderDetailsReq = (date) => {
    mainUrl
      .get(`venue-details/${UserSelectedData.VenueData.VenueId}`, {
        headers: {
          country: UserSelectedData.Country.id,
          lang: i18n.language,
        },
        params: {
          date: date,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        if (data.success === false) {
          if (data.status === '201') {
            navigate('/SelectDate');
          } else if (data.status === '404') {
            navigate('/notfound');
          }
        } else {
          SetData((prev) => ({ ...prev, VenueDetails: data }));
        }
      });
  };

  const GetProccedBookingReq = (date) => {
    mainUrl
      .get(`venue/proceed-to-booking`, {
        headers: {
          country: UserSelectedData.Country.id,
          lang: i18n.language,
        },
        params: {
          venue_id: UserSelectedData.VenueData.VenueId,
          time: UserSelectedData.VenueData.Time,
          date: date,
        },
      })
      .then((response) => response.data)
      .then((data) => SetData((prev) => ({ ...prev, OrderDetails: data })));
  };

  useEffect(() => {
    // check if time is selected
    if (UserSelectedData.VenueData) {
      if (UserSelectedData.VenueData?.Time) {
        if (UserSelectedData.VenueData) {
          // modify date format
          let date = ModifyDateFormat(UserSelectedData.SelectedDate);
          GetOrderDetailsReq(date);
          GetProccedBookingReq(date);
        } else {
          navigate('/Venues');
        }
      } else {
        navigate(`/Venues/${UserSelectedData.VenueData.VenueId}`);
      }
    } else {
      navigate('/Venues');
    }
  }, [i18n.language]);

  useEffect(() => {
    if (
      Data.OrderDetails.success === false ||
      Data.VenueDetails.success === false
    ) {
      navigate(`/Venues/${UserSelectedData.VenueData.VenueId}`);
    }
  }, [Data]);

  useEffect(() => {
    console.log(Data);
  }, [Data]);

  console.log('Data', Data);

  return (
    <MainLayout Cat='Sports'>
      {Data.VenueDetails.success && Data.OrderDetails.success ? (
        <div className="w-11/12 lg:w-1/3 sm:w-1/2 mt-5 grow">
          <Content
            Details={Data.VenueDetails}
            orderDetails={Data.OrderDetails}
          />
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </MainLayout>
  );
}
