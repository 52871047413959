import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as LeftArrow } from '../Assets/Icons/LeftArrow.svg';
import { ReactComponent as RightArrow } from '../Assets/Icons/RightArrow.svg';
import { useSelector } from 'react-redux';

export default function BackBTN({text=''}) {
  const { Lang } = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const navigate = useNavigate();
  return (
    <div className="flex items-center mb-5">
      <div className="w-fit cursor-pointer" onClick={() => navigate(-1)}>
        {Lang === 'ar' ? (
          <RightArrow width="9.353" height="13.102" />
        ) : (
          <LeftArrow width="9.353" height="13.102" />
        )}
      </div>
      <div className='w-full'>{text}</div>
    </div>
  );
}
