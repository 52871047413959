import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BlackClock } from '../Assets/Icons/BlackClock.svg';
import { ReactComponent as Trunk } from '../Assets/Icons/Store/Trunk.svg';

function StoreItemCard(props) {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        props.vendor
          ? navigate(`/VendorDetails/${props.data.id}`)
          : navigate(`/ProductDetails/${props.data.id}`);
      }}
      className={
        'flex flex-col justify-between relative rounded-lg shadow-md cursor-pointer'
      }
    >
      <div>
        {props.data.image && (
          <img
            className="top-0 rounded-t-lg h-20 w-full object-cover"
            src={props.data.image}
            alt={props.data.name}
          />
        )}

        {props.data.logo && (
          <img
            className="top-0 rounded-t-lg h-20 w-full object-cover"
            src={props.data.logo}
            alt={props.data.name}
          />
        )}

        <div className="p-2">
          <p className="md:truncate text-start text-xs text-primary_BG">
            {props.data.name}
          </p>
          <p className="md:truncate text-start text-xs text-DarkGrey">
            {props.data.categories}
          </p>
        </div>
      </div>

      {props.data.price && (
        <div
          onClick={() => navigate(`/ProductDetails/${props.data.id}`)}
          className="cursor-pointer flex justify-between items-center text-sm text-white rounded-b-lg bg-primary_BG px-2 py-px"
        >
          <p>{props.data.price}</p>
          <p>+</p>
        </div>
      )}

      {(props.data.delivery_fee || props.data.delivery_time) && (
        <div className="flex justify-between items-center gap-x-1 text-xs rounded-b-lg p-2">
          <div
            className={
              props.data.delivery_time ? 'flex items-center gap-x-1' : 'hidden'
            }
          >
            <BlackClock width="12.967" height="12.967" />
            <p className="whitespace-nowrap">{props.data.delivery_time}</p>
          </div>

          <div
            className={
              props.data.delivery_time ? 'flex items-center gap-x-1' : 'hidden'
            }
          >
            <Trunk width="18.303" height="10.919" />
            <p className="whitespace-nowrap">{props.data.delivery_fee}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default StoreItemCard;
