import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { ToggleShowHideAction } from '../Redux/Actions';

export default function CustomAlert() {
  // warning , info , success
  const ShowAlert = useSelector((state) => state.MainReducer.ShowAlert);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(ToggleShowHideAction({}));
    ShowAlert.onclose !== undefined && ShowAlert.onclose();
  };

  return (
    <Snackbar
      open={ShowAlert.Open ? ShowAlert.Open : false}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}

      // message="Note archived"
      // action={action}
    >
      <Alert
        onClose={handleClose}
        variant="outlined"
        severity={ShowAlert.Type && ShowAlert.Type}
        sx={{ width: '100%', bgcolor: 'white' }}
      >
        {ShowAlert.Msg ? ShowAlert.Msg : ''}
      </Alert>
    </Snackbar>
  );
}
