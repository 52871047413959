import React from 'react';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';

function ChangeVendorModal({
  OpenModal = true,
  OnClose = {},
  ChangeVendor = {},
}) {
  const { t } = useTranslation();

  const HandelClose = () => {};

  return (
    <Dialog onClose={OnClose} open={OpenModal} maxWidth="xs">
      <div className="flex flex-col items-center rounded-3xl p-5">
        <div className="mt-5">
          <img
            alt="change branch"
            src={require('../../../Assets/Images/Store/ChangeBranch.png')}
          />
        </div>
        <p className="font-semibold mt-2 mb-5">
          {t('You’re about to change the branch')}
        </p>
        <p className="text-center">
          {t(
            "Changing the Store might result in removing the items from your cart because the new selected store deliver's separately"
          )}
        </p>
        <div className="flex justify-between w-full pt-5 gap-x-2 px-0 lg:px-5">
          <button onClick={() => OnClose()} className="text-primary_BG">
            {t('Cancel')}
          </button>
          <button
            onClick={() => {
              ChangeVendor();
              OnClose();
            }}
            className="text-CustomRed"
          >
            {t('Change')}
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export default ChangeVendorModal;
