import React, { useState } from 'react';
import BackBTN from '../../../Components/BackBTN';
import { ReactComponent as BlackClock } from '../../../Assets/Icons/BlackClock.svg';
import { ReactComponent as Trunk } from '../../../Assets/Icons/Store/Trunk.svg';
import { useParams } from 'react-router-dom';
import CategoryItems from './CategoryItems';
import Scrollspy from 'react-scrollspy';

function Content(props) {
  const { id } = useParams();

  const GetCatsIds = () => {
    let ids = [];

    props.cats.data.forEach((elem) => {
      ids.push(elem.id);
    });

    console.log(ids);

    return ids;
  };

  return (
    <div>
      <BackBTN />

      <div className="h-32 my-3">
        <img
          className="h-full w-full"
          src={props.data.data.banner}
          onError={(e) => {
            e.onerror = null; // prevents looping
            e.target.src = '../../../Assets/Images/img-not-found.png';
          }}
        />
      </div>

      {/* vendor info */}
      <div className="flex items-center gap-x-2">
        <img
          className="w-16 h-16"
          src={props.data.data.logo}
          onError={(e) => {
            e.onerror = null; // prevents looping
            e.target.src = '../../../Assets/Images/img-not-found.png';
          }}
        />
        <div className="flex justify-between items-center w-full">
          <div>
            <p className="text-start truncate text-xs text-primary_BG mb-3">
              {props.data.data.name}
            </p>
            <p className="text-start truncate text-xs text-DarkGrey">
              {props.data.data.description}
            </p>
          </div>

          <div className="text-xs text-DarkBlue">
            <div className={'flex items-center gap-x-1 mb-3'}>
              <BlackClock width="12.967" height="12.967" />
              <p className="whitespace-nowrap">
                {props.data.data.delivery_time}
              </p>
            </div>

            <div className={'flex items-center gap-x-1'}>
              <Trunk width="17.303" height="9.919" />
              <p className="whitespace-nowrap">
                {props.data.data.delivery_fee}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* description */}
      <div className="mt-5">
        <p className="text-xs text-start">{props.data.data.description}</p>
      </div>

      {/* categories */}
      <div className="sticky top-0 z-50 bg-white rounded-b-lg">
        <Scrollspy
          currentClassName="text-primary_BG underline underline-offset-8 decoration-2"
          scrolledPastClassName="text-DarkBlue"
          items={GetCatsIds()}
          style={{
            display: 'flex',
            justifyItems: 'center',
            columnGap: '1rem',
            paddingBlock: '1.5rem',
          }}
        >
          {props.cats.data.map((itm) => {
            return (
              <li key={itm.id}>
                <a href={'#' + itm.id}>{itm.name}</a>
              </li>
            );
          })}
        </Scrollspy>
      </div>

      {/* items */}

      {props.cats.data.map((itm) => {
        return (
          <div key={itm.id}>
            <div id={itm.id}></div>
            <CategoryItems VenId={id} Cat={itm} />
          </div>
        );
      })}
    </div>
  );
}

export default Content;
