import React from 'react';
import ReactLoading from 'react-loading';

export default function LoadingSpinner() {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <ReactLoading type="spin" color="#189EC9"></ReactLoading>
    </div>
  );
}
