import React, { useEffect, useState } from 'react';
import BackBTN from '../../../Components/BackBTN';
import { useTranslation } from 'react-i18next';
import { SendReqHome } from '../../../API/APIRequests';
import { useSelector } from 'react-redux';
import CategoryItems from './CategoryItems';

import ItemSkelton from './ItemSkelton';

function Content(props) {
  const { t, i18n } = useTranslation();
  const [HomeData, SetHomeData] = useState({});
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const [ActiveCat, SetActiveCat] = useState({ id: null, name: 'All' });

  useEffect(() => {
    if (ActiveCat.id === null) {
      SendReqHome(
        {
          countryID: UserSelectedData.Country.id,
        },
        (res) => {
          SetHomeData(res);
        }
      );
    } else {
      SendReqHome(
        {
          countryID: UserSelectedData.Country.id,
          data: {
            category_id: ActiveCat.id,
          },
        },
        (res) => {
          SetHomeData(res);
        }
      );
    }
  }, [i18n.language, UserSelectedData.Country, ActiveCat]);

  useEffect(() => {
    console.log(HomeData);
  }, [HomeData]);

  return (
    <div>
      <BackBTN />

      {/* categories */}
      <div
        className="grid grid-flow-col auto-cols-max items-center gap-x-5 py-2 mb-2 text-sm"
        style={{ overflowX: 'auto' }}
      >
        <div
          className="cursor-pointer"
          onClick={() => SetActiveCat({ id: null, name: 'All' })}
        >
          <p
            className={
              ActiveCat.id === null
                ? 'text-primary_BG font-semibold px-2 pb-px'
                : 'text-DarkBlue  px-2'
            }
          >
            {t('All')}
          </p>
          <div
            className={ActiveCat.id === null ? 'h-0.5 bg-primary_BG' : 'hidden'}
          ></div>
        </div>

        {props.cats.data.map((itm) => {
          return (
            <div
              className="cursor-pointer"
              onClick={() => SetActiveCat(itm)}
              key={itm.id}
            >
              <p
                className={
                  ActiveCat.id === itm.id
                    ? 'text-primary_BG font-semibold px-2 pb-px'
                    : 'text-DarkBlue  px-2'
                }
              >
                {itm.name}
              </p>
              <div
                className={
                  ActiveCat.id === itm.id ? 'h-0.5 bg-primary_BG' : 'hidden'
                }
              ></div>
            </div>
          );
        })}
      </div>

      {HomeData.success ? (
        <CategoryItems data={HomeData} ActiveCat={ActiveCat} />
      ) : (
        <ItemSkelton />
      )}
    </div>
  );
}

export default Content;
