import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Class } from '../../Assets/Icons/Classes.svg';
import { ReactComponent as Retry } from '../../Assets/Icons/Class Category/Retry.svg';
import { useNavigate } from 'react-router-dom';

function ClassOrderFailure(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex justify-center">
        <img src={require('../../Assets/Images/ClassCategory/Fail.png')} />
      </div>

      <div>
        <p className="text-lg">{t('We’re Sorry!')}</p>
        <p className="">{t('Your class is failed to book')}</p>
      </div>

      <div class="grid grid-cols-2 gap-x-3 my-10">
        <div
          onClick={() => {
            navigate('/AllClassses');
          }}
          class="cursor-pointer shadow-custome rounded-2xl grid grid-rows-2 pt-3 lg:py-5 lg:px-3 lg:grid-cols-3 lg:grid-rows-none lg:items-center"
        >
          <div class="flex justify-center items-center">
            <Class width="31.05" height="31.081" />
          </div>
          <p class="text-xs text-center pt-3 lg:pt-0 col-span-2">
            {t('Explore more Classes')}
          </p>
        </div>

        <div
          onClick={() => {
            navigate('/OrderReview');
          }}
          class="cursor-pointer shadow-custome rounded-2xl grid grid-rows-2 pt-3 lg:py-3 lg:px-3 lg:grid-cols-3 lg:grid-rows-none lg:items-center"
          id="SecondCard"
        >
          <div class="flex justify-center items-center">
            <Retry width="27.249" height="30.894" />
          </div>
          <p class="text-xs text-center pt-3 lg:pt-0 col-span-2">
            {t('Re-Try Booking')}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ClassOrderFailure;
