import React from 'react';

export default function Card({
  icon = '',
  text = '',
  TextClass = '',
  onClick = {},
  CheckedIcon,
  cardClassName = '',
}) {
  return (
    <div
      onClick={() => onClick()}
      className={
        'relative rounded-xl shadow-HomeCard flex items-center cursor-pointer py-5 px-3 ' +
        cardClassName
      }
    >
      <div className="w-1/3">{icon}</div>

      <div className="w-full">
        <div className="flex justify-center w-full px-2">
          <p className={TextClass}>{text}</p>
        </div>

        <div className="absolute ltr:right-2 rtl:left-2 top-2">
          {CheckedIcon}
        </div>
      </div>
    </div>
  );
}
