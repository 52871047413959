import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GetVendorCatItems } from '../../../API/APIRequests';
import StoreItemCard from '../../../Components/StoreItemCard';
import ItemsPlaceHolder from './ItemsPlaceHolder';

function CategoryItems({ VenId, Cat }) {
  const { i18n } = useTranslation();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const [CatItems, SetCatItems] = useState({});

  // gett all items of all cats
  useEffect(() => {
    if (UserSelectedData.Country) {
      GetVendorCatItems(
        {
          countryID: UserSelectedData.Country.id,
          data: {
            vendor_id: VenId,
            category_id: Cat.id,
          },
        },
        (res) => {
          SetCatItems(res);
        }
      );
    }
  }, [i18n.language]);

  return (
    <div>
      {CatItems.success ? (
        <div>
          <p className="text-start font-semibold text-sm text-primary_BG mb-2">
            {Cat.name}
          </p>
          <div className="grid grid-cols-3 gap-x-4 gap-y-3 mb-5">
            {CatItems.data.map((itm) => {
              return <StoreItemCard data={itm} />;
            })}
          </div>
        </div>
      ) : (
        <div>
          <ItemsPlaceHolder />
        </div>
      )}
    </div>
  );
}

export default CategoryItems;
