export const types = {
  // generate temp id
  Save_TempID: 'Save_TempID',
  Save_TempID_SUCCESS: 'Save_TempID_SUCCESS',

  // Save user info
  Save_User_Info: 'Save_User_Info',
  Save_User_Info_SUCCESS: 'Save_User_Info_SUCCESS',

  //  loggedin flag
  User_LoggedIn: 'User_LoggedIn',
  User_LoggedIn_SUCCESS: 'User_LoggedIn_SUCCESS',

  // save token
  Save_Token: 'Save_Token',
  Save_Token_SUCCESS: 'Save_Token_SUCCESS',

  // guest user
  Is_Guest_User: 'Is_Guest_User',
  Is_Guest_User_SUCCESS: 'Is_Guest_User_SUCCESS',

  // logout
  LogOut: 'LogOut',
  LogOut_SUCCESS: 'LogOut_SUCCESS',

  // alert
  Toggle_Hide_Show_Alert: 'Toggle_Hide_Show_Alert',
  Toggle_Hide_Show_Alert_SUCCESS: 'Toggle_Hide_Show_Alert_SUCCESS',

  // User Selections sports
  Save_Selected_Data: 'Save_Selected_Data',
  Save_Selected_Data_Success: 'Save_Selected_Data_Success',

  // save order status
  Save_OrderStaus_Data: 'Save_OrderStaus_Data',
  Save_OrderStaus_Data_SUCCESS: 'Save_OrderStaus_Data_SUCCESS',

  // save invoiceid
  Save_InvoiceId_Data: 'Save_InvoiceId_Data',
  Save_InvoiceId_Data_SUCCESS: 'Save_InvoiceId_Data_SUCCESS',

  //Save cart
  Save_Cart: 'Save_Cart',
  Save_Cart_SUCCESS: 'Save_Cart_SUCCESS',

  //    set cart changed flag
  Set_Cart_Change_Flag: 'Set_Cart_Change_Flag',
  Set_Cart_Change_Flag_SUCCESS: 'Set_Cart_Change_Flag_SUCCESS',

  CHANGE_LOCALE: 'CHANGE_LOCALE',
  SET_LANG: 'SET_LANG',
};
