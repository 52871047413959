import React, { useEffect } from 'react';

function CustomInput({
  type = 'text',
  value,
  onChange = () => {},
  placeholder = '',
  readOnly = false,
  ShowValidationMsg = false,
  ValidationMsg = '',
}) {
  useEffect(() => {
    // console.log(onChange)
  }, [onChange]);

  return (
    <>
      <input
        className={`bg-gray rounded-lg outline-none px-2 py-1 w-full ${
          ShowValidationMsg === true ? '' : 'mb-4'
        }`}
        type={type}
        value={value}
        placeholder={placeholder}
        readOnly={readOnly}
        onChange={onChange}
      />

      {ShowValidationMsg === true && (
        <p className="text-xs text-red-500 text-start px-1 pt-px mb-4">
          {ValidationMsg}
        </p>
      )}
    </>
  );
}

export default CustomInput;
