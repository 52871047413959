import React, { useEffect, useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Home } from '../Assets/Icons/Home.svg';
import { ReactComponent as OrderHistory } from '../Assets/Icons/OrderHistory.svg';
import { ReactComponent as AccountSettings } from '../Assets/Icons/AccountSettings.svg';
import { ReactComponent as RightBlueArrow } from '../Assets/Icons/RightBlueArrow.svg';
import { ReactComponent as LogOut } from '../Assets/Icons/LogOut.svg';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeLocale,
  LogOutAction,
} from '../Redux/Actions';
import { GetAllTypes } from '../API/APIRequests';
import SideMenueSkelton from './SideMenueSkelton';

function SideMenu({ isOpen, onClose }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Lang, Country } = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const { LoggedIn, GuestUser } = useSelector(
    (state) => state.PersistedReducer
  );
  const [ModuleActiveTypes, SetModuleActiveTypes] = useState({});

  useEffect(() => {
    GetAllTypes({}, (res) => {
      SetModuleActiveTypes(res);
    });
  }, [Lang, Country]);

  // navigate for active modules
  const handelNavigate = (id) => {
    switch (id) {
      case 4:
        navigate('/Store');
        break;

      case 1:
        navigate('/Sports');
        break;

      case 2:
        navigate('/Classes');
        break;

      case 3:
        navigate('/Subscriptions');
        break;

      default:
        break;
    }
  };

  // log out
  const HandelLogOut = () => {
    dispatch(LogOutAction());
    // dispatch(SaveUserInfoAction({}))
    // dispatch(UserLoggedInAction(false))
    // dispatch(IsGuestUserAction(false))
    // dispatch(SaveTokenAction(''))
  };

  return (
    <Menu
      right={Lang === 'ar'}
      isOpen={isOpen}
      onClose={() => onClose()}
      className="w-full"
      itemListClassName="overflow-auto"
      customBurgerIcon={false}
      customCrossIcon={false}
    >
      <div
        style={{ display: 'flex' }}
        className="flex-col justify-between gap-y-3 bg-white h-full outline-none"
      >
        {ModuleActiveTypes.success ? (
          <>
            <div className={`bg-white`}>
              <header className="px-3">
                <div className="flex gap-x-2 py-5">
                  <div className="w-full flex justify-center items-start">
                    <img
                      className="h-10"
                      src={require('../Assets/Images/Logo.png')}
                    />
                  </div>
                  <p
                    className="cursor-pointer"
                    id="CloseMenuBtn"
                    onClick={() => onClose()}
                  >
                    <CloseIcon fontSize="small" />
                  </p>
                </div>
              </header>

              <div className="bg-white my-3 px-5">
                <ul>
                  <li
                    className="flex pb-7 items-center  cursor-pointer"
                    onClick={() => {
                      navigate('/home');
                    }}
                  >
                    <div>
                      <Home width="20.111" height="20.111" />
                    </div>
                    <div className="ltr:pl-5 rtl:pr-5">
                      <p>{t('Home')}</p>
                    </div>
                  </li>

                  {ModuleActiveTypes.data.types.map((itm) => {
                    return (
                      <li
                        key={itm.id}
                        className="flex gap-x-5 pb-7 items-center  cursor-pointer"
                        onClick={() => {
                          handelNavigate(itm.id);
                        }}
                      >
                        <div>
                          <img
                            className="h-6 w-6 object-contain"
                            src={itm.image}
                          />
                        </div>
                        <div>
                          <p>{itm.name}</p>
                        </div>
                      </li>
                    );
                  })}

                  <li
                    className="flex pb-7 items-center cursor-pointer"
                    onClick={() => {
                      navigate('/OrderHistory');
                    }}
                  >
                    <div>
                      <OrderHistory width="20.111" height="20.111" />
                    </div>
                    <div className="ltr:pl-5 rtl:pr-5">
                      <p>{t('Order History')}</p>
                    </div>
                  </li>

                  <li
                    className="flex pb-7 items-center cursor-pointer"
                    onClick={() => {
                      navigate('/AccountSettings');
                    }}
                  >
                    <div>
                      <AccountSettings width="20.111" height="20.111" />
                    </div>
                    <div className="ltr:pl-5 rtl:pr-5">
                      <p>{t('Account Settings')}</p>
                    </div>
                  </li>

                  <li
                    className="flex pb-7 items-center cursor-pointer"
                    onClick={() => {
                      navigate('/about');
                    }}
                  >
                    <div>
                      <InfoOutlinedIcon
                        sx={{ color: '#189EC9' }}
                        width="20.111"
                        height="20.111"
                      />
                    </div>
                    <div className="ltr:pl-5 rtl:pr-5">
                      <p>{t('About Us')}</p>
                    </div>
                  </li>

                  <li
                    className="flex pb-7 items-center cursor-pointer"
                    onClick={() => {
                      navigate('/terms');
                    }}
                  >
                    <div>
                      <StickyNote2OutlinedIcon
                        sx={{ color: '#189EC9' }}
                        width="20.111"
                        height="20.111"
                      />
                    </div>
                    <div className="ltr:pl-5 rtl:pr-5">
                      <p>{t('Terms And Conditions')}</p>
                    </div>
                  </li>
                </ul>
              </div>
              <footer>
                <div className="flex flex-col bg-LightGray rounded-t-xl px-3 py-5 h-full w-full">
                  {LoggedIn ? (
                    <div
                      onClick={() => HandelLogOut()}
                      className="flex items-center mb-5 cursor-pointer"
                      id="Logged In"
                    >
                      <div>
                        <LogOut width="18.118" height="18.12" />
                      </div>
                      <p className="mx-2 text-primary_BG">{t('Log out')}</p>
                    </div>
                  ) : (
                    <div
                      className="flex justify-between  mb-5 "
                      id="Logged Out"
                    >
                      <div
                        className="flex  justify-between  w-full cursor-pointer"
                        onClick={() => navigate('/Login')}
                      >
                        <div className="text-primary_BG">
                          <p>{t('Log in')}</p>
                        </div>
                        <div>
                          <RightBlueArrow
                            width="6.454"
                            height="15.549"
                            className={Lang === 'ar' ? `rotate-180` : ``}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="bg-primary_BG text-white rounded-lg flex justify-center items-center w-1/3">
                    <button
                      onClick={() => dispatch(changeLocale('ar'))}
                      className={`rtl:bg-DarkBlue ltr:bg-transparent py-1 rounded-lg  text-center w-1/2`}
                    >
                      ع
                    </button>
                    <button
                      onClick={() => dispatch(changeLocale('en'))}
                      className={`ltr:bg-DarkBlue ltr:py-1 rtl:bg-transparent rtl:py-0 rounded-lg  text-center w-1/2 pt-1`}
                    >
                      EN
                    </button>
                  </div>
                </div>
              </footer>
            </div>
          </>
        ) : (
          <SideMenueSkelton />
        )}
      </div>
    </Menu>
  );
}

export default SideMenu;
