import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetAllTypes } from '../../API/APIRequests';
import LoadingSpinner from '../../Components/LoadingSpinner';
import { mainUrl } from '../../Redux/BaseUrls';
import Content from './Content';
import MainLayout from '../../Components/MainLayout';

function Home() {
  const { i18n } = useTranslation();
  const [Countries, SetCountries] = useState({});
  const [ModuleActiveTypes, SetModuleActiveTypes] = useState({});

  const GetCountries = () => {
    mainUrl
      .get(`countries`)
      .then((response) => response.data)
      .then((data) => SetCountries(data));
  };

  useEffect(() => {
    GetAllTypes({
    }, (res) => {
      SetModuleActiveTypes(res);
    });
    GetCountries();
  }, [i18n.language]);

  useEffect(() => {
    console.log('ModuleActiveTypes', ModuleActiveTypes);
  }, [ModuleActiveTypes]);

  return (
    <MainLayout active='home'>
      {Countries.success && ModuleActiveTypes.success ? (
        <div className="w-11/12 lg:w-1/3 md:w-1/2 mt-5 grow">
          <Content
            Countries={Countries}
            ModuleActiveTypes={ModuleActiveTypes}
          />
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </MainLayout>
  );
}

export default Home;
