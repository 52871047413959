import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SaveUserDataAction } from '../../../Redux/Actions';

export default function SelectedCard({
  FirstRow = {},
  ChangeBtnOnClick = {},
  icon = {},
  cardClassName = '',
  showRemoveArea = false,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );

  const RemoveAreaBtnOnClick = () => {
    dispatch(SaveUserDataAction({ ...UserSelectedData, SelectedArea: '' }));
  };

  return (
    <div
      className={
        'grid grid-rows-2 gap-y-2 rounded-xl shadow-custome bg-primary_BG p-3 ' +
        cardClassName
      }
    >
      {FirstRow}

      <div className="flex justify-between items-end">
        <div className="flex flex-col">
          <button
            onClick={ChangeBtnOnClick}
            className="text-white text-xs bg-TransparentWhite rounded-full px-2 py-px"
          >
            {t('Change')}
          </button>
          {showRemoveArea && (
            <button
              onClick={RemoveAreaBtnOnClick}
              className="text-white text-xs bg-TransparentWhite rounded-full px-2 py-px mt-1"
            >
              {t('Remove')}
            </button>
          )}
        </div>
        {icon}
      </div>
    </div>
  );
}
