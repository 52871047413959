import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ExploreMoreClasses, GetAllClasses } from '../../../API/APIRequests';
import Footer from '../../../Components/Footer';
import Header from '../../../Components/Header';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import { CheckDateIsNotPassed, ModifyDateFormat } from '../../../Utils/Utils';
import Content from './Content';
import MainLayout from '../../../Components/MainLayout';

function AllClasses(props) {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const Location = useLocation();
  const ClassID = Location.state;
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const [AllClasses, SetAllClasses] = useState({});

  useEffect(() => {
    console.log(ClassID);
    if (ClassID) {
      ExploreMoreClasses(
        {
          countryID: UserSelectedData.Country.id,
          data: {
            class_id: ClassID,
          },
        },
        (res) => {
          SetAllClasses(res);
        }
      );
    } else {
      if (UserSelectedData.SelectedDate) {
        if (UserSelectedData.SelectedGender && UserSelectedData.SelectedClass) {
          let DateIsPassed = CheckDateIsNotPassed(
            UserSelectedData.SelectedDate
          );

          if (DateIsPassed === true) {
            navigate('/SelectDate');
          } else {
            // modify date format
            let date = ModifyDateFormat(UserSelectedData.SelectedDate);
            GetAllClasses(
              {
                countryID: UserSelectedData.Country.id,
                data: {
                  date: date,
                  type: UserSelectedData.SelectedClass.id,
                  ...(UserSelectedData.SelectedArea
                    ? { area: UserSelectedData.SelectedArea.id }
                    : {}),
                  gender: UserSelectedData.SelectedGender,
                },
              },
              (res) => {
                SetAllClasses(res);
              }
            );
          }
        } else {
          navigate('/classes');
        }
      } else {
        navigate('/SelectDate');
      }
    }
  }, []);

  return (
    <MainLayout active={`class`} Cat='Classes'>
      {AllClasses.success ? (
        <div className="w-11/12 lg:w-1/3 sm:w-1/2 mt-5 grow">
          <Content AllClasses={AllClasses} />
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </MainLayout>
  );
}

export default AllClasses;
