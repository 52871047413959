import React from 'react';

function TextWithIconCard({ icon, text, onclick }) {
  return (
    <div
      onClick={() => onclick()}
      className="grid grid-cols-1 grid-rows-2 md:grid-cols-3 md:grid-rows-1 gap-x-2 items-center shadow-custome cursor-pointer rounded-xl text-sm justify-items-center py-5 px-3"
    >
      <div>{icon}</div>

      <div className="md:col-span-2 py-1 md:py-0">
        <p>{text}</p>
      </div>
    </div>
  );
}

export default TextWithIconCard;
