import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { GetClassDetails } from '../../../API/APIRequests';
import Footer from '../../../Components/Footer';
import Header from '../../../Components/Header';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import Content from './Content';
import MainLayout from '../../../Components/MainLayout';

function ClassDetails(props) {
  const { i18n } = useTranslation();
  const { id } = useParams();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const [ClassDetails, SetClassDetails] = useState({});

  useEffect(() => {
    let dt = new Date(UserSelectedData.SelectedDate);
    let date = [
      dt.getFullYear(),
      ('0' + (dt.getMonth() + 1)).slice(-2),
      ('0' + dt.getDate()).slice(-2),
    ].join('-');

    GetClassDetails(
      {
        countryID: UserSelectedData.Country.id,
        data: {
          date: date,
          class_id: id,
        },
      },
      (res) => {
        SetClassDetails(res);
      }
    );
  }, [i18n.language, UserSelectedData.Country.id]);

  // useEffect(() => {
  //     console.log(id)
  // }, [id]);

  return (
    <MainLayout Cat='Classes'>
      {ClassDetails.success ? (
        <div className="w-11/12 lg:w-1/3 sm:w-1/2 mt-5 grow">
          <Content ClassDetails={ClassDetails} />
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </MainLayout>
  );
}

export default ClassDetails;
