import React, { useEffect, useState } from 'react';
import { ReactComponent as SelectArea } from '../../../Assets/Icons/Class Category/SelectArea.svg';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useTranslation } from 'react-i18next';
import CustomInput from '../../../Components/CustomInput';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import MapPicker from 'react-google-map-picker';
import LocationPicker from 'react-location-picker';
import CustomButton from '../../../Components/CustomButton';
import { SaveAddress } from '../../../API/APIRequests';
import {
  SaveUserDataAction,
  ToggleShowHideAction,
} from '../../../Redux/Actions';
import Modal from '@mui/material/Modal';
import GreyLine from '../../../Components/GreyLine';

function Content(props) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const PersistedReducer = useSelector((state) => state.PersistedReducer);
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );

  // add  type
  const [value, setValue] = useState('house');

  // address info
  const [AddressInfo, SetAddressInfo] = useState({
    type: value,
    area_id: UserSelectedData.SelectedArea
      ? UserSelectedData.SelectedArea.id
      : '',
    address: {},
    ...(PersistedReducer.Loggedin && {
      customer_id: PersistedReducer.UserInfo.user.id,
    }),
  });

  // input validation show or hide
  const [ShowValidation, SetShowValidation] = useState(false);

  // location and map
  const [ShowMap, SetShowMap] = useState(false);
  const [CurrLoc, SetCurrLoc] = useState({});
  const [SelectedMapAdd, SetSelectedMapAdd] = useState({});

  const [SaveAddressRes, SetSaveAddressRes] = useState({});

  // update address on change
  const HandelUpdateAddress = (nm, val) => {
    SetAddressInfo((prev) => ({
      ...prev,
      address: {
        ...prev.address,
        [nm]: val,
      },
    }));
  };

  // when type is changed
  const handleChange = (event, newValue) => {
    setValue(newValue);
    SetAddressInfo((prev) => ({ ...prev, type: newValue, address: {} }));
  };

  // save address
  const HandelContinueToCheckout = () => {
    let AddressValid = ValidateRequiredAddress();

    if (AddressInfo.area_id && AddressValid) {
      SaveAddress(
        {
          data: AddressInfo,
          countryID: PersistedReducer.UserSelectedData.Country.id,
        },
        (res) => {
          SetSaveAddressRes(res);
        }
      );
    } else {
      SetShowValidation(true);
    }
  };

  // check all required add fields is selected
  const ValidateRequiredAddress = () => {
    let valid = true;
    for (let i = 0; i < UserSelectedData.Country?.address_field.length; i++) {
      if (UserSelectedData.Country?.address_field[i].selection === 'required') {
        if (
          AddressInfo.address.hasOwnProperty(
            UserSelectedData.Country?.address_field[i].key
          )
        ) {
        } else {
          valid = false;
        }
      }
    }

    return valid;
  };

  useEffect(() => {
    console.log(SaveAddressRes);
    if (SaveAddressRes.success) {
      dispatch(
        ToggleShowHideAction({
          Open: true,
          Type: 'success',
          Msg: t('Address saved successfully'),
          onclose: () => {
            navigate('/ReviewOrder/Store');
            dispatch(
              SaveUserDataAction({
                ...UserSelectedData,
                StoreUserAddress: SaveAddressRes.data,
              })
            );
          },
        })
      );
    }
  }, [SaveAddressRes]);

  /////////////////////////////// map functions///////////////////////////////////////
  const HandelShowMapClick = () => {
    // get curr loc of user
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        SetCurrLoc((prev) => ({
          ...prev,
          lat: position.coords.latitude,
          long: position.coords.longitude,
        }));
      });
    }
    // else {
    //   dispatch(
    //     ToggleShowHideAction({
    //       Open: true,
    //       Type: 'info',
    //       Msg: t('please Allow Access to your location to show map'),
    //     })
    //   );
    // }
  };

  // open map when curr loc is settted
  useEffect(() => {
    if (CurrLoc.lat && CurrLoc.long && ShowMap === false) {
      SetShowMap(true);
    }
  }, [CurrLoc]);

  function handleChangeLocation({ position, address, places }) {
    console.log(position);
    SetSelectedMapAdd(position);
  }

  const HandelSelectAdd = () => {
    SetAddressInfo((prev) => ({
      ...prev,
      longitude: SelectedMapAdd.lng ? SelectedMapAdd.lng : CurrLoc.long,
      latitude: SelectedMapAdd.lat ? SelectedMapAdd.lat :CurrLoc.lat,
    }));
    SetShowMap(false);
  };

  useEffect(() => {
    console.log(AddressInfo);
  }, [AddressInfo]);

  return (
    <div>
      <Tabs centered="true" value={value} onChange={handleChange}>
        <Tab label="House" value={'house'} />
        <Tab label="Appratement" value={'appartement'} />
        <Tab label="Office" value={'office'} />
      </Tabs>

      {/* inputs */}
      <div
        className="w-full"
        // hidden={value !== 'house'}
      >
        <div className="text-sm">
          {/* area */}
          <div
            onClick={() => navigate('/SelectArea')}
            className="flex justify-between items-center w-full py-3 mb-2 cursor-pointer"
          >
            <div className="flex items-center gap-x-2 ">
              <SelectArea width="34.198" height="22.383" />
              <p>{t('Select Area')}</p>
            </div>
            {i18n.language === 'ar' ? (
              <KeyboardArrowLeftIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </div>

          <CustomInput
            ValidationMsg={
              AddressInfo.area_id === '' ? 'Area Is Required *' : ''
            }
            ShowValidationMsg={ShowValidation}
            name="area"
            type="text"
            value={
              UserSelectedData.SelectedArea
                ? UserSelectedData.SelectedArea.name
                : ''
            }
            placeholder={t('Area')}
            readOnly={true}
          />

          {UserSelectedData.Country?.address_field.map((itm) => {
            return (
              <CustomInput
                onChange={(e) => HandelUpdateAddress(itm.key, e.target.value)}
                name={itm.key}
                type={itm.type}
                value={AddressInfo.address[itm.key]}
                ValidationMsg={
                  itm.selection === 'required'
                    ? AddressInfo.address[itm.key]
                      ? ''
                      : `${itm.key} Is Required *`
                    : ''
                }
                ShowValidationMsg={ShowValidation}
                placeholder={
                  itm.selection === 'required' ? `${itm.key} *` : itm.key
                }
              />
            );
          })}
        </div>
      </div>

      {/* add from map map */}
      <div>
        <div
          onClick={() => {
            HandelShowMapClick();
          }}
          className={
            'flex justify-between items-center w-full py-3 mb-2 cursor-pointer'
          }
        >
          <div className="flex items-center gap-x-2 ">
            <SelectArea width="34.198" height="22.383" />
            <p>{t('On map')}</p>
          </div>
          {i18n.language === 'ar' ? (
            <KeyboardArrowLeftIcon />
          ) : (
            <KeyboardArrowRightIcon />
          )}
        </div>
      </div>

      {/* select loc on map */}

      <Modal
        open={ShowMap}
        onClose={() => SetShowMap(false)}
        sx={
          {
            // top: '50%',
            // left: '50%',
          }
        }
      >
        <div
          className="w-1/2 bg-white p-3"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          {/* <MapPicker
            defaultLocation={{ lat: CurrLoc.lat, lng: CurrLoc.long }}
            zoom={10}
            mapTypeId="roadmap"
            style={{ height: '300px' }}
            onChangeLocation={handleChangeLocation}
            onChangeZoom={() => {}}
            apiKey="AIzaSyChibV0_W_OlSRJg2GjL8TWVU8CzpRHRAE"
          /> */}

          <LocationPicker
            containerElement={<div style={{ height: '100%' }} />}
            mapElement={<div style={{ height: '400px' }} />}
            defaultPosition={{ lat: CurrLoc.lat, lng: CurrLoc.long }}
            onChange={handleChangeLocation}
          />

          <GreyLine />
          <CustomButton
            onclick={() => {
              HandelSelectAdd();
            }}
            BtnValue={t('Set Location')}
            ContainerClass="bg-primary_BG rounded-xl text-sm text-white text-center cursor-pointer py-1 my-5"
          />
        </div>
      </Modal>

      {/* continue */}
      <div className="py-5">
        <CustomButton
          onclick={() => HandelContinueToCheckout()}
          BtnValue={t('Continue To CheckOut')}
          ContainerClass="bg-primary_BG rounded-xl text-sm text-white cursor-pointer py-1"
        />
      </div>
    </div>
  );
}

export default Content;
