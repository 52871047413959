import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GetAllSubscriptions } from '../../../API/APIRequests';
import BackBTN from '../../../Components/BackBTN';
import Footer from '../../../Components/Footer';
import Header from '../../../Components/Header';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import Content from './Content';
import MainLayout from '../../../Components/MainLayout';
import { useNavigate } from 'react-router-dom';

function AllSubscriptions(props) {
  const { i18n } = useTranslation();
  const navigate=useNavigate()
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const [AllSubscriptions, SetAllSubscriptions] = useState({});

  useEffect(() => {
    if (UserSelectedData.Country) {
      if(UserSelectedData.SelectedSubscriptionType){
        GetAllSubscriptions(
          {
            countryID: UserSelectedData.Country.id,
            data: {
              category: UserSelectedData.SelectedSubscriptionType.id,
              ...(UserSelectedData.SelectedGender
                ? { gender: UserSelectedData.SelectedGender }
                : {}),
              ...(UserSelectedData.SelectedArea
                ? { area: UserSelectedData.SelectedArea.id }
                : {}),
            },
          },
          (res) => {
            SetAllSubscriptions(res);
          }
        );
      }
      else{
        navigate('/Subscriptions')
      }
      
    }
  }, [i18n.language, UserSelectedData.Country]);

  useEffect(() => {
    console.log(AllSubscriptions);
  }, [AllSubscriptions]);

  return (
    <MainLayout Cat='Subscriptions'>
      {AllSubscriptions.success === true ? (
        <div className="w-11/12 lg:w-1/3 sm:w-1/2 mt-5 grow">
          {AllSubscriptions.data.length === 0 ? (
            <div className="">
              <BackBTN />
              <p className="text-center py-3">No Subscription Found</p>
            </div>
          ) : (
            <Content AllSubscriptions={AllSubscriptions} />
          )}
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </MainLayout>
  );
}

export default AllSubscriptions;
