import React from 'react';
import BackBTN from './../../Components/BackBTN';
import { ReactComponent as AccountSettings } from '../../Assets/Icons/AccountSettings.svg';
import { useTranslation } from 'react-i18next';

function AboutUs(props) {
  const { t } = useTranslation();

  return (
    <div>
      <BackBTN
        text={
          <div className="flex items-cener justify-center my-3">
            <AccountSettings width="20.111" height="24.354" />
            <p className="px-1">{t('About Us')}</p>
          </div>
        }
      />

      <div dangerouslySetInnerHTML={{ __html: props.data.data[0].body }} />
    </div>
  );
}

export default AboutUs;
