import React, { useEffect } from 'react';
import BackBTN from '../../../Components/BackBTN';
import Carousel from 'react-material-ui-carousel';
import { useTranslation } from 'react-i18next';
import SectionTitle from '../../Venues/Venue Details/SectionTitle';

import { ReactComponent as AvailableSeats } from '../../../Assets/Icons/Class Category/BlueAvailableSeats.svg';
import { ReactComponent as ClassType } from '../../../Assets/Icons/Class Category/BlueClassType.svg';
import { ReactComponent as BlueCalender } from '../../../Assets/Icons/Class Category/BlueCalender.svg';
import { ReactComponent as Aminities } from '../../../Assets/Icons/Aminities.svg';
import { ReactComponent as Location } from '../../../Assets/Icons/Location.svg';
import { ReactComponent as LocationMarker } from '../../../Assets/Icons/LocationMarker.svg';
import GoogleMapReact from 'google-map-react';
import CustomButton from '../../../Components/CustomButton';
import { useNavigate } from 'react-router-dom';
import { SaveUserDataAction } from '../../../Redux/Actions';
import { useDispatch, useSelector } from 'react-redux';
import GeneralCarousel from '../../../Components/GeneralCarousel';

function Content(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );

  const OnClickBookClass = () => {
    dispatch(
      SaveUserDataAction({
        ...UserSelectedData,
        ClassDetails: {
          ClassId: props.ClassDetails.data.id,
          Date: props.ClassDetails.data.date_without_format,
        },
      })
    );
    navigate('/OrderReview');
  };

  // useEffect(() => {
  //     console.log(props.ClassDetails)
  // }, []);

  return (
    <div>
      <BackBTN />
      {/* <div className='flex justify-center w-full'>
                <p className='w-3/5'> classes found</p>
            </div> */}

      {/* slider imgs */}
      <div className="mt-5 ">
        <GeneralCarousel
          h="6rem"
          slidesArray={props.ClassDetails.data.images}
        />
      </div>

      {/* class details */}
      <div className="grid grid-cols-2 mb-4">
        <div className="flex p-px">
          <img
            className="w-10 h-10 rounded-md"
            src={props.ClassDetails.data.vendor_logo}
            onError={(e) => {
              e.onerror = null; // prevents looping
              e.target.src = require('../../../Assets/Images/img-not-found-thumbNail.png');
            }}
          />
          <div className="text-left mx-1">
            <p className="">{props.ClassDetails.data.vendor_name}</p>
            <p className="text-xxs">{props.ClassDetails.data.class_name}</p>
          </div>
        </div>

        <div className="flex flex-col justify-between items-end h-full">
          <p className="text-primary_BG">
            {props.ClassDetails.data.class_price}{' '}
            {props.ClassDetails.data.currency}
          </p>
          <div className="flex justify-end items-center text-xs">
            <div className="px-1">
              <AvailableSeats width="11.108" height="13.674" />
            </div>
            <p>
              {props.ClassDetails.data.available_seats} {t('available seats')}
            </p>
          </div>
        </div>

        {/* <div className='col-span-2 flex justify-end items-center text-xs'>
                    <div className='px-1'>
                        <AvailableSeats width="11.108" height="13.674"/>
                    </div>
                    <p>{props.ClassDetails.data.available_seats} {t("available seats")}</p>
                </div> */}

        <div className="col-span-2 flex ltr:text-left rtl:test-right text-xs py-4">
          {props.ClassDetails.data.discription}
        </div>
      </div>

      {/* class type */}
      <SectionTitle
        icon={<ClassType width="20.088" height="20.099" />}
        title={t('Class type')}
      />

      {/* class type */}
      <div className="grid grid-flow-col gap-x-2 gap-y-3 mt-1 mb-4 bg-LighterGray rounded-md p-2 h-24">
        {props.ClassDetails.data.categories.map((itm) => {
          return (
            <div
              key={itm.id}
              className="flex flex-col items-center justify-center"
            >
              <img
                className="h-10 w-10"
                alt={itm.name}
                src={itm.image}
                onError={(e) => {
                  e.onerror = null; // prevents looping
                  e.target.src = require('../../../Assets/Images/img-not-found-thumbNail.png');
                }}
              />

              <p className="text-primary_BG text-xs py-1">{itm.name}</p>
            </div>
          );
        })}
      </div>

      {/* Coach details */}
      <SectionTitle
        icon={<AvailableSeats width="15.703" height="15.487" />}
        title={t('Coach details')}
      />

      <div className="flex flex-col items-start mt-1 mb-4 bg-LighterGray rounded-md p-4">
        <p className="text-sm">{props.ClassDetails.data.coach_name}</p>
        <p className="text-xs  text-DarkBlue">{t('Personal trainer')}</p>
      </div>

      {/* date and time */}
      <SectionTitle
        icon={<BlueCalender width="15.703" height="15.487" />}
        title={t('Date and time')}
      />

      <div className="flex flex-col items-start mt-1 mb-4 bg-LighterGray rounded-md p-4">
        <p className="text-sm text-DarkBlue mb-2">
          {props.ClassDetails.data.date}
        </p>
        <p className="text-xs">{props.ClassDetails.data.time}</p>
      </div>

      {/* aminities */}
      <SectionTitle
        icon={<Aminities width="15.703" height="15.487" />}
        title={t('Amenities')}
      />

      <div className="grid grid-flow-col gap-x-2 gap-y-3 mt-1 mb-4 bg-LighterGray rounded-md p-2 h-24">
        {props.ClassDetails.data.amenities.map((itm) => {
          return (
            <div
              key={itm.id}
              className="flex flex-col items-center justify-center"
            >
              <img
                className="h-10 w-10"
                alt={itm.name}
                src={itm.icon}
                onError={(e) => {
                  e.onerror = null; // prevents looping
                  e.target.src = require('../../../Assets/Images/img-not-found-thumbNail.png');
                }}
              />

              <p className="text-primary_BG text-xs py-1">{itm.name}</p>
            </div>
          );
        })}
      </div>

      {/* location */}
      <SectionTitle
        icon={<Location width="9.857" height="14.899" />}
        title={t('Location')}
      />

      <div className="h-32 mb-3">
        <GoogleMapReact
          className="rounded-md"
          bootstrapURLKeys={{
            // remove the key if you want to fork
            key: 'AIzaSyChibV0_W_OlSRJg2GjL8TWVU8CzpRHRAE',
            language: 'en',
            region: 'US',
          }}
          defaultCenter={{
            lat: props.ClassDetails.data.latitude
              ? parseInt(props.ClassDetails.data.latitude)
              : 10.99835602,
            lng: props.ClassDetails.data.longitude
              ? parseInt(props.ClassDetails.data.longitude)
              : 77.01502627,
          }}
          defaultZoom={11}
        >
          <LocationMarker
            width="20.601"
            height="31.664"
            lat={
              props.ClassDetails.data.latitude
                ? props.ClassDetails.data.latitude
                : 10.99835602
            }
            lng={
              props.ClassDetails.data.longitude
                ? props.ClassDetails.data.longitude
                : 77.01502627
            }
          />
        </GoogleMapReact>
      </div>

      {/* continue btn */}

      <div className="pb-5">
        <CustomButton
          onclick={() => {
            OnClickBookClass();
          }}
          BtnValue={t('Book a Class')}
          ContainerClass="bg-primary_BG rounded-xl text-sm text-white cursor-pointer py-1 mt-8"
        />
      </div>
    </div>
  );
}

export default Content;
