import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function FooterTab({
  Dir = '/home',
  cat = 'Sports',
  ActiveIcon = '',
  InActiveIcon = '',
  Text = '',
  active = false,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );

  return (
    <div className="flex justify-center">
      <div
        onClick={() => {
          navigate(Dir);
        }}
        className={`flex flex-col justify-center items-center w-fit cursor-pointer ${
          active && 'bg-gray p-2 rounded-md'
        }`}
      >
        <div className="w-fit">{active ? ActiveIcon : InActiveIcon}</div>
        {active && <p className="text-xs w-fit text-DarkBlue">{t(Text)}</p>}
      </div>
    </div>
  );
}

export default FooterTab;
