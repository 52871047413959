import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import BackBTN from '../../../Components/BackBTN';

function Content(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      <BackBTN
        text={
          <div className="py-1 mb-3">
            <p>{t('Subscription found')}</p>
          </div>
        }
      />

      <div className="grid grid-cols-1 gap-x-1 gap-y-5">
        {props.AllSubscriptions.data.map((itm) => {
          return (
            <div
              onClick={() => {
                navigate(`/AllSubscriptions/${itm.vendor_id}`);
              }}
              className="h-56 relative cursor-pointer"
            >
              <img
                className="w-full h-full rounded-md"
                src={itm.vendor_banner}
                onError={(e) => {
                  e.onerror = null; // prevents looping
                  e.target.src = require('../../../Assets/Images/img-not-found.png');
                }}
              />

              <div className="bg-primary_BG rounded-md absolute bottom-0 w-full">
                <div className="grid grid-cols-8 text-white p-1.5">
                  <div className="col-start-1 col-span-8 mx-1">
                    <div className="flex justify-between">
                      <div className="flex text-left">
                        {/* logo img */}
                        <div className="p-px w-8 h-8">
                          <img
                            className="w-full h-full rounded-md"
                            src={itm.vendor_logo}
                            onError={(e) => {
                              e.onerror = null; // prevents looping
                              e.target.src = require('../../../Assets/Images/img-not-found-thumbNail.png');
                            }}
                          />
                        </div>

                        {/* venue name */}
                        <div className="px-2">
                          <p className="text-sm">{itm.vendor_name}</p>
                          <p className="text-xs">{itm.area}</p>
                        </div>
                      </div>

                      {/* price */}
                      <p className="text-sm">
                        {itm.price} {t('kwd')}
                      </p>
                    </div>
                  </div>

                  <div className="flex items-center justify-end gap-x-1 col-start-1 col-span-8 ">
                    <div className="grid grid-flow-col gap-x-1 bg-TransparentWhite rounded-xl px-1.5 py-px h-fit">
                      {itm.amenities.map((item) => {
                        return (
                          <img
                            className="w-4 h-4 rounded-full"
                            key={item.id}
                            alt={item.name}
                            src={item.icon}
                            onError={(e) => {
                              e.onerror = null; // prevents looping
                              e.target.src = require('../../../Assets/Images/img-not-found.png');
                            }}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Content;
