import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function SubscriptionOrderFailure(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex justify-center h-32">
        <img
          src={require('../../Assets/Images/Subscription Category/Fail.png')}
        />
      </div>

      <div className="my-2">
        <p className="text-lg">{t('We’re Sorry!')}</p>
        <p className="text-sm">{t('Your subscription payment failed')}</p>
      </div>
    </div>
  );
}

export default SubscriptionOrderFailure;
