import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import RootReducer from './Reducers/RootReducer';
import RootSaga from './Saga/RootSaga';
import { createLogger } from 'redux-logger';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  // key: 'PersistedStore',
  key: 'primary',
  storage: storage,
  blacklist: ['MainReducer'],
  debug: process.env.NODE_ENV !== 'production',
  version: 1,
};

const appLogger = createLogger({
  collapsed: true,
  duration: true,
  diff: true,
});

const PersistedReducer = persistReducer(persistConfig, RootReducer);

const sagaMiddleware = createSagaMiddleware();

const middleware =
  process.env.NODE_ENV !== 'production'
    ? applyMiddleware(sagaMiddleware, appLogger)
    : applyMiddleware(sagaMiddleware);

const Store = createStore(PersistedReducer, middleware);

const persistor = persistStore(Store);

export { persistor, Store };

sagaMiddleware.run(RootSaga);
