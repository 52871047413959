import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import GreyLine from '../../../Components/GreyLine';
import { ReactComponent as BlackLocation } from '../../../Assets/Icons/BlackLocation.svg';
import { ReactComponent as BlackCalender } from '../../../Assets/Icons/BlackCalender.svg';
import { ReactComponent as BlackClock } from '../../../Assets/Icons/BlackClock.svg';
import { ReactComponent as BlackAvatar } from '../../../Assets/Icons/Class Category/BlackAvatar.svg';
import { ReactComponent as Payment } from '../../../Assets/Icons/Class Category/Payment.svg';
import BackBTN from '../../../Components/BackBTN';

import SectionTitle from '../../Venues/Venue Details/SectionTitle';

function Content(props) {
  const { t } = useTranslation();

  useEffect(() => {
    console.log(props.data);
  }, []);

  return (
    <div>
      <BackBTN
        text={
          <div class="flex justify-center">
            <p>{t('View Receipt')}</p>
          </div>
        }
      />

      <div className="flex flex-col items-start  mt-5 bg-LighterGray rounded-md p-2">
        <div>
          <p class="text-primary_BG">{t('Payment Receipt')}</p>
        </div>

        <GreyLine className="mb-3" />

        {/* location */}

        <SectionTitle
          icon={<BlackLocation width="8.73" height="15.425" />}
          title={t('Location')}
        />

        <div class="flex items-center pb-2">
          <div class="rounded-lg h-12 w-12">
            <img
              src={props.data.data.vendor_logo}
              class="rounded-lg h-full w-full"
            />
          </div>
          <div class="flex flex-col items-start px-2">
            <p className="text-sm">{props.data.data.vendor_name}</p>
            <p className="text-xs text-DarkGrey">{props.data.data.area}</p>
          </div>
        </div>

        <GreyLine className="mb-3" />

        {/* date and time */}

        <div className="flex justify-between w-full text-sm">
          <div className="flex flex-col justify-start">
            <SectionTitle
              icon={<BlackCalender width="12.613" height="14.801" />}
              title={t('Class date')}
            />
            <p>{props.data.data.date}</p>
          </div>

          <div className="flex flex-col justify-start">
            <SectionTitle
              icon={<BlackClock width="14.967" height="14.967" />}
              title={t('Time')}
            />
            <p>{props.data.data.time}</p>
          </div>
        </div>

        <GreyLine className="my-3" />

        <div className="flex flex-col justify-start">
          <SectionTitle
            icon={<BlackAvatar width="10.864" height="12.332" />}
            title={t('Billed to')}
          />
          <p className="w-fit">{props.data.data.customer_name}</p>
        </div>

        <GreyLine className="my-3" />

        {/* payment */}
        <div className="flex flex-col justify-start">
          <SectionTitle
            icon={<Payment width="20" height="12.51" />}
            title={t('Payment')}
          />
          {/* <p className='w-fit'>{props.data.data.customer_name}</p> */}
        </div>

        <GreyLine className="my-3" />

        <div className="flex justify-between w-full mb-2">
          <div className="flex flex-col justify-start">
            <p className="text-xl">{props.data.data.class_name}</p>
            <div className="flex">
              <BlackLocation width="10.73" height="16.425" />
              <p className="px-1 text-xs">{props.data.data.area}</p>
            </div>
          </div>

          <div className="flex flex-col justify-between text-sm">
            <p>{t('Total amount')}</p>
            <p className="text-primary_BG">
              {props.data.data.price} {props.data.data.currency}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Content;
