import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import BackBTN from '../../Components/BackBTN';
import CustomButton from '../../Components/CustomButton';
import {
  SaveTokenAction,
  SaveUserInfoAction,
  UserLoggedInAction,
} from '../../Redux/Actions';
import { mainUrl } from '../../Redux/BaseUrls';
import { ExecuteBasedOnCat } from '../../Utils/Utils';

export default function Content() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const Phone = location.state ? location.state.Phone : '';

  const UserInfo = useSelector((state) => state.PersistedReducer.UserInfo);
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );

  const [Code, SetCode] = useState({ num1: '', num2: '', num3: '', num4: '' });
  const [VerifyCodeRes, SetVerifyCodeRes] = useState({});

  const handelCodeChange = (elem, nm) => {
    // console.log(elem,elem.value,elem.name,elem.id)
    SetCode((prev) => ({
      ...prev,
      [nm]: elem.value,
    }));

    //Focus next input
    if (elem.nextSibling && elem.value !== '') {
      elem.nextSibling.focus();
    }
  };

  // verify code request
  const HandelVerifyCode = () => {
    // console.log(Code.num1.concat(Code.num2,Code.num3,Code.num4), UserInfo)
    mainUrl
      .post(`verify-code`, {
        phone: UserInfo.user.phone ? UserInfo.user.phone : Phone,
        code: Code.num1.concat(Code.num2, Code.num3, Code.num4),
      })
      .then((response) => response.data)
      .then((data) => SetVerifyCodeRes(data));
  };

  // if code verified successfully (save token set logged in true )
  // or not
  useEffect(() => {
    console.log(VerifyCodeRes);
    if (VerifyCodeRes.success === true) {
      dispatch(UserLoggedInAction(true));
      dispatch(SaveTokenAction(UserInfo.access_token));

      // navigate based on cat
      // navigate based on cat
      ExecuteBasedOnCat(
        UserSelectedData.CategoryId,
        () => navigate('/ReviewOrder'),
        () => navigate('/OrderReview'),
        () => navigate('/OrderReview'),
        () => navigate('/ReviewOrder/Store')
      );
    } else if (VerifyCodeRes.success === false) {
      //   navigate('/CreateAccount')
    }
  }, [VerifyCodeRes]);

  useEffect(() => {
    console.log(Phone);
  }, []);

  return (
    <div className="h-full">
      <BackBTN />

      {/* enter your code */}
      <div className="h-full flex flex-col justify-between">
        <div className="flex flex-col items-center justify-between grow">
          <div>
            <div className="flex justify-center mb-5">
              <p className="w-1/2 ">
                {t(
                  'We’ve sent a verification code to your number to continue registration'
                )}
              </p>
            </div>

            <div className="flex justify-center py-5">
              <img
                className="h-52"
                src={require('../../Assets/Images/EnterCode.png')}
              />
            </div>
          </div>

          <div className="flex justify-center gap-x-2 mb-8">
            <input
              autofocus="true"
              id="num1"
              maxlength="1"
              type="text"
              class=" outline-none bg-gray rounded-xl text-center w-10 h-10"
              onChange={(e) => handelCodeChange(e.target, 'num1')}
            />

            <input
              id="num1"
              maxlength="1"
              type="text"
              class=" outline-none bg-gray rounded-xl text-center w-10 h-10"
              onChange={(e) => handelCodeChange(e.target, 'num2')}
            />

            <input
              id="num1"
              maxlength="1"
              type="text"
              class=" outline-none bg-gray rounded-xl text-center w-10 h-10"
              onChange={(e) => handelCodeChange(e.target, 'num3')}
            />

            <input
              id="num1"
              maxlength="1"
              type="text"
              class=" outline-none bg-gray rounded-xl text-center w-10 h-10"
              onChange={(e) => handelCodeChange(e.target, 'num4')}
            />
          </div>
        </div>

        {/* continue btn */}
        <CustomButton
          onclick={() => {
            HandelVerifyCode();
          }}
          ContainerClass="w-full bg-primary_BG rounded-lg text-sm text-white py-1 my-5"
          BtnValue={t('Continue')}
        />
      </div>
    </div>
  );
}
