import { useTranslation } from 'react-i18next';
import BackBTN from './../../Components/BackBTN';
import { ReactComponent as AccountSettings } from '../../Assets/Icons/AccountSettings.svg';

function TermsAndConditions(props) {
  const { t } = useTranslation();

  return (
    <div>
      <BackBTN
        text={
          <div className="flex items-cener justify-center my-3">
            <AccountSettings width="20.111" height="24.354" />
            <p className="px-1">{t('Terms And Conditions')}</p>
          </div>
        }
      />

      <div dangerouslySetInnerHTML={{ __html: props.data.data[1].body }} />
    </div>
  );
}

export default TermsAndConditions;
