import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import GreyLine from '../../../Components/GreyLine';
import { ReactComponent as BlackLocation } from '../../../Assets/Icons/BlackLocation.svg';
import { ReactComponent as BlackCalender } from '../../../Assets/Icons/BlackCalender.svg';
import { ReactComponent as BlackClock } from '../../../Assets/Icons/BlackClock.svg';
import { ReactComponent as BlackAvatar } from '../../../Assets/Icons/Class Category/BlackAvatar.svg';
import { ReactComponent as Payment } from '../../../Assets/Icons/Class Category/Payment.svg';

import SectionTitle from '../../Venues/Venue Details/SectionTitle';
import BackBTN from '../../../Components/BackBTN';

function SubscriptionReceipt(props) {
  const { t } = useTranslation();

  useEffect(() => {
    console.log(props.data);
  }, []);

  return (
    <div>
      <BackBTN
        text={
          <div class="flex justify-center">
            <p>{t('View Receipt')}</p>
          </div>
        }
      />

      <div className="flex flex-col items-start  mt-5 bg-LighterGray rounded-md py-2 px-3">
        <div>
          <p class="text-primary_BG">{t('Payment Receipt')}</p>
        </div>

        <GreyLine className="mb-3" />

        <div className="flex flex-col justify-start">
          <SectionTitle
            icon={<BlackAvatar width="10.864" height="12.332" />}
            title={t('Billed to')}
          />
          <p className="w-fit">{props.data.data.customer_name}</p>
        </div>

        <GreyLine className="my-3" />

        {/* date */}

        <div className="flex justify-between w-full text-sm">
          <div className="flex flex-col justify-start">
            <SectionTitle
              icon={<BlackCalender width="12.613" height="14.801" />}
              title={t('Start Date')}
            />
            <p>{props.data.data.start_date}</p>
          </div>
        </div>

        <GreyLine className="my-3" />

        {/* payment */}
        <div className="flex flex-col justify-start w-full">
          <SectionTitle
            icon={<Payment width="20" height="12.51" />}
            title={t('Payment')}
          />
          <div className="flex justify-between items-center">
            <p className="w-fit">{props.data.data.payment_method.name}</p>
            <img
              className="w-7 h-7"
              src={props.data.data.payment_method.image}
            />
          </div>
        </div>

        <GreyLine className="my-3" />

        <div className="flex justify-between w-full mb-2">
          <div className="flex flex-col justify-between">
            <p className="text-lg">{props.data.data.subscription_name}</p>
            <div className="flex">
              <p className="text-xs">{props.data.data.subscription_duration}</p>
            </div>
          </div>

          <div className="flex flex-col justify-between text-sm">
            <p>{t('Total amount')}</p>
            <p className="text-primary_BG">{props.data.data.price}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionReceipt;
