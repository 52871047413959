import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../Components/CustomButton';
import { SavePhoneAction, ToggleShowHideAction } from '../../Redux/Actions';
import { mainUrl } from '../../Redux/BaseUrls';

export default function Content(props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const UserInfo = useSelector((state) => state.PersistedReducer.UserInfo);

  const [Data, SetData] = useState({
    code: UserSelectedData.Country.code && UserSelectedData.Country.code,
    phone: '',
  });
  const [SendOtpRes, SetSendOtpRes] = useState({});

  // send otp code to user
  const SendOTPReq = () => {
    mainUrl
      .post(
        `send-otp`,
        { ...Data },
        {
          headers: {
            Authorization: `Bearer ${UserInfo.access_token}`,
            lang: i18n.language,
          },
        }
      )
      .then((response) => response.data)
      .then((data) => SetSendOtpRes(data));
  };

  const HandelContinue = () => {
    if (UserInfo.access_token) {
      if (Data.code && Data.phone !== '') {
        SendOTPReq();
      } else {
        dispatch(
          ToggleShowHideAction({
            Open: true,
            Type: 'info',
            Msg: t('Please select phone and code '),
          })
        );
      }
    } else {
      navigate('/login');
    }
  };

  // navigate and save phone when code is sent
  useEffect(() => {
    if (SendOtpRes.success === true) {
      navigate('/OTP/Code', { state: { Phone: Data.phone } });
    } else if (SendOtpRes.success === false) {
      dispatch(
        ToggleShowHideAction({
          Open: true,
          Type: 'error',
          Msg: SendOtpRes.message,
        })
      );
    }
  }, [SendOtpRes]);

  return (
    <div>
      <div className="flex justify-center mb-5">
        <p className="w-1/2">
          {t('Create your Form account to continue your booking')}
        </p>
      </div>

      <div className="flex justify-center py-5">
        <img src={require('../../Assets/Images/EnterPhone.png')} />
      </div>

      <div className="grid grid-cols-4 gap-x-2 items-center">
        <select
          onChange={(e) => {
            SetData((prev) => ({ ...prev, code: e.target.value }));
          }}
          className="bg-primary_BG h-fit text-white text-center text-sm rounded-xl outline-none p-1"
        >
          {props.Countries.data.map((itm) => {
            return (
              <option
                selected={UserSelectedData.Country.code === itm.code && true}
                key={itm.id}
                value={itm.code}
              >
                {itm.code}
              </option>
            );
          })}
        </select>

        <div className="col-start-2 col-span-3">
          <input
            onChange={(e) => {
              SetData((prev) => ({ ...prev, phone: e.target.value }));
            }}
            type="tel"
            placeholder={t('Enter your phone')}
            className="text-center rounded-lg px-3 py-1 text-sm bg-gray outline-none w-full"
          />
        </div>
      </div>

      <CustomButton
        onclick={() => HandelContinue()}
        ContainerClass=" w-full bg-primary_BG rounded-lg text-sm text-white py-1 my-5"
        BtnValue={t('Continue')}
      />
    </div>
  );
}
