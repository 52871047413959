import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GetShoppingCart } from '../API/APIRequests';
import { ReactComponent as BurgerMenu } from '../Assets/Icons/BurgerMenu.svg';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import SideMenu from './SideMenu';
import Badge from '@mui/material/Badge';

function Header({ ShowCart = false }) {
  const { i18n } = useTranslation();
  const state = useSelector((state) => state);
  const navigate = useNavigate();

  const UserInfo = useSelector((state) => state.PersistedReducer.UserInfo);
  const persistedReducer = useSelector((state) => state.PersistedReducer);
  const MainReducer = useSelector((state) => state.MainReducer);
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const [Cart, SetCart] = useState({});
  const [OpenSideMenu, SetOpenSideMenu] = useState(false);

  const handelCloseSideMenu = () => {
    SetOpenSideMenu(false);
  };

  useEffect(() => {
    if (UserSelectedData.Country) {
      // dispatch(GetCartAction())
      GetShoppingCart(
        {
          countryID: persistedReducer.UserSelectedData.Country.id,
          data: {
            // ...(persistedReducer.Loggedin ?
            //     {user_id:persistedReducer.UserInfo.user.id}
            //     :{tempId:persistedReducer.TempID}
            // ),
            ...(persistedReducer.Loggedin && {
              user_id: persistedReducer.UserInfo.user.id,
            }),
            tempId: persistedReducer.TempID ? persistedReducer.TempID : null,
          },
        },
        (res) => {
          SetCart(res);
        }
      );
    }
  }, [
    i18n.language,
    UserSelectedData.Country,
    MainReducer.CartChanged,
    persistedReducer.Loggedin,
  ]);

  useEffect(() => {
    console.log('all state', state);
  }, [state]);

  return (
    <div className="w-full">
      <SideMenu isOpen={OpenSideMenu} onClose={() => handelCloseSideMenu()} />
      <div className="grid grid-cols-4 w-full p-5 items-center">
        <div>
          <div
            className="cursor-pointer w-fit"
            onClick={() => SetOpenSideMenu(true)}
          >
            <BurgerMenu />
          </div>
        </div>

        {/* logo */}
        <div
          onClick={() => {
            navigate('/home');
          }}
          className="flex justify-center col-start-2 col-span-2 cursor-pointer"
        >
          <div className="flex justify-center">
            <img className="h-12" src={require('../Assets/Images/Logo.png')} />
          </div>
        </div>

        <div className="flex justify-end items-center gap-x-5">
          {ShowCart === true && (
            <>
              {Cart.success && (
                <>
                  {Cart.data.length === 0 || Cart.data.items?.length === 0 ? (
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        navigate('/ShoppingCart');
                      }}
                    >
                      <div className="mx-1.5">
                        <ShoppingCartOutlinedIcon />
                      </div>
                    </div>
                  ) : (
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        navigate('/ShoppingCart');
                      }}
                    >
                      <Badge
                        sx={{
                          '& .MuiBadge-badge': {
                            color: 'white',
                            backgroundColor: 'red',
                            fontSize: 9,
                            height: 15,
                            minWidth: 15,
                          },
                        }}
                        badgeContent={Cart.data.items.length}
                        max={99}
                      >
                        <div className="mx-1.5">
                          <ShoppingCartOutlinedIcon />
                        </div>
                      </Badge>
                    </div>
                  )}
                </>
              )}
            </>
          )}

          {UserInfo.user && (
            <img className="rounded-full w-9 h-9" src={UserInfo.user.avatar} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
