import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { types } from '../ActionTypes';
import {
  IsGuestUserActionSuccess,
  LogOutActionSuccess,
  SaveCartActionSuccess,
  SaveInvoiceIdActionSuccess,
  SaveOrderStatusActionSuccess,
  SaveTempIDActionSuccess,
  SaveTokenActionSuccess,
  SaveUserDataActionSuccess,
  SaveUserInfoActionSuccess,
  SetCartChangedFlagActionSuccess,
  setLang,
  ToggleShowHideActionSuccess,
  UserLoggedInActionSuccess,
} from '../Actions';
import { mainUrl } from '../BaseUrls';
import i18n from '../../i18n/Config';

function* SaveTempIdWorker({ payload }) {
  yield put(SaveTempIDActionSuccess(payload));
}

// save user info
function* SaveUserInfoWorker({ payload }) {
  yield put(SaveUserInfoActionSuccess(payload));
}

// save token
function* SaveTokenWorker({ payload }) {
  yield put(SaveTokenActionSuccess(payload));
}

// login flag
function* UserLoggedinWorker({ payload }) {
  yield put(UserLoggedInActionSuccess(payload));
}

// guest user flag
function* IsGuestUserWorker({ payload }) {
  yield put(IsGuestUserActionSuccess(payload));
}

// logout
function* LogOutWorker() {
  // yield call(GenerateTempIdWorker)  // this blocks flow until GenerateTempIdWorker is done
  // console.log('in logout')
  yield put(LogOutActionSuccess('Logged out successfully !!'));
}

// alert hide and show
function* ToggleHideShowAlertWorker({ payload }) {
  yield put(ToggleShowHideActionSuccess(payload));
}

//save user selected data.................................................................................
function* SaveSelectedDataWorker({ payload }) {
  // console.log(payload)
  yield put(SaveUserDataActionSuccess(payload));
}

// order invoice is
function* SaveInvoiceIdWorker({ payload }) {
  yield put(SaveInvoiceIdActionSuccess(payload));
}

// order status
function* SaveOrderStatusWorker({ payload }) {
  yield put(SaveOrderStatusActionSuccess(payload));
}

// save cart
function* SaveCartWorker({ payload }) {
  yield put(SaveCartActionSuccess(payload));
}

// cart changed flag
function* SetCartChangedFlagWorker({ payload }) {
  console.log(payload);
  yield put(SetCartChangedFlagActionSuccess(payload));
}

function* startChangeLocaleScenario({ payload }) {
  yield call(i18n.changeLanguage, payload);
  yield put(setLang(payload));
}

// watchers
export default function* FetchSaga() {
  yield takeEvery(types.Save_TempID, SaveTempIdWorker);
  yield takeEvery(types.Save_User_Info, SaveUserInfoWorker);
  yield takeEvery(types.User_LoggedIn, UserLoggedinWorker);
  yield takeEvery(types.Is_Guest_User, IsGuestUserWorker);
  yield takeEvery(types.Save_Token, SaveTokenWorker);
  yield takeEvery(types.LogOut, LogOutWorker);

  yield takeEvery(types.Toggle_Hide_Show_Alert, ToggleHideShowAlertWorker);

  yield takeEvery(types.Save_Selected_Data, SaveSelectedDataWorker);

  yield takeEvery(types.Save_InvoiceId_Data, SaveInvoiceIdWorker);
  yield takeEvery(types.Save_OrderStaus_Data, SaveOrderStatusWorker);

  yield takeEvery(types.Save_Cart, SaveCartWorker);
  yield takeEvery(types.Set_Cart_Change_Flag, SetCartChangedFlagWorker);
  yield takeEvery(types.CHANGE_LOCALE, startChangeLocaleScenario);
}
