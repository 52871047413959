import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ClearIcon from '@mui/icons-material/Clear';
import Counter from '../../../Components/Counter';
import {
  GetCartAction,
  SetCartChangedFlagAction,
} from '../../../Redux/Actions';
import { IncDecCartItem, RemoveFromCart } from '../../../API/APIRequests';
import BackBTN from '../../../Components/BackBTN';
import { useTranslation } from 'react-i18next';
import GreyLine from '../../../Components/GreyLine';
import CustomButton from '../../../Components/CustomButton';
import { useNavigate } from 'react-router-dom';

function Content(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const Cart = props.data;
  const MainReducer = useSelector((state) => state.MainReducer);
  const persistedReducer = useSelector((state) => state.PersistedReducer);
  const [Total, SetTotal] = useState(Cart.total);
  const [RemoveFromCartRes, SetRemoveFromCartRes] = useState({});
  const [IncDecItemQtyRes, SetIncDecItemQtyRes] = useState({});

  useEffect(() => {
    let total = 0;
    for (let i = 0; i < Cart.data.items.length; i++) {
      total += parseFloat(Cart.data.items[i].item_price);
    }
    SetTotal(total);
  }, [Cart]);

  const DisplayAddons = (addonarray) => {
    let addons = '';

    for (let i = 0; i < addonarray.length; i++) {
      if (i === addonarray.length - 1) {
        addons = addons.concat('+', addonarray[i].qty, ' ', addonarray[i].name);
      } else {
        addons = addons.concat(
          '+',
          addonarray[i].qty,
          ' ',
          addonarray[i].name,
          ' | '
        );
      }
    }

    return addons;
  };

  const RemoveItem = (id) => {
    RemoveFromCart(
      {
        countryID: persistedReducer.UserSelectedData.Country.id,
        data: {
          // ...(persistedReducer.Loggedin ?
          //     {user_id:persistedReducer.UserInfo.user.id}
          //     :{tempId:persistedReducer.TempID}
          // ),

          ...(persistedReducer.Loggedin && {
            user_id: persistedReducer.UserInfo.user.id,
          }),
          tempId: persistedReducer.TempID ? persistedReducer.TempID : null,
          cart_item_id: id,
        },
      },
      (res) => {
        SetRemoveFromCartRes(res);
      }
    );
  };

  const IncDecItem = (id, qty) => {
    if (qty >= 1) {
      IncDecCartItem(
        {
          countryID: persistedReducer.UserSelectedData.Country.id,
          data: {
            ...(persistedReducer.Loggedin && {
              user_id: persistedReducer.UserInfo.user.id,
            }),
            tempId: persistedReducer.TempID ? persistedReducer.TempID : null,
            cart_item_id: id,
            quantity: qty,
          },
        },
        (res) => {
          SetIncDecItemQtyRes(res);
        }
      );
    }
  };

  useEffect(() => {
    if (RemoveFromCartRes.success === true) {
      dispatch(SetCartChangedFlagAction(!MainReducer.CartChanged));
    }
  }, [RemoveFromCartRes]);

  useEffect(() => {
    if (IncDecItemQtyRes.success === true) {
      dispatch(SetCartChangedFlagAction(!MainReducer.CartChanged));
    }
  }, [IncDecItemQtyRes]);

  useEffect(() => {
    // console.log(MainReducer.CartChanged)
  }, [MainReducer.CartChanged]);

  return (
    <div className="flex flex-col justify-between w-full">
      <div>
        <BackBTN />

        <div className="my-5">
          {Cart.data.items.map((itm) => {
            return (
              <div
                key={itm.item_id}
                className="rounded-lg py-2 px-3 mb-5"
                style={{ boxShadow: '0px 1px 5px 1px #DEDEDE' }}
              >
                <div className="flex justify-between">
                  <p className="text-primary_BG text-start">{itm.item_name}</p>
                  <div
                    onClick={() => RemoveItem(itm.cart_item_id)}
                    className="cursor-pointer"
                  >
                    <ClearIcon fontSize="small" />
                  </div>
                </div>
                <p className="text-xs text-start mt-px mb-3">
                  {DisplayAddons(itm.item_options)}
                </p>

                {/* <div className='text-start my-3'>
                                    <p>Extra notes</p>
                                    <p></p>
                                </div> */}

                <div className="flex justify-between mt-5">
                  <Counter
                    val={itm.item_qty}
                    inc={() => IncDecItem(itm.cart_item_id, itm.item_qty + 1)}
                    dec={() => IncDecItem(itm.cart_item_id, itm.item_qty - 1)}
                  />
                  <p>{itm.item_price}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div>
        <div className="flex justify-between gap-x-2 text-DarkGrey">
          <p>{t('Total')}</p>
          <p>{Total}</p>
        </div>
        <GreyLine />

        <div className="py-5">
          <CustomButton
            onclick={() => {
              navigate('/DeliveryDetails');
            }}
            BtnValue={t('Continue To CheckOut')}
            ContainerClass="bg-primary_BG rounded-xl text-sm text-white cursor-pointer py-1"
          />
        </div>
      </div>
    </div>
  );
}

export default Content;
