import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { GetAllProducts } from '../../../API/APIRequests';
import Footer from '../../../Components/Footer';
import Header from '../../../Components/Header';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import Content from './Content';
import MainLayout from '../../../Components/MainLayout';

function AllProducts(props) {
  const { i18n } = useTranslation();
  const { CatId } = useParams();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const [AllProductsRes, SetAllProductsRes] = useState({});

  useEffect(() => {
    if (UserSelectedData.Country) {
      GetAllProducts(
        {
          countryID: UserSelectedData.Country.id,
          data: {
            ...(CatId === 'null' ? {} : { category_id: CatId }),
            limit: 10,
            page: 1,
          },
        },
        (res) => {
          SetAllProductsRes(res);
        }
      );
    }
  }, [i18n.language, UserSelectedData.Country]);

  useEffect(() => {
    console.log(AllProductsRes);
  }, [AllProductsRes]);

  return (
    <MainLayout showCart={true} Cat='Store'>
      {AllProductsRes.success ? (
        <div className="px-3 md:px-0 lg:w-1/3 grow">
          {AllProductsRes.data.length === 0 ? (
            <div className="h-full justify-center items-center">
              <p>No Products found</p>
            </div>
          ) : (
            <Content
              data={AllProductsRes.data}
              pagination={AllProductsRes.pagination}
            />
          )}
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </MainLayout>
  );
}

export default AllProducts;
