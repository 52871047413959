import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BackBTN from '../../Components/BackBTN';
import { ReactComponent as AccountSettings } from '../../Assets/Icons/AccountSettings.svg';
import { ReactComponent as Camera } from '../../Assets/Icons/Camera.svg';
import { useTranslation } from 'react-i18next';
import GreyLine from '../../Components/GreyLine';
import { mainUrl } from '../../Redux/BaseUrls';
import { UpdateAccountSettings, UpdateAvatar } from '../../API/APIRequests';
import CustomButton from '../../Components/CustomButton';
import { SaveUserInfoAction, ToggleShowHideAction } from '../../Redux/Actions';
import ChangePassModal from './ChangePassModal';

export default function Content() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const UserInfo = useSelector((state) => state.PersistedReducer.UserInfo);
  const Token = useSelector((state) => state.PersistedReducer.Token);
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );

  const [UserData, SetUserData] = useState({
    name: UserInfo.user.name,
    email: UserInfo.user.email,
    country_code: UserSelectedData.Country.code,
    phone: UserInfo.user.phone,
    date_of_birth: UserInfo.user.date_of_birth,
    gender: UserInfo.user.gender,
  });

  const [selectedImagePreview, SetSelectedImagePreview] = useState(
    UserInfo.user.avatar
      ? UserInfo.user.avatar
      : require('../../Assets/Images/avatar.png')
  );
  const [SeletedImgFile, SetSeletedImgFile] = useState('');

  const [UpdateAvatarRes, SetUpdateAvatarRes] = useState({});
  const [UpdateAccountSettingsRes, SetUpdateAccountSettingsRes] = useState({});

  const [OpenChangePassModal, SetOpenChangePassModal] = useState(false);

  // update img when new img is seleced
  useEffect(() => {
    if (SeletedImgFile !== '') {
      console.log(SeletedImgFile);
      UpdateAvatar(
        {
          country: UserSelectedData.Country.id,
          data: SeletedImgFile,
          token: Token,
        },
        (res) => {
          SetUpdateAvatarRes(res);
        }
      );
    }
  }, [SeletedImgFile]);

  // when img change req is sent
  useEffect(() => {
    // update img in uaer info
    if (UpdateAvatarRes.success === true) {
      const updatedUserInfo = {
        ...UserInfo,
        user: UpdateAvatarRes.data,
      };
      dispatch(SaveUserInfoAction(updatedUserInfo));
    }

    if (UpdateAvatarRes.success === false) {
      dispatch(
        ToggleShowHideAction({
          Open: true,
          Type: 'error',
          Msg: UpdateAvatarRes.message,
        })
      );
    }

    // console.log(UpdateAvatarRes)
  }, [UpdateAvatarRes]);

  // change user data
  const HandelChangeUserData = (nm, val) => {
    SetUserData((prev) => ({
      ...prev,
      [nm]: val,
    }));
  };

  // HandelUpdate accountsettings
  const HandelUpdateAcciuntSettings = () => {
    UpdateAccountSettings(
      {
        country: UserSelectedData.Country.id,
        data: UserData,
        token: Token,
      },
      (res) => {
        SetUpdateAccountSettingsRes(res);
      }
    );
  };

  // update account res check
  useEffect(() => {
    // console.log(UpdateAccountSettingsRes)
    if (UpdateAccountSettingsRes.success === true) {
      // update user info data
      const updatedUserInfo = {
        ...UserInfo,
        user: UpdateAccountSettingsRes.data,
      };
      dispatch(SaveUserInfoAction(updatedUserInfo));

      dispatch(
        ToggleShowHideAction({
          Open: true,
          Type: 'success',
          Msg: UpdateAccountSettingsRes.message,
        })
      );
    }

    if (UpdateAccountSettingsRes.success === false) {
      dispatch(
        ToggleShowHideAction({
          Open: true,
          Type: 'error',
          Msg: UpdateAccountSettingsRes.message,
        })
      );
    }
  }, [UpdateAccountSettingsRes]);

  // test
  useEffect(() => {
    // console.log(SeletedImgFile)
  }, [SeletedImgFile]);

  return (
    <div>
      <BackBTN
        text={
          <div className="flex items-cener justify-center ">
            <AccountSettings width="20.111" height="24.354" />
            <p className="px-1">{t('Account Settings')}</p>
          </div>
        }
      />

      {/* avatar img */}
      <div className="flex justify-center items-end px-2">
        <img src={selectedImagePreview} className="rounded-full w-12 h-12" />
        <label
          htmlFor="Selectimg"
          class="relative ltr:right-4 rtl:left-4 cursor-pointer"
          // onClick={()=>handleClick()}
        >
          <Camera width="20" height="20" />
        </label>

        <input
          id="Selectimg"
          type="file"
          accept="image/*"
          class="hidden"
          onChange={(e) => {
            // console.log(e.target.files)
            SetSeletedImgFile(e.target.files[0]);
            SetSelectedImagePreview(URL.createObjectURL(e.target.files[0]));
          }}
        />
      </div>

      {/* info */}
      <div className="py-5">
        <div className="flex flex-col items-start text-sm py-2">
          <p className="text-DarkGrey mb-1">{t('Name')}</p>
          <input
            value={UserData.name}
            onChange={(e) => HandelChangeUserData('name', e.target.value)}
            className="border-none  focus:outline-none w-full"
            type="text"
          />
        </div>

        <GreyLine />

        <div className="flex flex-col items-start text-sm py-2">
          <p className="text-DarkGrey mb-1">{t('Email')}</p>
          <input
            value={UserData.email}
            onChange={(e) => HandelChangeUserData('email', e.target.value)}
            className="border-none  focus:outline-none w-full"
            type="email"
          />
        </div>

        <GreyLine />

        <div className="flex flex-col items-start text-sm py-2">
          <p className="text-DarkGrey mb-1">{t('Mobile Number')}</p>
          <input
            value={UserData.phone}
            onChange={(e) => HandelChangeUserData('phone', e.target.value)}
            className="border-none  focus:outline-none w-full"
            type="tel"
          />
        </div>

        <GreyLine />

        <div className="flex flex-col items-start text-sm py-2">
          <p className="text-DarkGrey mb-1">{t('Gender')}</p>
          <select
            value={UserData.gender}
            onChange={(e) => HandelChangeUserData('gender', e.target.value)}
            className="border-none  focus:outline-none w-full"
          >
            <option value="male">{t('male')}</option>
            <option value="female">{t('female')}</option>
          </select>
        </div>

        <GreyLine />

        <div className="flex flex-col items-start text-sm py-2">
          <p className="text-DarkGrey mb-1">{t('Date of Birth')}</p>
          <input
            value={UserData.date_of_birth}
            onChange={(e) =>
              HandelChangeUserData('date_of_birth', e.target.value)
            }
            className="border-none  focus:outline-none w-full"
            type="date"
          />
        </div>

        <GreyLine />

        <div className="flex flex-col items-start text-sm py-2">
          <p className="text-DarkGrey mb-1">{t('Password')}</p>
          <input
            value={UserData.pass}
            onChange={(e) =>
              HandelChangeUserData('pass', e.target.value)
            }
            className="border-none  focus:outline-none w-full"
            type="password"
          />
        </div>
      </div>

      <div className="flex py-3">
        <p
          onClick={() => SetOpenChangePassModal(true)}
          className="text-primary_BG cursor-pointer"
        >
          {t('Change your Password')}
        </p>
      </div>

      <CustomButton
        onclick={() => HandelUpdateAcciuntSettings()}
        ContainerClass="bg-primary_BG rounded-lg text-sm text-white py-1 my-5"
        BtnValue={t('Update My Account')}
      />

      <ChangePassModal
        IsOpen={OpenChangePassModal}
        onclose={() => SetOpenChangePassModal(false)}
      />
    </div>
  );
}
