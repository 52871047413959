import React from 'react';
import { useTranslation } from 'react-i18next';
// import { useDispatch } from 'react-redux'
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
// import LoadingSpinner from '../../Components/LoadingSpinner'
import Content from './Content';
import MainLayout from '../../Components/MainLayout';

export default function SelectDate() {
  return (
    <MainLayout>
      <div className="w-11/12 lg:w-1/3 sm:w-1/2 mt-5 grow">
        <Content />
      </div>
    </MainLayout>
  );
}
