import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import BackBTN from '../../../Components/BackBTN';
import GreyLine from '../../../Components/GreyLine';

import CallIcon from '@mui/icons-material/Call';
import PersonIcon from '@mui/icons-material/Person';
import PaymentIcon from '@mui/icons-material/Payment';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import HouseSidingIcon from '@mui/icons-material/HouseSiding';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

function StoreReceipt(props) {
  const { t } = useTranslation();

  useEffect(() => {
    console.log(props.data);
  }, []);

  return (
    <div>
      <BackBTN
        text={
          <div class="flex justify-center">
            <p>{t('View Receipt')}</p>
          </div>
        }
      />

      <div className="flex flex-col items-start  mt-5 bg-LighterGray rounded-md py-4 px-3">
        <div className="mb-2">
          <p class="text-primary_BG">{t('Payment Receipt')}</p>
        </div>

        <GreyLine className="mb-3" />

        <div className="w-full text-start text-sm">
          {props.data.data.items.map((i, idx) => {
            return (
              <div className="">
                <p className="mb-1">{i.item_name}</p>
                <div className="flex justify-between gap-x-2">
                  <p>{i.addons.join(' , ')}</p>
                  <p className="text-primary_BG">{i.item_price}</p>
                </div>

                {idx !== props.data.data.items.length && (
                  <GreyLine className="my-2" />
                )}
              </div>
            );
          })}

          <div>
            <p className="mb-2 font-semibold text-primary_BG">
              {t('Billed to')}
            </p>

            <div className="flex items-center gap-x-1 mb-1 px-2">
              <PersonIcon sx={{ fontSize: 16 }} />
              <p className="w-fit">{props.data.data.customer_name}</p>
            </div>

            <div className="flex items-center gap-x-1 mb-1 px-2">
              <CallIcon sx={{ fontSize: 16 }} />
              <p className="w-fit">{props.data.data.customer_phone}</p>
            </div>
          </div>

          <GreyLine className="my-2" />

          <div>
            <p className="mb-2 font-semibold text-primary_BG">
              {t('Payment Method')}
            </p>

            <div className="flex items-center gap-x-1 mb-1 px-2">
              <PaymentIcon sx={{ fontSize: 16 }} />
              <p className="w-fit">{props.data.data.payment_method}</p>
            </div>
          </div>

          <GreyLine className="my-2" />

          <div>
            <p className="mb-2 font-semibold text-primary_BG">
              {t('Order Info')}
            </p>

            <div className="flex items-center gap-x-1 mb-1 px-2">
              <Grid3x3Icon sx={{ fontSize: 16 }} />
              <p className="w-fit">
                {t('Order Code')} : {props.data.data.order_code}
              </p>
            </div>

            <div className="flex items-center gap-x-1 mb-1 px-2">
              <ConfirmationNumberIcon sx={{ fontSize: 16 }} />
              <p className="w-fit">
                {t('Order ID')} : {props.data.data.receipt_id}
              </p>
            </div>
          </div>

          <GreyLine className="my-2" />

          <div>
            <p className="mb-2 font-semibold text-primary_BG">{t('Address')}</p>

            <div className="flex items-center gap-x-1 mb-1 px-2">
              <HouseSidingIcon sx={{ fontSize: 16 }} />
              <p className="w-fit">
                {Object.entries(props.data.data.address)
                  .map((entry) => entry.join(': '))
                  .join(',')}{' '}
              </p>
            </div>
          </div>

          <GreyLine className="my-2" />

          <div>
            <p className="mb-2 font-semibold text-primary_BG">
              {t('Vendor Info')}
            </p>

            <div className="flex items-center gap-x-1 mb-1 px-2">
              <AccountCircleIcon sx={{ fontSize: 16 }} />
              <p className="w-fit">{props.data.data.vendor_name} </p>
            </div>
          </div>

          <GreyLine className="my-2" />

          <div className="flex justify-between items-center">
            <p className="">{t('Total')}</p>
            <p className="text-primary_BG">{props.data.data.total}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StoreReceipt;
