import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../Components/Header';
import LoadingSpinner from '../../Components/LoadingSpinner';
import { GetAllCountriesAction } from '../../Redux/Actions';
import { mainUrl } from '../../Redux/BaseUrls';
import Content from './Content';
import MainLayout from '../../Components/MainLayout';

export default function EnterPhone() {
  const { i18n } = useTranslation();
  const [Countries, SetCountries] = useState({});

  const GetCountries = () => {
    mainUrl
      .get(`countries`, {
        headers: {
          lang: i18n.language,
        },
      })
      .then((response) => response.data)
      .then((data) => SetCountries(data));
  };

  useEffect(() => {
    GetCountries();
  }, [i18n.language]);

  return (
    <MainLayout>
      {Countries.success ? (
        <div className="lg:w-1/3 mt-5 grow">
          <Content Countries={Countries} />
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </MainLayout>
  );
}
