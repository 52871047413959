import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GetSubscriptionTypes } from '../../../API/APIRequests';
import Footer from '../../../Components/Footer';
import Header from '../../../Components/Header';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import Content from './Content';
import MainLayout from '../../../Components/MainLayout';

function SubscriptionTypes(props) {
  const { i18n } = useTranslation();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const [SubscriptionTypes, SetSubscriptionTypes] = useState({});

  useEffect(() => {
    GetSubscriptionTypes({ countryID: UserSelectedData.Country.id }, (res) => {
      SetSubscriptionTypes(res);
    });
  }, [i18n.language, UserSelectedData.Country.id]);

  useEffect(() => {
    console.log(SubscriptionTypes);
  }, [SubscriptionTypes]);

  return (
    <MainLayout Cat='Subscriptions'>
      {SubscriptionTypes.success ? (
        <div className="w-11/12 lg:w-1/3 sm:w-1/2 mt-5 grow">
          <Content SubscriptionTypes={SubscriptionTypes} />
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </MainLayout>
  );
}

export default SubscriptionTypes;
