import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ShareCopyLocation from '../../Components/ShareCopyLocation';
import { ToggleShowHideAction } from '../../Redux/Actions';

function SubscriptionHistory(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <>
      {props.History.data.map((itm) => {
        return (
          <div className="rounded-md bg-HistoryBG px-3 py-1 mb-5">
            <div className="flex flex-col items-start mb-2">
              <p className="text-xl text-primary_BG w-fit">
                {itm.subscription_name}
              </p>
              <div className="flex text-xs">
                <p>{itm.subscription_duration}</p>
              </div>
            </div>

            <div className="flex flex-col justify-start text-xs my-2">
              <div className="flex pb-1.5">
                <LocationOnIcon sx={{ fontSize: 14 }} />
                <p className="px-1">{itm.area}</p>
              </div>
              <div className="flex pb-1.5">
                <CalendarMonthIcon sx={{ fontSize: 14 }} />
                <p className="px-1">
                  {t('Start date:')} {itm.start_date}
                </p>
              </div>
            </div>

            {/* booked on */}
            <div className="w-full mb-2">
              <p className="text-primary_BG w-fit">{t('Subscribed On')}</p>
              <div className="flex justify-between text-xs">
                <p>{itm.subscribed_on}</p>
              </div>
            </div>

            <div className="h-px bg-black w-full"></div>

            {/* share loc and price */}
            <div className="flex justify-between items-center py-2">
              <ShareCopyLocation
                lat={itm.latitude}
                long={itm.longitude}
                ShareMsg={itm.share_message}
              />
              <p className="text-primary_BG">
                {itm.price} {itm.currency}
              </p>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default SubscriptionHistory;
