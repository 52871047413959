import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../Components/Header';
import Content from './Content';
import MainLayout from '../../Components/MainLayout';

export default function GuestLogin() {
  return (
    <MainLayout>
      <div className="lg:w-1/3 mt-5 grow">
        <Content />
      </div>
    </MainLayout>
  );
}
