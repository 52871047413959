import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  GetOrderReviewData,
  GetSubscriptionOrderReview,
} from '../../../API/APIRequests';
import Footer from '../../../Components/Footer';
import Header from '../../../Components/Header';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import { ToggleShowHideAction } from '../../../Redux/Actions';
import { CheckDateIsNotPassed, ModifyDateFormat } from '../../../Utils/Utils';
import Content from './Content';
import MainLayout from '../../../Components/MainLayout';

function OrderReview(props) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const [OrderReviewRes, SetOrderReviewRes] = useState({});

  useEffect(() => {
    switch (UserSelectedData.Category) {
      case 'Classes':
        {
          if (UserSelectedData.ClassDetails) {
            let DateIsPassed = CheckDateIsNotPassed(
              UserSelectedData.SelectedDate
            );

            if (DateIsPassed === true) {
              navigate('/SelectDate');
            } else {
              GetOrderReviewData(
                {
                  countryID: UserSelectedData.Country.id,
                  data: {
                    class_id: UserSelectedData.ClassDetails.ClassId,
                    date: UserSelectedData.ClassDetails.Date,
                  },
                },
                (res) => {
                  SetOrderReviewRes(res);
                }
              );
            }
          } else {
            navigate('/AllClassses');
          }
        }

        break;

      case 'Subscriptions':
        {
          if (
            UserSelectedData.SelectedSubscriptionDate &&
            UserSelectedData.SelectedSubscription
          ) {
            let DateIsPassed = CheckDateIsNotPassed(
              UserSelectedData.SelectedSubscriptionDate
            );

            if (DateIsPassed === true) {
              dispatch(
                ToggleShowHideAction({
                  Open: true,
                  Type: 'info',
                  Msg: t('please select a valid date'),
                })
              );
              navigate('/AllSubscriptions');
            } else {
              let date = ModifyDateFormat(
                UserSelectedData.SelectedSubscriptionDate
              );
              GetSubscriptionOrderReview(
                {
                  countryID: UserSelectedData.Country.id,
                  data: {
                    subscription_id: UserSelectedData.SelectedSubscription.id,
                    date: date,
                  },
                },
                (res) => {
                  SetOrderReviewRes(res);
                }
              );
            }
          } else {
            navigate('/AllSubscriptions');
          }
        }
        break;

      default:
        // GetSportsHistory()
        break;
    }
  }, [i18n.language, UserSelectedData.Country]);

  useEffect(() => {
    if (OrderReviewRes.success === false) {
      dispatch(
        ToggleShowHideAction({
          Open: true,
          Type: 'info',
          Msg: OrderReviewRes.message,
        })
      );
      navigate(-1);
    }
  }, [OrderReviewRes]);

  useEffect(() => {
    console.log('heeere', OrderReviewRes);
  }, [OrderReviewRes]);

  return (
    <MainLayout>
      {OrderReviewRes.success ? (
        <div className="w-11/12 lg:w-1/3 sm:w-1/2 mt-5 grow">
          <Content OrderReview={OrderReviewRes} />
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </MainLayout>
  );
}

export default OrderReview;
