import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SaveUserDataAction } from '../Redux/Actions';
import OfflinePage from '../Pages/Offline/Offline';

export const CheckDateIsNotPassed = (val) => {
  const today = new Date();
  let date = new Date(val);

  // 👇️ OPTIONAL!
  // This line sets the hour of the current date to midnight
  // so the comparison only returns `true` if the passed in date
  // is at least yesterday
  today.setHours(0, 0, 0, 0);

  return date < today;
};

export const ModifyDateFormat = (val) => {
  console.log(val);

  let dt = new Date(val);
  let date = [
    dt.getFullYear(),
    ('0' + (dt.getMonth() + 1)).slice(-2),
    ('0' + dt.getDate()).slice(-2),
  ].join('-');

  return date;
};

export const ExecuteBasedOnCat = (
  CatId,
  SportCat = () => {},
  ClassCat = () => {},
  SubsCat = () => {},
  StoreCat = () => {}
) => {
  console.log(CatId, SportCat, ClassCat, SubsCat);

  switch (CatId) {
    case 1:
      SportCat();
      break;
    case 2:
      ClassCat();
      break;
    case 3:
      SubsCat();
      break;
    case 4:
      StoreCat();
      break;
    default:
      break;
  }
};

// detect onlineoffline status and change cat
export function DetectOnlineStatus({ children, cat = undefined }) {
  const dispatch = useDispatch();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );

  // change cat if necessary
  useEffect(() => {
    if (cat !== undefined) {
      HandelChangeCat(cat);
    }
  }, [cat]);

  // set cat
  const HandelChangeCat = (cat) => {
    switch (cat) {
      case 'Sports':
        dispatch(
          SaveUserDataAction({
            ...UserSelectedData,
            Category: cat,
            CategoryId: 1,
          })
        );
        break;

      case 'Classes':
        dispatch(
          SaveUserDataAction({
            ...UserSelectedData,
            Category: cat,
            CategoryId: 2,
          })
        );
        break;

      case 'Subscriptions':
        dispatch(
          SaveUserDataAction({
            ...UserSelectedData,
            Category: cat,
            CategoryId: 3,
          })
        );
        break;

      case 'Store':
        dispatch(
          SaveUserDataAction({
            ...UserSelectedData,
            Category: cat,
            CategoryId: 4,
          })
        );
        break;

      case 'Event':
        dispatch(
          SaveUserDataAction({
            ...UserSelectedData,
            Category: cat,
            CategoryId: 5,
          })
        );
        break;

      default:
        break;
    }
  };

  return window.navigator.onLine ? children : <OfflinePage />;
}
