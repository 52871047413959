import React from 'react';
import CustomButton from '../../Components/CustomButton';

function OfflinePage(props) {
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <img
        className="h-1/3"
        alt="offline"
        src={require('../../Assets/Images/offline.webp')}
      />
      <p>Network is not available, please check your internet</p>
      <CustomButton
        ContainerClass="bg-primary_BG text-white w-fit rounded-full px-2 my-3"
        BtnValue="Reload"
        onclick={() => {
          window.location.reload();
        }}
      />
    </div>
  );
}

export default OfflinePage;
