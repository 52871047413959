import React from 'react';
import Content from './Content';
import MainLayout from '../../Components/MainLayout';

export default function AccountSettings() {
  return (
    <MainLayout active={`settings`}>
      <div className="lg:w-1/3 mt-5 grow">
        <Content />
      </div>
    </MainLayout>
  );
}
