import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import BackBTN from '../../Components/BackBTN';
import i18n from '../../i18n/Config';

import { ReactComponent as RightArrow } from '../../Assets/Icons/RightArrow.svg';
import { ReactComponent as LeftArrow } from '../../Assets/Icons/LeftArrow.svg';
import GreyLine from '../../Components/GreyLine';
import { SaveUserDataAction } from '../../Redux/Actions';
import { useNavigate } from 'react-router-dom';

export default function Content(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );

  const SaveArea = (selectedArea) => {
    dispatch(
      SaveUserDataAction({ ...UserSelectedData, SelectedArea: selectedArea })
    );
    navigate(-1);
  };

  return (
    <div>
      <BackBTN
        text={
          <div className="flex justify-center py-3">
            <p>{t('Select Area')}</p>
          </div>
        }
      />

      <div>
        {props.AllAreas.data.map((itm) => {
          return (
            <div key={itm.id}>
              <GreyLine className="my-3" />
              <div
                onClick={() => {
                  SaveArea(itm);
                }}
                className="flex justify-between cursor-pointer my-5"
              >
                <p className="text-primary_BG text-sm">{itm.name}</p>
                {i18n.language === 'ar' ? (
                  <LeftArrow width="13" height="13" />
                ) : (
                  <RightArrow width="13" height="13" />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
