import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../../../Components/Footer';
import Header from '../../../Components/Header';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import { mainUrl } from '../../../Redux/BaseUrls';
import Content from './Content';
import MainLayout from '../../../Components/MainLayout';

export default function VenueDetails() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { VenueId } = useParams();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const [Details, SetDetails] = useState({});

  const GetVenueDetails = (countryID, date) => {
    mainUrl
      .get(`venue-details/${VenueId}`, {
        headers: {
          country: countryID,
          lang: i18n.language,
        },
        params: {
          date: date,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        if (data.success === false) {
          if (data.status === '201') {
            navigate('/SelectDate');
          } else if (data.status === '404') {
            navigate('/notfound');
          }
        } else {
          SetDetails(data);
        }
      });
  };

  useEffect(() => {
    if (UserSelectedData.Country) {
      if (UserSelectedData.SelectedDate) {
        // console.log(UserSelectedData)

        // modify date format
        let dt = new Date(UserSelectedData.SelectedDate);
        let date = [
          dt.getFullYear(),
          ('0' + (dt.getMonth() + 1)).slice(-2),
          ('0' + dt.getDate()).slice(-2),
        ].join('-');

        GetVenueDetails(UserSelectedData.Country.id, date);
      } else {
        navigate('/sports');
      }
    } else {
      navigate('/home');
    }
  }, [i18n.language]);

  useEffect(() => {
    console.log('Details :', Details);
  }, [Details]);

  return (
    <MainLayout Cat='Sports'>
      {Details.success ? (
        <div className="mt-5 grow w-11/12 lg:w-1/3 sm:w-1/2">
          <Content Data={Details} />
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </MainLayout>
  );
}
