import React from 'react';
import EventNoteIcon from '@mui/icons-material/EventNote';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

import FooterTab from './FooterTab';

function Footer({ active = 'home' }) {
  return (
    <div className="w-full">
      <div className="h-32 w-full relative"></div>
      <div className="fixed left-0 bottom-0 flex justify-center w-full bg-LighterGray py-5">
        <div className="grid grid-cols-3 gap-x-2 w-11/12 lg:w-1/3 sm:w-1/2">
          <FooterTab
            Dir="/Home"
            ActiveIcon={
              <HomeIcon
                sx={{ color: '#189EC9' }}
                width="25.099"
                height="25.099"
              />
            }
            InActiveIcon={<HomeOutlinedIcon color="disabled" />}
            Text="Home"
            active={active === 'home' ? true : false}
          />

          <FooterTab
            Dir="/OrderHistory"
            ActiveIcon={
              <EventNoteIcon
                sx={{ color: '#189EC9' }}
                width="25.099"
                height="25.099"
              />
            }
            InActiveIcon={<EventNoteOutlinedIcon color="disabled" />}
            Text="History"
            active={active === 'history' ? true : false}
          />

          <FooterTab
            Dir="/AccountSettings"
            ActiveIcon={
              <ManageAccountsIcon
                sx={{ color: '#189EC9' }}
                width="25.099"
                height="25.099"
              />
            }
            InActiveIcon={<ManageAccountsOutlinedIcon color="disabled" />}
            Text="Settings"
            active={active === 'settings' ? true : false}
          />
        </div>
      </div>
    </div>
  );
}

export default Footer;
