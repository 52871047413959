import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import CustomButton from '../../Components/CustomButton';
import { useTranslation } from 'react-i18next';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { Input } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { ResetPassword } from '../../API/APIRequests';
import { useDispatch, useSelector } from 'react-redux';
import { ToggleShowHideAction } from '../../Redux/Actions';

function ChangePassModal({ IsOpen = false, onclose = () => {} }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.PersistedReducer.Token);
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );

  const [Open, SetOpen] = useState(IsOpen);
  const [ShowPass, SetShowPass] = useState({
    ShowOldPass: false,
    ShowNewPass: false,
    ShowPassconfirm: false,
  });
  const [Data, SetData] = useState({
    old_password: '',
    password: '',
    password_confirmation: '',
  });

  const [ResetPassRes, SetResetPassRes] = useState({});

  // set open modal
  useEffect(() => {
    SetOpen(IsOpen);
  }, [IsOpen]);

  // on modal close
  const OnCloseModal = () => {
    SetOpen(false);
    onclose();
  };

  // on inputs change
  const HandelChangePass = (nm, val) => {
    SetData((prev) => ({
      ...prev,
      [nm]: val,
    }));
  };

  // toggle show hide pass
  const handleClickShowPassword = (nm) => {
    // console.log(!ShowPass.nm,ShowPass[nm],ShowPass,nm)
    SetShowPass({
      ...ShowPass,
      [nm]: !ShowPass[nm],
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // reset pass req
  const HandelResetPassReq = () => {
    ResetPassword(
      {
        country: UserSelectedData.Country.id,
        data: Data,
        token: Token,
      },
      (res) => {
        SetResetPassRes(res);
      }
    );
  };

  useEffect(() => {
    if (ResetPassRes.success === true) {
      dispatch(
        ToggleShowHideAction({
          Open: true,
          Type: 'success',
          Msg: ResetPassRes.message,
        })
      );

      OnCloseModal();
    }

    if (ResetPassRes.success === false) {
      dispatch(
        ToggleShowHideAction({
          Open: true,
          Type: 'error',
          Msg: ResetPassRes.message,
        })
      );
    }
  }, [ResetPassRes]);

  useEffect(() => {
    console.log('pass data', Data, ShowPass);
  }, [Data, ShowPass]);

  return (
    <Modal
      open={Open}
      onClose={OnCloseModal}
      className="flex justify-center items-center"
    >
      <div className="outline-none w-1/4">
        <div className="flex flex-col bg-white px-10 py-2 rounded-xl ">
          <p className="font-bold text-center text-lg mt-5 mb-7">
            Change Password
          </p>

          <div className="mb-6">
            <FormControl size="sm" fullWidth variant="standard">
              <InputLabel htmlFor="old password">Old Password</InputLabel>
              <Input
                id="old password"
                type={ShowPass.ShowOldPass ? 'text' : 'password'}
                value={Data.old_password}
                label="Old Password"
                size="sm"
                required
                onChange={(e) =>
                  HandelChangePass('old_password', e.target.value)
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        handleClickShowPassword('ShowOldPass');
                      }}
                      onMouseDown={(e) => handleMouseDownPassword(e)}
                    >
                      {ShowPass.ShowOldPass ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>

          <div className="mb-6">
            <FormControl size="sm" fullWidth variant="standard">
              <InputLabel htmlFor="new pass">New Password</InputLabel>
              <Input
                id="new pass"
                type={ShowPass.ShowNewPass ? 'text' : 'password'}
                value={Data.password}
                label="New Password"
                required
                onChange={(e) => HandelChangePass('password', e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        handleClickShowPassword('ShowNewPass');
                      }}
                      onMouseDown={(e) => handleMouseDownPassword(e)}
                    >
                      {ShowPass.ShowNewPass ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>

          <div className="mb-6">
            <FormControl size="sm" fullWidth variant="standard">
              <InputLabel htmlFor="passConfirmation">
                Password Confirmation
              </InputLabel>
              <Input
                id="passConfirmation"
                type={ShowPass.ShowPassconfirm ? 'text' : 'password'}
                value={Data.password_confirmation}
                label="Password Confirmation"
                required
                onChange={(e) =>
                  HandelChangePass('password_confirmation', e.target.value)
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        handleClickShowPassword('ShowPassconfirm');
                      }}
                      onMouseDown={(e) => handleMouseDownPassword(e)}
                    >
                      {ShowPass.ShowPassconfirm ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>

          <CustomButton
            onclick={() => HandelResetPassReq()}
            ContainerClass="bg-primary_BG rounded-full text-sm text-white text-center py-1 my-5"
            BtnValue={t('Update Password')}
          />
        </div>
      </div>
    </Modal>
  );
}

export default ChangePassModal;
