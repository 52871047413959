import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import BackBTN from '../../Components/BackBTN';
import { ReactComponent as BlackCalender } from '../../Assets/Icons/BlackCalender.svg';
import { ReactComponent as BlackLocation } from '../../Assets/Icons/BlackLocation.svg';
import CustomButton from '../../Components/CustomButton';
import { ReactComponent as GreenRight } from '../../Assets/Icons/GreenRight.svg';
import { ReactComponent as BlackAvatar } from '../../Assets/Icons/Class Category/BlackAvatar.svg';

import { mainUrl } from '../../Redux/BaseUrls';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  SaveInvoiceIdAction,
  SaveOrderStatusAction,
  ToggleShowHideAction,
} from '../../Redux/Actions';

export default function Content(props) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const state = location.state;

  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const persistedState = useSelector((state) => state.PersistedReducer);

  const [PaymentMethod, SetPaymentMethod] = useState('');
  const [ProceedToPaymentRes, SetProceedToPaymentRes] = useState({});
  const [SendReqLoading, SetSendReqLoading] = useState(false);

  // on click continue
  const HandelProceedTopayment = () => {
    if (persistedState.GuestUser) {
      let restOfData = ValidateReqData();
      console.log(restOfData);
      if (restOfData !== undefined) {
        ProceedPaymentReq({
          guest_name: persistedState.UserInfo.guest_name,
          guest_phone: persistedState.UserInfo.guest_phone,
          guest_gender: persistedState.UserInfo.guest_gender,
          payment_method: PaymentMethod,
          ...restOfData,
        });
      }
    } else if (persistedState.Loggedin) {
      let restOfData = ValidateReqData();
      // console.log(restOfData)
      if (restOfData !== undefined) {
        ProceedPaymentReq({
          user_id: persistedState.UserInfo.user.id,
          ...restOfData,
        });
      }
    }
  };

  // send req to payment
  const ProceedPaymentReq = (data) => {
    console.log('req data', data);

    SetSendReqLoading(true);
    mainUrl
      .post(
        `venue/proceed-to-payment`,
        { ...data },
        {
          headers: {
            country: UserSelectedData.Country.id,
            lang: i18n.language,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Content-Type',
            'Accept-Language': i18n.language,
          },
        }
      )
      .then((response) => response.data)
      .then((data) => {
        SetProceedToPaymentRes(data);
      })
      .catch((e) => console.log('the e', e));
  };

  // check if data exist before req and if payment method is selected
  const ValidateReqData = () => {
    let restOfData = {};
    if (PaymentMethod !== '') {
      restOfData.payment_method = PaymentMethod;
      restOfData.total = props.orderDetails.data.total;
      restOfData.venue_id = UserSelectedData.VenueData.VenueId;
      restOfData.time = UserSelectedData.VenueData.Time;

      let dt = new Date(UserSelectedData.SelectedDate);
      let date = [
        dt.getFullYear(),
        ('0' + (dt.getMonth() + 1)).slice(-2),
        ('0' + dt.getDate()).slice(-2),
      ].join('-');

      restOfData.date = date;
      restOfData.channel = 'web';

      return restOfData;
    } else {
      dispatch(
        ToggleShowHideAction({
          Open: true,
          Type: 'info',
          Msg: t('Please select a payment method to continue'),
        })
      );
    }
  };

  ////////////////////////////////////////////////////////

  useEffect(() => {
    console.log(ProceedToPaymentRes);
    if (ProceedToPaymentRes.success === true) {
      if (PaymentMethod === 'cash') {
        // save data in orer status
        dispatch(SaveOrderStatusAction(ProceedToPaymentRes));
        navigate('/appointment/payment/success', { state: { cash: true } });
      } else {
        // save invoice id in redux
        dispatch(SaveInvoiceIdAction(ProceedToPaymentRes.data.invoice_id));
        // empty state before sending req in order status
        dispatch(SaveOrderStatusAction({}));
        window.open(ProceedToPaymentRes.data.url, '_self');
      }
    } else if (ProceedToPaymentRes.success === false) {
      dispatch(
        ToggleShowHideAction({
          Open: true,
          Type: 'error',
          Msg: ProceedToPaymentRes.message,
        })
      );
    }
  }, [ProceedToPaymentRes]);

  // useEffect(()=>{
  //     console.log(PaymentMethod)
  // },[PaymentMethod])

  return (
    <div>
      <BackBTN
        text={
          <div className="flex justify-center mb-5">
            <p className="w-1/2">{t('Review booking info')}</p>
          </div>
        }
      />

      {/* blue section */}
      <div className="rounded-md bg-primary_BG px-3 py-1 text-white mb-5">
        <div className="flex justify-between items-center mb-2">
          <p className="text-xl text-start">{props.Details.data.venue_name}</p>
          <button
            onClick={() => navigate('/Venues')}
            className="text-white text-xs bg-TransparentWhite rounded-full px-2 py-px"
          >
            {t('Change')}
          </button>
        </div>

        <div className="flex flex-col justify-start text-xs mb-5">
          <div className="flex items-center pb-1">
            <BlackAvatar width="9.864" height="11.332" />
            <p className="px-1">{props.Details.data.vendor_name}</p>
          </div>

          <div className="flex items-center pb-1">
            <BlackLocation width="9.73" height="15.425" />
            <p className="px-1">{props.Details.data.area}</p>
          </div>

          <div className="flex items-center">
            <BlackCalender width="12.613" height="14.801" />
            <p className="px-1">
              {new Date(UserSelectedData.SelectedDate).toDateString()} ,{' '}
              {state && state.time ? state.time : ''}
            </p>
          </div>
        </div>

        {/* price and space  */}
        <div className="flex justify-between items-center">
          <button className="text-white text-xs bg-TransparentWhite rounded-full px-2 py-px">
            {props.Details.data.space}
          </button>
          <p>
            {props.Details.data.price} {props.Details.data.currency}
          </p>
        </div>
      </div>

      {/* payment summary */}
      <div className="mb-5 text-sm">
        <p className="text-primary_BG w-fit mb-1">{t('Payment summary')}</p>

        <div className="bg-gray px-5 py-2 rounded-md">
          <div class="flex justify-between mb-1">
            <p>{t('Subtotal')}</p>
            <p>
              {props.orderDetails.data.sub_total} {props.Details.data.currency}
            </p>
          </div>

          {UserSelectedData.Country?.tax_status === 1 ? (
            <div class="flex justify-between mb-1">
              <p>{t('Tax')}</p>
              <p>
                {UserSelectedData.Country?.tax}{' '}
                {UserSelectedData.Country?.currency}
              </p>
            </div>
          ) : (
            <></>
          )}

          <div class="flex justify-between mb-1 text-primary_BG">
            <p>{t('Total')}</p>
            <p>
              {props.orderDetails.data.total} {props.Details.data.currency}
            </p>
          </div>
        </div>
      </div>

      {/* select payment method */}
      <div className="mb-5">
        <p className="mb-4">{t('Select your payment method')}</p>

        <div className="flex justify-between  gap-x-2">
          {props.orderDetails.data.payment_methods.map((itm) => {
            return (
              <div
                onClick={() => SetPaymentMethod(itm.name)}
                key={itm.id}
                className={
                  PaymentMethod === itm.name
                    ? 'flex flex-col items-center justify-center rounded-md p-5 relative bg-gray relative cursor-pointer'
                    : 'flex flex-col items-center justify-center rounded-md p-5 relative cursor-pointer'
                }
              >
                {PaymentMethod === itm.name && (
                  <div className="absolute top-2 ltr:right-2">
                    <GreenRight width="15.763" height="10.44" />
                  </div>
                )}
                <img className="w-1/2" alt={itm.name} src={itm.image} />
                <p className="text-sm">{itm.name}</p>
              </div>
            );
          })}
        </div>
      </div>

      {/* continue */}
      {SendReqLoading ? (
        <CustomButton
          ContainerClass="flex justify-center bg-InputGray text-white rounded-lg text-sm cursor-pointer py-2 mt-7"
          BtnValue={t('Continue')}
          ButtonClass="flex items-center gap-x-2"
          loading="true"
        />
      ) : (
        <CustomButton
          onclick={() => HandelProceedTopayment()}
          ContainerClass="bg-primary_BG text-white rounded-lg text-sm cursor-pointer py-2 mt-7"
          BtnValue={t('Continue')}
        />
      )}
    </div>
  );
}
