import React from 'react';
import Content from './Content';
import BackBTN from '../../Components/BackBTN';
import { ReactComponent as OrderHistoryicon } from '../../Assets/Icons/OrderHistory.svg';
import MainLayout from '../../Components/MainLayout';
import { useTranslation } from 'react-i18next';

export default function OrderHistory() {
  const { t } = useTranslation();
  return (
    <MainLayout active={`history`}>
      <div className="lg:w-1/3 mt-5 grow">
        <BackBTN
          text={
            <div className="flex items-cener justify-center">
              <OrderHistoryicon width="20.111" height="24.354" />
              <p className="px-1">{t('Order History')}</p>
            </div>
          }
        />

        <Content />
      </div>
    </MainLayout>
  );
}
