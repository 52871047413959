import React from 'react';
import MainLayout from '../Components/MainLayout';
import { useTranslation } from 'react-i18next';

export default function NotFound() {
  const { t } = useTranslation();
  return (
    <MainLayout>
      <div className={`h-screen flex-1 items-center justify-center`}>
        <h1 className={`text-2xl mt-8`}>{t(`page_not_found`)}</h1>
        <img
          src={`https://m.media-amazon.com/images/I/61FQCSP7ZIL._SS500_.jpg`}
          className={`w-auto h-auto object-contain`}
        />
      </div>
    </MainLayout>
  );
}
