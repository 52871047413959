import React from 'react';
import ReactLoading from 'react-loading';

export default function CustomButton({
  ButtonClass = '',
  ContainerClass = '',
  BtnValue = '',
  onclick,
  ContainerStyle = {},
  loading = false,
}) {
  return (
    <div style={ContainerStyle} className={ContainerClass} onClick={onclick}>
      <button className={ButtonClass}>
        {loading && (
          <ReactLoading
            type="spinningBubbles"
            color="#fff"
            height={20}
            width={20}
          />
        )}
        {BtnValue}
      </button>
    </div>
  );
}
