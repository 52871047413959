import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BackBTN from '../../Components/BackBTN';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import ar from 'date-fns/locale/ar';
import enGB from 'date-fns/locale/en-GB';
// import Grid from '@mui/material/Grid';

import { ReactComponent as WhiteRight } from '../../Assets/Icons/WhiteRight.svg';
import { useDispatch, useSelector } from 'react-redux';
import { SaveUserDataAction } from '../../Redux/Actions';
import { useNavigate } from 'react-router-dom';

import './galenderStyle.css';

export default function Content() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );

  const minDate = new Date();
  const maxDate = new Date('2034-01-01T00:00:00.000');
  const [date, setDate] = useState(new Date());

  const SaveDate = () => {
    // console.log(date)
    dispatch(SaveUserDataAction({ ...UserSelectedData, SelectedDate: date }));
    navigate(-1);
  };

  return (
    <div>
      <BackBTN
        text={
          <div className="py-3 mb-3">
            <p>{t('Select a date')}</p>
          </div>
        }
      />

      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        locale={i18n.language === 'ar' ? ar : enGB}
      >
        {/* <Grid container spacing={5}> */}
        {/* <Grid item xs={12} md={12}> */}
        <CalendarPicker
          className={i18n.language === 'ar' ? 'rtl' : 'ltr'}
          minDate={minDate}
          maxDate={maxDate}
          date={date}
          onChange={(newDate) => setDate(newDate)}
        />
        {/* </Grid> */}
        {/* </Grid> */}
      </LocalizationProvider>

      {/* btn set date */}

      <div
        onClick={() => {
          SaveDate();
        }}
        className="bg-primary_BG rounded-xl px-5 py-4 text-white flex justify-between cursor-pointer"
      >
        <p>{t('Set your Date')}</p>
        <div className="">
          <WhiteRight />
        </div>
      </div>
    </div>
  );
}
