import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import GreyLine from '../../Components/GreyLine';
import { ReactComponent as Loc } from '../../Assets/Icons/BlackLocation.svg';
import { ReactComponent as Avatar } from '../../Assets/Icons/Class Category/BlackAvatar.svg';
import { ReactComponent as Store } from '../../Assets/Icons/Store.svg';
import { ReactComponent as Receipt } from '../../Assets/Icons/Class Category/Receipt.svg';

function StoreOrderSuccess(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );

  useEffect(() => {
    console.log(props.data);
  }, []);

  return (
    <div>
      <div className="flex justify-center">
        <img src={require('../../Assets/Images/Store/Success.png')} />
      </div>

      <div className="my-2">
        <p className="text-lg">{t('Success!')}</p>
        <p className="">{t('Your purchase is successfully completed')}</p>
      </div>

      <div className="rounded-lg shadow-md p-5">
        <p className="text-start text-primary_BG">payment reciept</p>
        <GreyLine className="my-2" />

        <div className="flex justify-between items-center">
          <p>Order ID</p>
          <p>{props.data.data.order_code}</p>
        </div>

        <GreyLine className="my-2" />

        <div className="text-sm">
          <div className="flex items-center gap-x-2">
            <Loc width="10.73" height="17.425" />
            <p>{props.data.data.area}</p>
          </div>

          <div className="flex items-center gap-x-2">
            <Avatar width="10.73" height="17.425" />
            <p>{props.data.data.vendor_name}</p>
          </div>
        </div>

        <GreyLine className="my-2" />

        <div className="flex justify-between items-center">
          <p className="text-start text-primary_BG">Total Amount</p>
          <p>{props.data.data.total}</p>
        </div>
      </div>

      <div class="grid grid-cols-2 gap-x-3 my-10">
        <div
          onClick={() => {
            navigate('/AllProducts/null');
          }}
          class="cursor-pointer shadow-custome rounded-2xl grid grid-rows-2 pt-3 lg:py-5 lg:px-3 lg:grid-cols-3 lg:grid-rows-none lg:items-center"
        >
          <div class="flex justify-center items-center">
            <Store width="31.05" height="31.081" />
          </div>
          <p class="text-xs text-center pt-3 lg:pt-0 col-span-2">
            {t('Shop more items')}
          </p>
        </div>

        <div
          onClick={() => {
            navigate('/Receipt', { state: { Data: props.data } });
          }}
          class="cursor-pointer shadow-custome rounded-2xl grid grid-rows-2 pt-3 lg:py-3 lg:px-3 lg:grid-cols-3 lg:grid-rows-none lg:items-center"
          id="SecondCard"
        >
          <div class="flex justify-center items-center">
            <Receipt width="27.249" height="30.894" />
          </div>
          <p class="text-xs text-center pt-3 lg:pt-0 col-span-2">
            {t('View receipt')}
          </p>
        </div>
      </div>
    </div>
  );
}

export default StoreOrderSuccess;
