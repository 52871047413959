import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GetAllAreas } from '../../API/APIRequests';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import LoadingSpinner from '../../Components/LoadingSpinner';
import Content from './Content';
import MainLayout from '../../Components/MainLayout';

export default function SelectArea() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const [AllAreas, SetAllAreas] = useState({});

  // get all areas
  useEffect(() => {
    if (UserSelectedData.Country) {
      GetAllAreas(
        {
          countryID: UserSelectedData.Country.id,
          data: {
            type:
              UserSelectedData.Category === 'Sports'
                ? 'venue'
                : UserSelectedData.Category === 'Subscriptions'
                ? 'subscription'
                : UserSelectedData.Category === 'Classes'
                ? 'class'
                : UserSelectedData.Category === 'Store' && 'store',
          },
        },
        (res) => {
          SetAllAreas(res);
        }
      );
    } else {
      navigate('/home');
    }
  }, [i18n.language, UserSelectedData.Country]);

  return (
    <MainLayout>
      {AllAreas.success ? (
        <div className="w-11/12 lg:w-1/3 sm:w-1/2 mt-5 grow">
          <Content AllAreas={AllAreas} />
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </MainLayout>
  );
}
