import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import BackBTN from '../../../Components/BackBTN';
import Card from '../../Home/Card';
import { ReactComponent as Classs } from '../../../Assets/Icons/Class Category/Classs.svg';
// import {ReactComponent as SelectArea} from '../../../Assets/Icons/Class Category/SelectArea.svg'
import { ReactComponent as Calender } from '../../../Assets/Icons/Calender.svg';
import { ReactComponent as Areas } from '../../../Assets/Icons/Areas.svg';
import { ReactComponent as SelectedArea } from '../../../Assets/Icons/WhiteSelectedArea.svg';
import { ReactComponent as WhiteCalender } from '../../../Assets/Icons/WhiteCalender.svg';
import { ReactComponent as WhiteRight } from '../../../Assets/Icons/WhiteRight.svg';
import SelectedCard from '../../Venues/SportCategory/SelectedCard';
import SearchBar from '../../../Components/SearchBar';

import { GetAllClasses } from '../../../API/APIRequests';
import {
  SaveUserDataAction,
  ToggleShowHideAction,
} from '../../../Redux/Actions';

function Content(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );

  const GenderClass =
    'text-primary_BG rounded-2xl shadow-custome cursor-pointer w-full py-8';
  const SelectedGenderClass =
    'relative bg-primary_BG text-white rounded-2xl shadow-custome cursor-pointer w-full py-8';
  const [Gender, SetGender] = useState(
    UserSelectedData.SelectedGender ? UserSelectedData.SelectedGender : []
  );

  // when gender is clicked
  const HandelGenderSelection = (val) => {
    if (Gender.includes(val)) {
      let newArr = Gender.filter((item) => item !== val);
      SetGender(newArr);
    } else {
      SetGender((prev) => [...prev, val]);
    }
  };

  // get all available classes
  const OnSearchClicked = () => {
    if (UserSelectedData.SelectedClass === undefined) {
      dispatch(
        ToggleShowHideAction({
          Open: true,
          Type: 'info',
          Msg: t('Please select a class to continue'),
        })
      );
    }

    if (Gender.length === 0) {
      dispatch(
        ToggleShowHideAction({
          Open: true,
          Type: 'info',
          Msg: t('Please select a gender to continue'),
        })
      );
    } else {
      dispatch(
        SaveUserDataAction({ ...UserSelectedData, SelectedGender: Gender })
      );
      navigate('/AllClassses');
    }
  };

  useEffect(() => {
    // console.log(Gender)
  }, [Gender]);

  return (
    <div>
      <BackBTN
        text={
          <div className="flex justify-center w-full">
            <p className="w-3/5">
              {t('Select your class type and find a session')}
            </p>
          </div>
        }
      />

      <div>
        {/* select class type*/}

        <div className="mb-4">
          <div className="flex justify-start mb-1">
            <p>{t('Please select')}</p>
          </div>
          {UserSelectedData.SelectedClass ? (
            <div
              className="rounded-xl grid grid-cols-2 gap-x-2 h-28 py-2 px-4"
              style={{
                backgroundImage: `linear-gradient(80deg,#${UserSelectedData.SelectedClass.color}55,#${UserSelectedData.SelectedClass.color})`,
              }}
            >
              {/* left side selected sport and change btn */}
              <div className="flex flex-col justify-between items-start">
                <div className="flex flex-col items-start text-sm">
                  <p>{UserSelectedData.SelectedClass.name}</p>
                  <p>{t('Sport selected')}</p>
                </div>

                <div className="" onClick={() => navigate('/ClassTypes')}>
                  <button className="text-white text-xs bg-TransparentWhite rounded-full px-2 py-px">
                    {t('Change')}
                  </button>
                </div>
              </div>

              {/* image */}
              <div className="flex justify-end h-24">
                <img
                  className="h-full"
                  src={UserSelectedData.SelectedClass.image}
                />
              </div>
            </div>
          ) : (
            <Card
              cardClassName="col-span-2 py-8"
              onClick={() => navigate('/ClassTypes')}
              text={t('Classes types')}
              icon={
                <Classs width="73.722" height="60.589" className="w-full" />
              }
            />
          )}
        </div>

        {/* select gender */}
        <div className="mb-4">
          <div className="flex justify-start mb-1">
            <p>{t('Please select')}</p>
          </div>

          <div className="grid grid-cols-3 gap-x-3">
            <div
              onClick={() => {
                HandelGenderSelection('female');
              }}
              className={
                Gender.includes('female') ? SelectedGenderClass : GenderClass
              }
            >
              {Gender.includes('female') && (
                <WhiteRight
                  width="16.898"
                  height="10.804"
                  className="absolute top-3 right-3"
                />
              )}
              {t('Female')}
            </div>

            <div
              onClick={() => {
                HandelGenderSelection('male');
              }}
              className={
                Gender.includes('male') ? SelectedGenderClass : GenderClass
              }
            >
              {Gender.includes('male') && (
                <WhiteRight
                  width="16.898"
                  height="10.804"
                  className="absolute top-3 right-3"
                />
              )}
              {t('Male')}
            </div>

            <div
              onClick={() => {
                HandelGenderSelection('kids');
              }}
              className={
                Gender.includes('kids') ? SelectedGenderClass : GenderClass
              }
            >
              {Gender.includes('kids') && (
                <WhiteRight
                  width="16.898"
                  height="10.804"
                  className="absolute top-3 right-3"
                />
              )}
              {t('Kids')}
            </div>
          </div>
        </div>

        {/* select area*/}
        {/* <div  onClick={()=>navigate('/SelectArea')} class="shadow-custome rounded-xl px-5 py-2 flex justify-between items-center cursor-pointer mb-5">
                    <p >{t("Select Area")}</p>
                    <div class="">
                        <SelectArea width="34.198" height="22.383"/>
                    </div>
                </div> */}

        {/* select date and area */}
        <div className="grid grid-cols-2 gap-x-5 gap-y-6 py-5">
          {UserSelectedData.SelectedDate ? (
            <SelectedCard
              cardClassName="h-32"
              ChangeBtnOnClick={() => navigate('/SelectDate')}
              FirstRow={
                <div className="flex flex-col items-start text-white text-sm">
                  <p>
                    {new Date(UserSelectedData.SelectedDate).toDateString()}
                  </p>
                  <p>{t('Date selected')}</p>
                </div>
              }
              icon={<WhiteCalender width="40" height="40" />}
            />
          ) : (
            <Card
              cardClassName={'py-8'}
              onClick={() => {
                navigate('/SelectDate');
              }}
              text={t('Select Date')}
              icon={
                <Calender width="53.722" height="40.589" className="w-full" />
              }
            />
          )}

          {/* select area card */}
          {UserSelectedData.SelectedArea ? (
            <SelectedCard
              cardClassName="h-32"
              ChangeBtnOnClick={() => navigate('/SelectArea')}
              showRemoveArea="true"
              FirstRow={
                <div className="flex flex-col items-start text-white text-sm">
                  <p>{UserSelectedData.SelectedArea.name}</p>
                  <p>{t('Area selected')}</p>
                </div>
              }
              icon={<SelectedArea width="40" height="40" />}
            />
          ) : (
            <Card
              cardClassName={'py-8'}
              onClick={() => {
                navigate('/SelectArea');
              }}
              text={t('Select Areas')}
              icon={<Areas width="53.722" height="40.589" className="w-full" />}
            />
          )}
        </div>

        {/* search */}
        {UserSelectedData.SelectedClass && UserSelectedData.SelectedDate ? (
          <SearchBar
            onClick={() => {
              OnSearchClicked();
            }}
            textClass="text-white"
            ContainerClass="bg-DarkBlue cursor-pointer"
          />
        ) : (
          <SearchBar textClass="text-InputGray" />
        )}
      </div>
    </div>
  );
}

export default Content;
