import React from 'react';
import { useTranslation } from 'react-i18next';
import GreyLine from '../../Components/GreyLine';
import { ReactComponent as BlackCalender } from '../../Assets/Icons/BlackCalender.svg';
import { ReactComponent as BlackClock } from '../../Assets/Icons/BlackClock.svg';
import { ReactComponent as BlackAvatar } from '../../Assets/Icons/Class Category/BlackAvatar.svg';
import { ReactComponent as BlackLocation } from '../../Assets/Icons/BlackLocation.svg';
import { ReactComponent as Class } from '../../Assets/Icons/Classes.svg';
import { ReactComponent as Receipt } from '../../Assets/Icons/Class Category/Receipt.svg';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function ClassOrderSuccess(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );

  return (
    <div>
      <div className="flex justify-center">
        <img src={require('../../Assets/Images/ClassCategory/Success.png')} />
      </div>

      <div className="my-2">
        <p className="text-lg">{t('Congratulations !')}</p>
        <p className="">{t('Your class is booked successfully ')}</p>
      </div>

      <div className="flex flex-col items-start  mt-5 bg-LighterGray rounded-md p-2">
        <p className="text-primary_BG text-sm">{t('Payment Reciept')}</p>
        <GreyLine className="mb-5" />

        <div className="flex mb-3 text-xs">
          <BlackCalender width="15.613" height="17.801" className="mx-1" />
          <p>{props.data.data.date}</p>
        </div>

        <div className="flex mb-3 text-xs">
          <BlackClock width="14.967" height="14.967" className="mx-1" />
          <p>{props.data.data.time}</p>
        </div>

        <div className="flex mb-3 text-xs">
          <BlackAvatar width="11.864" height="13.332" className="mx-1" />
          <p>{props.data.data.date}</p>
        </div>

        <GreyLine className="my-2" />

        <div className="flex justify-between items-end w-full">
          <div>
            <p className="text-xl mb-2">{props.data.data.class_name}</p>
            <div className="flex pb-1.5">
              <BlackLocation width="10.73" height="16.425" />
              <p className="px-1 text-xs">{props.data.data.area}</p>
            </div>
          </div>
          <p>
            {props.data.data.price} {props.data.data.currency}
          </p>
        </div>
      </div>

      {/* buttons */}

      <div class="grid grid-cols-2 gap-x-3 my-10">
        <div
          onClick={() => {
            navigate('/AllClassses', {
              state: UserSelectedData.SelectedClass.id,
            });
          }}
          class="cursor-pointer shadow-custome rounded-2xl grid grid-rows-2 pt-3 lg:py-5 lg:px-3 lg:grid-cols-3 lg:grid-rows-none lg:items-center"
        >
          <div class="flex justify-center items-center">
            <Class width="31.05" height="31.081" />
          </div>
          <p class="text-xs text-center pt-3 lg:pt-0 col-span-2">
            {t('Explore more Classes')}
          </p>
        </div>

        <div
          onClick={() => {
            navigate('/Receipt', { state: { Data: props.data } });
          }}
          class="cursor-pointer shadow-custome rounded-2xl grid grid-rows-2 pt-3 lg:py-3 lg:px-3 lg:grid-cols-3 lg:grid-rows-none lg:items-center"
          id="SecondCard"
        >
          <div class="flex justify-center items-center">
            <Receipt width="27.249" height="30.894" />
          </div>
          <p class="text-xs text-center pt-3 lg:pt-0 col-span-2">
            {t('View receipt')}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ClassOrderSuccess;
