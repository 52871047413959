import React from 'react';
import Skeleton from '@mui/material/Skeleton';

function ItemsPlaceHolder(props) {
  return (
    <div>
      {Array.apply(null, { length: 10 }).map((itm, i) => {
        return (
          <>
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'30%'} />
            <div className="grid grid-cols-3 gap-x-3 mb-3">
              <Skeleton variant="rectangular" width={'100%'} height={'7rem'} />
              <Skeleton variant="rectangular" width={'100%'} height={'7rem'} />
              <Skeleton variant="rectangular" width={'100%'} height={'7rem'} />
            </div>
          </>
        );
      })}
    </div>
  );
}

export default ItemsPlaceHolder;
