import React from 'react';
import BackBTN from '../../../Components/BackBTN';
import Carousel from 'react-material-ui-carousel';
import { useSelector } from 'react-redux';
import GreyLine from '../../../Components/GreyLine';
import { ReactComponent as BlueRight } from '../../../Assets/Icons/SubscriptionCategory/BlueRight.svg';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Location } from '../../../Assets/Icons/Location.svg';
import { ReactComponent as LocationMarker } from '../../../Assets/Icons/LocationMarker.svg';
import GoogleMapReact from 'google-map-react';
import SectionTitle from '../../Venues/Venue Details/SectionTitle';
import GeneralCarousel from '../../../Components/GeneralCarousel';

function Content(props) {
  const { t } = useTranslation();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  return (
    <div>
      <BackBTN />
      <div className="my-3">
        <GeneralCarousel
          slidesArray={UserSelectedData.SelectedSubscription.images}
        />
      </div>

      {/* name and price */}
      <div className="flex justify-between text-primary_BG font-semibold">
        <p>{UserSelectedData.SelectedSubscription.name}</p>
        <p>{UserSelectedData.SelectedSubscription.price}</p>
      </div>

      <GreyLine className="my-2" />

      {/* aminities */}
      <div>
        {UserSelectedData.SelectedSubscription.amenities.map((itm) => {
          return (
            <div key={itm.id} className="flex items-center mb-1">
              <BlueRight width="12.066" height="8.289" />
              <p className="text-sm px-2">{itm.name}</p>
            </div>
          );
        })}
      </div>

      <GreyLine className="my-2" />

      {/* description */}
      <div className="flex flex-col items-start mb-7">
        <p className="text-primary_BG w-fit">{t('About this subscription')}</p>
        <p className="text-xs w-fit text-justify">
          {UserSelectedData.SelectedSubscription.description}
        </p>
      </div>

      {/* location */}
      {/* location */}
      <SectionTitle
        icon={<Location width="9.857" height="14.899" />}
        title={t('Location')}
      />

      <div className="h-28 mb-3 rounded-xl">
        <GoogleMapReact
          className="rounded-xl"
          bootstrapURLKeys={{
            // remove the key if you want to fork
            key: 'AIzaSyChibV0_W_OlSRJg2GjL8TWVU8CzpRHRAE',
            language: 'en',
            region: 'US',
          }}
          defaultCenter={{
            lat: UserSelectedData.SelectedSubscription.latitude
              ? parseInt(UserSelectedData.SelectedSubscription.latitude)
              : 10.99835602,
            lng: UserSelectedData.SelectedSubscription.longitude
              ? parseInt(UserSelectedData.SelectedSubscription.longitude)
              : 77.01502627,
          }}
          defaultZoom={11}
        >
          <LocationMarker
            width="20.601"
            height="31.664"
            lat={
              UserSelectedData.SelectedSubscription.latitude
                ? UserSelectedData.SelectedSubscription.latitude
                : 10.99835602
            }
            lng={
              UserSelectedData.SelectedSubscription.longitude
                ? UserSelectedData.SelectedSubscription.longitude
                : 77.01502627
            }
          />
        </GoogleMapReact>
      </div>
    </div>
  );
}

export default Content;
