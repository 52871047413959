import React from 'react';
import Minus from '../Assets/Icons/Store/Minus';
import Plus from '../Assets/Icons/Store/Plus';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

function Counter({
  id,
  val,
  inc,
  dec,
  iconClr = '',
  iconSize = 18,
  inptclassName = 'bg-primary_BG outline-none text-center text-white py-1 mx-2 w-10 h-6',
  containerClassName = '',
}) {
  return (
    <div className={containerClassName + ' flex justif-center items-center'}>
      <div className="cursor-pointer" onClick={dec}>
        <RemoveIcon sx={{ color: iconClr, fontSize: iconSize }} />
      </div>

      <input
        id={id}
        value={val}
        type="text"
        readOnly
        className={' rounded-full outline-none text-sm ' + inptclassName}
      ></input>

      <div className="cursor-pointer" onClick={inc}>
        <AddIcon sx={{ color: iconClr, fontSize: iconSize }} />
      </div>
    </div>
  );
}

export default Counter;
