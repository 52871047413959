import React from 'react';
import Skeleton from '@mui/material/Skeleton';

function PlaceHolderSkelton(props) {
  return (
    <div>
      {Array.apply(null, { length: 5 }).map((e, i) => (
        <Skeleton
          key={i}
          variant="rectangular"
          width={'100%'}
          height={'7rem'}
          className="mb-3 rounded-md"
        />
      ))}
    </div>
  );
}

export default PlaceHolderSkelton;
