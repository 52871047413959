import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Card from '../../Home/Card';
import { ReactComponent as Subscription } from '../../../Assets/Icons/Subscription.svg';
import { ReactComponent as WhiteRight } from '../../../Assets/Icons/WhiteRight.svg';
import SearchBar from '../../../Components/SearchBar';
import { ReactComponent as SelectArea } from '../../../Assets/Icons/Class Category/SelectArea.svg';
import BackBTN from '../../../Components/BackBTN';
import {
  SaveUserDataAction,
  ToggleShowHideAction,
} from '../../../Redux/Actions';
import { ReactComponent as WhiteSelectedArea } from '../../../Assets/Icons/WhiteSelectedArea.svg';
import { ReactComponent as Areas } from '../../../Assets/Icons/Areas.svg';
import { ReactComponent as SelectedArea } from '../../../Assets/Icons/WhiteSelectedArea.svg';
import SelectedCard from '../../Venues/SportCategory/SelectedCard';

function Content(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const GenderClass =
    'text-primary_BG rounded-2xl shadow-custome cursor-pointer w-full py-8';
  const SelectedGenderClass =
    'relative bg-primary_BG text-white rounded-2xl shadow-custome cursor-pointer w-full py-8';
  const [Gender, SetGender] = useState(
    UserSelectedData.SelectedGender ? UserSelectedData.SelectedGender : []
  );

  // when gender is clicked
  const HandelGenderSelection = (val) => {
    if (Gender.includes(val)) {
      let newArr = Gender.filter((item) => item !== val);
      SetGender(newArr);
    } else {
      SetGender((prev) => [...prev, val]);
    }
  };

  // get all available classes
  const OnSearchClicked = () => {
    if (UserSelectedData.SelectedSubscriptionType === undefined) {
      dispatch(
        ToggleShowHideAction({
          Open: true,
          Type: 'info',
          Msg: t('Please select a class to continue'),
        })
      );
    }

    if (
      Gender.length === 0 &&
      UserSelectedData.SelectedSubscriptionType.gender_filter === 1
    ) {
      dispatch(
        ToggleShowHideAction({
          Open: true,
          Type: 'info',
          Msg: t('Please select a gender to continue'),
        })
      );
    } else {
      dispatch(
        SaveUserDataAction({ ...UserSelectedData, SelectedGender: Gender })
      );
      navigate('/AllSubscriptions');
    }
  };

  return (
    <div>
      <BackBTN />

      {/* <div className='flex justify-center w-full mb-5 mt-1'>
                <p className='w-3/5'>{t("Gym type")}</p>
            </div> */}

      {/* select subscription type */}
      <div className="mb-4">
        <div className="flex justify-start mb-1 mt-2">
          <p>{t('Please select')}</p>
        </div>

        {UserSelectedData.SelectedSubscriptionType ? (
          // style={{backgroundImage: `linear-gradient(80deg,#${UserSelectedData.SelectedClass.color}55,#${UserSelectedData.SelectedSport.color})`}}
          <div
            className="rounded-xl grid grid-cols-2 gap-x-2 h-28 py-2 px-4"
            style={{
              backgroundColor: `#${UserSelectedData.SelectedSubscriptionType.color}`,
            }}
          >
            {/* left side selected sport and change btn */}
            <div className="flex flex-col justify-between items-start">
              <div className="flex flex-col items-start text-sm">
                <p>{UserSelectedData.SelectedSubscriptionType.name}</p>
                <p>{t('Subscription Type selected')}</p>
              </div>

              <div className="" onClick={() => navigate('/SubscriptionTypes')}>
                <button className="text-white text-xs bg-TransparentWhite rounded-full px-2 py-px">
                  {t('Change')}
                </button>
              </div>
            </div>

            {/* image */}
            <div className="flex justify-end h-24">
              <img
                className="h-full"
                src={UserSelectedData.SelectedSubscriptionType.image}
              />
            </div>
          </div>
        ) : (
          <Card
            cardClassName="col-span-2 py-8"
            onClick={() => navigate('/SubscriptionTypes')}
            text={t('Select Category')}
            icon={
              <Subscription width="85.221" height="56.233" className="w-full" />
            }
          />
        )}
      </div>

      {/* select gender */}
      <div
        className={
          UserSelectedData.SelectedSubscriptionType
            ? UserSelectedData.SelectedSubscriptionType.gender_filter === 1
              ? 'mb-4'
              : 'hidden'
            : 'mb-4'
        }
      >
        <div className="flex justify-start mb-1">
          <p>{t('Please select')}</p>
        </div>

        <div className="grid grid-cols-3 gap-x-3">
          <div
            onClick={() => {
              HandelGenderSelection('female');
            }}
            className={
              Gender.includes('female') ? SelectedGenderClass : GenderClass
            }
          >
            {Gender.includes('female') && (
              <WhiteRight
                width="16.898"
                height="10.804"
                className="absolute top-3 right-3"
              />
            )}
            {t('Female')}
          </div>

          <div
            onClick={() => {
              HandelGenderSelection('male');
            }}
            className={
              Gender.includes('male') ? SelectedGenderClass : GenderClass
            }
          >
            {Gender.includes('male') && (
              <WhiteRight
                width="16.898"
                height="10.804"
                className="absolute top-3 right-3"
              />
            )}
            {t('Male')}
          </div>

          <div
            onClick={() => {
              HandelGenderSelection('kids');
            }}
            className={
              Gender.includes('kids') ? SelectedGenderClass : GenderClass
            }
          >
            {Gender.includes('kids') && (
              <WhiteRight
                width="16.898"
                height="10.804"
                className="absolute top-3 right-3"
              />
            )}
            {t('Kids')}
          </div>
        </div>
      </div>

      {/* select area*/}
      {/* {UserSelectedData.SelectedArea ? 
                <div  onClick={()=>navigate('/SelectArea')} class="shadow-custome rounded-xl px-5 py-2 flex justify-between items-center cursor-pointer mb-5 bg-primary_BG">
                    <div className='text-start text-white'>
                        <p>{UserSelectedData.SelectedArea.name}</p>
                        <p className='text-xs'>{t("Area Selected")}</p>
                    </div>
                    <div class="">
                        <WhiteSelectedArea width="34.198" height="22.383"/>
                    </div>
                </div>
                
            :
                <div  onClick={()=>navigate('/SelectArea')} class="shadow-custome rounded-xl px-5 py-2 flex justify-between items-center cursor-pointer mb-5">
                    <p >{t("Select Area")}</p>
                    <div class="">
                        <SelectArea width="34.198" height="22.383"/>
                    </div>
                </div>
            } */}

      {UserSelectedData.SelectedArea ? (
        <SelectedCard
          cardClassName="mb-5"
          ChangeBtnOnClick={() => navigate('/SelectArea')}
          showRemoveArea="true"
          FirstRow={
            <div className="flex flex-col items-start text-white text-xs">
              <p>{UserSelectedData.SelectedArea.name}</p>
              <p>{t('Area selected')}</p>
            </div>
          }
          icon={<SelectedArea width="40" height="40" />}
        />
      ) : (
        <Card
          cardClassName={'py-8 mb-5'}
          onClick={() => {
            navigate('/SelectArea');
          }}
          text={t('Select Areas')}
          icon={<Areas width="53.722" height="40.589" className="w-full" />}
        />
      )}

      {/* search */}
      {UserSelectedData.SelectedSubscriptionType ? (
        <SearchBar
          onClick={() => {
            OnSearchClicked();
          }}
          textClass="text-white"
          ContainerClass="bg-DarkBlue cursor-pointer"
        />
      ) : (
        <SearchBar textClass="text-InputGray" />
      )}
    </div>
  );
}

export default Content;
