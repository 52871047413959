import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as WhiteRight } from '../../Assets/Icons/WhiteRight.svg';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TextWithIconCard from '../../Components/TextWithIconCard';
import ReplayIcon from '@mui/icons-material/Replay';
import { useNavigate } from 'react-router-dom';

export default function OrderFailure(props) {
  const { t } = useTranslation();
  const navigate=useNavigate()

  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-11/12 md:w-full mx-3">
        <div className="rounded-3xl  shadow-CardTop">
          <div className="flex justify-center items-center gap-x-2 rounded-t-3xl bg-CustomRed w-full py-2 text-white">
            <WhiteRight width="20.898" height="14.804" />
            <p>{t('Booking failed')}</p>
          </div>

          {/* booking date time and address */}
          <div className="text-xs ltr:pl-5 rtl:pr-5 my-5">
            <div className="flex items-center gap-x-2 mb-2">
              <CalendarMonthIcon sx={{ fontSize: 15, color: '#141E3C' }} />
              <p className=" text-primary_BG text-start ">
                {props.data.data.date}
              </p>
            </div>
            <div className="flex items-center gap-x-2 mb-2">
              <QueryBuilderIcon sx={{ fontSize: 15, color: '#141E3C' }} />
              <p className=" text-primary_BG text-start ">
                {props.data.data.time}
              </p>
            </div>
            <div className="flex items-center gap-x-2 mb-2">
              <LocationOnIcon sx={{ fontSize: 16, color: '#141E3C' }} />
              <p className=" text-primary_BG text-start ">
                {props.data.data.area}
              </p>
            </div>
          </div>

          {/* payment details */}
          <div className="text-xs rounded-b-3xl px-2 py-4 mt-10 bg-gray">
            <div className="flex justify-between mb-2">
              <p>{t('Payment method : ')}</p>
              <p className="text-primary_BG">
                {props.data.data.payment_method}
              </p>
            </div>

            <div className="flex justify-between mb-2">
              <p>{t('Price :')}</p>
              <p className="text-primary_BG">
                {props.data.data.price} {props.data.data.currency}
              </p>
            </div>

            <div className="flex justify-between mb-2">
              <p>{t('Venue Name :')}</p>
              <p className="text-primary_BG">
                {props.data.data.venue_name}
              </p>
            </div>

            <div className="flex justify-between mb-2">
              <p>{t('Vendor Name :')}</p>
              <p className="text-primary_BG">
                {props.data.data.vendor_name}
              </p>
            </div>

            <div className="flex justify-between mb-2">
              <p>{t('Customer name : ')}</p>
              <p className="text-primary_BG">{props.data.data.customer_name}</p>
            </div>

            <div className="flex justify-between mb-2">
              <p>{t('Customer Phone : ')}</p>
              <p className="text-primary_BG">
                {props.data.data.customer_phone}
              </p>
            </div>
          </div>
        </div>

        <div className="my-7">
          <p className="font-semibold">{t("We're Sorry!")}</p>
          <p>{t('Your booking was not placed')}</p>
        </div>

        <div className="flex justify-center gap-x-5 items-center gap-x-2">
            <TextWithIconCard
              text="Re-try Booking"
              icon={<ReplayIcon sx={{ color: '#189EC9' }} />} 
              onclick={()=>navigate('/ReviewOrder')}         
            />
        </div>

      </div>
    </div>
  );
}
