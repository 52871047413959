import React from 'react';
import { useTranslation } from 'react-i18next';
import GreyLine from '../../Components/GreyLine';
import { ReactComponent as BlackCalender } from '../../Assets/Icons/BlackCalender.svg';
import { ReactComponent as BlackAvatar } from '../../Assets/Icons/Class Category/BlackAvatar.svg';
import { ReactComponent as BlackLocation } from '../../Assets/Icons/BlackLocation.svg';
import { ReactComponent as Receipt } from '../../Assets/Icons/Class Category/Receipt.svg';
import { useNavigate } from 'react-router-dom';

function SubscriptionOrderSuccess(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex justify-center h-32">
        <img
          src={require('../../Assets/Images/Subscription Category/Success.png')}
        />
      </div>

      <div className="my-2">
        <p className="text-lg">{t('Congratulations !')}</p>
        <p className="text-sm">
          {t('Your subscription is started and paid Successfully')}
        </p>
      </div>

      <div className="flex flex-col items-start  mt-5 bg-LighterGray rounded-md p-2">
        <p className="text-primary_BG text-sm">{t('Payment Reciept')}</p>
        <GreyLine className="mb-5" />

        <div className="flex pb-3 text-xs">
          <BlackLocation width="10.73" height="16.425" className="mx-1" />
          <p className="px-1 text-xs">{props.data.data.area}</p>
        </div>

        <div className="flex mb-3 text-xs">
          <BlackCalender width="15.613" height="17.801" className="mx-1" />
          <p>
            {' '}
            {t('Start date:')} {props.data.data.start_date}
          </p>
        </div>

        <div className="flex mb-3 text-xs">
          <BlackAvatar width="11.864" height="13.332" className="mx-1" />
          <p>{props.data.data.vendor_name}</p>
        </div>

        <GreyLine className="my-2" />

        <div className="flex justify-between items-end w-full">
          <div>
            <p className="text-xl mb-1">{props.data.data.subscription_name}</p>
            <div className="flex">
              <p className="px-1 text-xs">
                {props.data.data.subscription_duration}
              </p>
            </div>
          </div>
          <p>{props.data.data.price}</p>
        </div>
      </div>

      {/* buttons */}
      <div class="grid grid-cols-3 my-10">
        <div
          onClick={() => {
            navigate('/Receipt', { state: { Data: props.data } });
          }}
          class="cursor-pointer shadow-custome rounded-2xl grid grid-rows-2 pt-3 lg:py-3 lg:px-3 lg:grid-cols-3 lg:grid-rows-none lg:items-center col-start-2"
          id="SecondCard"
        >
          <div class="flex justify-center items-center">
            <Receipt width="27.249" height="30.894" />
          </div>
          <p class="text-xs text-center pt-3 lg:pt-0 col-span-2">
            {t('View receipt')}
          </p>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionOrderSuccess;
