import React, { useEffect } from 'react';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import Tooltip from '@mui/material/Tooltip';
import { WhatsappShareButton } from 'react-share';

function ShareCopyLocation({ lat = '', long = '', ShareMsg = '' }) {
  const ViewLoc = () => {
    window.open('https://maps.google.com?q=' + lat + ',' + long);
  };

  return (
    <div className="flex gap-x-2">
      <Tooltip title="Share Location" placement="top">
        <WhatsappShareButton
          url={`https://maps.google.com?q=${lat},${long}`}
          title={ShareMsg}
        >
          <div className="cursor-pointer">
            <IosShareOutlinedIcon />
          </div>
        </WhatsappShareButton>
      </Tooltip>

      <Tooltip title="View Location" placement="top">
        <div className="cursor-pointer" onClick={() => ViewLoc()}>
          <LocationOnOutlinedIcon />
        </div>
      </Tooltip>
    </div>
  );
}

export default ShareCopyLocation;
