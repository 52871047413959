import { types } from './ActionTypes';

// ///////////////////////////////////// auth
// genetate temp id
export const SaveTempIDAction = (temp) => {
  return { type: types.Save_TempID, payload: temp };
};

export const SaveTempIDActionSuccess = (data) => {
  return { type: types.Save_TempID_SUCCESS, payload: data };
};

// save login (guest and user) and register info
export const SaveUserInfoAction = (info) => {
  return { type: types.Save_User_Info, payload: info };
};

export const SaveUserInfoActionSuccess = (data) => {
  return { type: types.Save_User_Info_SUCCESS, payload: data };
};

// if user logged in
export const UserLoggedInAction = (info) => {
  return { type: types.User_LoggedIn, payload: info };
};

export const UserLoggedInActionSuccess = (data) => {
  return { type: types.User_LoggedIn_SUCCESS, payload: data };
};

// save access token
export const SaveTokenAction = (info) => {
  return { type: types.Save_Token, payload: info };
};

export const SaveTokenActionSuccess = (data) => {
  return { type: types.Save_Token_SUCCESS, payload: data };
};

// if user is guest
export const IsGuestUserAction = (info) => {
  return { type: types.Is_Guest_User, payload: info };
};

export const IsGuestUserActionSuccess = (data) => {
  return { type: types.Is_Guest_User_SUCCESS, payload: data };
};

export const LogOutAction = () => {
  return { type: types.LogOut };
};

export const LogOutActionSuccess = (data) => {
  console.log(data);
  return { type: types.LogOut_SUCCESS, payload: data };
};

// hide show alert
export const ToggleShowHideAction = (info) => {
  return { type: types.Toggle_Hide_Show_Alert, payload: info };
};

export const ToggleShowHideActionSuccess = (data) => {
  return { type: types.Toggle_Hide_Show_Alert_SUCCESS, payload: data };
};

// save user selections sports
export const SaveUserDataAction = (info) => {
  return { type: types.Save_Selected_Data, payload: info };
};

export const SaveUserDataActionSuccess = (data) => {
  return { type: types.Save_Selected_Data_Success, payload: data };
};

// save invoice id
export const SaveInvoiceIdAction = (info) => {
  return { type: types.Save_InvoiceId_Data, payload: info };
};

export const SaveInvoiceIdActionSuccess = (data) => {
  return { type: types.Save_InvoiceId_Data_SUCCESS, payload: data };
};

// save order status
export const SaveOrderStatusAction = (info) => {
  return { type: types.Save_OrderStaus_Data, payload: info };
};

export const SaveOrderStatusActionSuccess = (data) => {
  return { type: types.Save_OrderStaus_Data_SUCCESS, payload: data };
};

// Save Cart
export const SaveCartAction = (cart) => {
  return { type: types.Save_Cart, payload: cart };
};

export const SaveCartActionSuccess = (data) => {
  return { type: types.Save_Cart_SUCCESS, payload: data };
};

//set Cart flag
export const SetCartChangedFlagAction = (info) => {
  return { type: types.Set_Cart_Change_Flag, payload: info };
};

export const SetCartChangedFlagActionSuccess = (data) => {
  return { type: types.Set_Cart_Change_Flag_SUCCESS, payload: data };
};

export const changeLocale = (payload) => {
  return { type: types.CHANGE_LOCALE, payload };
};

export const setLang = (payload) => {
  return { type: types.SET_LANG, payload };
};
