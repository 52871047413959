import React, { useEffect } from 'react';
import Content from './Content';
import Header from '../../Components/Header';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MainLayout from '../../Components/MainLayout';

export default function Otp() {
  const navigate = useNavigate();
  const UserInfo = useSelector((state) => state.PersistedReducer.UserInfo);

  useEffect(() => {
    if (UserInfo.user) {
    } else {
      navigate('/CreateAccount');
    }
  }, []);

  return (
    <MainLayout>
      <div className="lg:w-1/3 mt-5 grow">
        <Content />
      </div>
    </MainLayout>
  );
}
