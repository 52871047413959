import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Card from '../../Home/Card';
import { ReactComponent as Sports } from '../../../Assets/Icons/Sports.svg';
import { ReactComponent as Calender } from '../../../Assets/Icons/Calender.svg';
import { ReactComponent as Areas } from '../../../Assets/Icons/Areas.svg';
import { ReactComponent as SelectedArea } from '../../../Assets/Icons/WhiteSelectedArea.svg';
import { ReactComponent as WhiteCalender } from '../../../Assets/Icons/WhiteCalender.svg';

import SearchBar from '../../../Components/SearchBar';
import {  useSelector } from 'react-redux';
import SelectedCard from './SelectedCard';

export default function Content() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );

  return (
    <div className="flex flex-col">
      {/* centered title */}
      

      {/* categories */}
      <div className="grid grid-cols-2 gap-x-5 gap-y-6  py-5">
        {/* select sport card */}
        {UserSelectedData.SelectedSport ? (
          <div
            className="col-span-2 rounded-xl grid grid-cols-2 gap-x-2 h-28 py-2 px-4"
            style={{
              backgroundImage: `linear-gradient(80deg,#${UserSelectedData.SelectedSport.color}55,#${UserSelectedData.SelectedSport.color})`,
            }}
          >
            {/* left side selected sport and change btn */}
            <div className="flex flex-col justify-between items-start">
              <div className="flex flex-col items-start text-sm">
                <p>{UserSelectedData.SelectedSport.name}</p>
                <p>{t('Sport selected')}</p>
              </div>

              <div className="" onClick={() => navigate('/SelectSport')}>
                <button className="text-white text-xs bg-TransparentWhite rounded-full px-2 py-px">
                  {t('Change')}
                </button>
              </div>
            </div>

            {/* image */}
            <div className="flex justify-end h-24">
              <img
                className="h-full"
                src={UserSelectedData.SelectedSport.image}
              />
            </div>
          </div>
        ) : (
          <Card
            cardClassName="col-span-2 py-8"
            onClick={() => navigate('/SelectSport')}
            text={t('Select Sports')}
            icon={<Sports width="73.722" height="60.589" className="w-full" />}
          />
        )}

        {/* select date */}
        {UserSelectedData.SelectedDate ? (
          <SelectedCard
            cardClassName="h-32"
            ChangeBtnOnClick={() => navigate('/SelectDate')}
            FirstRow={
              <div className="flex flex-col items-start text-white text-sm">
                <p>{new Date(UserSelectedData.SelectedDate).toDateString()}</p>
                <p>{t('Date selected')}</p>
              </div>
            }
            icon={<WhiteCalender width="40" height="40" />}
          />
        ) : (
          <Card
            cardClassName={'py-8'}
            onClick={() => {
              navigate('/SelectDate');
            }}
            text={t('Select Date')}
            icon={
              <Calender width="53.722" height="40.589" className="w-full" />
            }
          />
        )}

        {/* select area card */}
        {UserSelectedData.SelectedArea ? (
          <SelectedCard
            cardClassName="h-32"
            ChangeBtnOnClick={() => navigate('/SelectArea')}
            showRemoveArea="true"
            FirstRow={
              <div className="flex flex-col items-start text-white text-sm">
                <p>{UserSelectedData.SelectedArea.name}</p>
                <p>{t('Area selected')}</p>
              </div>
            }
            icon={<SelectedArea width="40" height="40" />}
          />
        ) : (
          <Card
            cardClassName={'py-8'}
            onClick={() => {
              navigate('/SelectArea');
            }}
            text={t('Select Areas')}
            icon={<Areas width="53.722" height="40.589" className="w-full" />}
          />
        )}
      </div>

      {/* continue btn */}
      {UserSelectedData.SelectedSport && UserSelectedData.SelectedDate ? (
        <SearchBar
          onClick={() => {
            navigate('/Venues');
          }}
          textClass="text-white"
          ContainerClass="bg-DarkBlue cursor-pointer"
        />
      ) : (
        <SearchBar textClass="text-InputGray" />
      )}
    </div>
  );
}
