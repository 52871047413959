import React from 'react';
import BackBTN from '../../../Components/BackBTN';
import Content from './Content';
import MainLayout from '../../../Components/MainLayout';
import { useTranslation } from 'react-i18next';

export default function SportCategory() {
  const {t}=useTranslation()
  return (
    <MainLayout Cat="Sports">
      <div className="mt-5 grow w-11/12 lg:w-1/3 sm:w-1/2">
        <BackBTN
          text={
            <div className="flex justify-center w-full">
              <p className="w-3/5">
                {t('Select your sport')}
              </p>
            </div>
          }
        />
        <Content />
      </div>
    </MainLayout>
  );
}
