import React from 'react';
import { DetectOnlineStatus } from '../Utils/Utils';
import { useTranslation } from 'react-i18next';
import Header from './Header';
import Footer from './Footer';

export default function MainLayout({
  children,
  showCart = false,
  active = ``,
  showFooter = true,
  Cat=undefined
}) {
  const { i18n } = useTranslation();
  return (
    <DetectOnlineStatus cat={Cat}>
      <div
        dir={i18n.dir().toString()}
        className="flex flex-col items-center h-screen scroll-smooth hover:scroll-auto font-tajwal-medium capitalize"
      >
        <Header ShowCart={showCart} />
        {children}
        {showFooter && <Footer active={active} />}
      </div>
    </DetectOnlineStatus>
  );
}
