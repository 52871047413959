import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Search } from '../Assets/Icons/Search.svg';

export default function SearchBar({
  ContainerClass = '',
  textClass = '',
  onClick = {},
}) {
  const { t } = useTranslation();
  return (
    <div
      onClick={() => {
        onClick();
      }}
      className={
        'flex justify-between bg-SearchGrey rounded-xl p-3 ' + ContainerClass
      }
    >
      <p className={textClass}>{t('Search')}</p>
      <Search width="20.733" height="21.76" />
    </div>
  );
}
