import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BackBTN from '../../../Components/BackBTN';
import { SaveUserDataAction } from '../../../Redux/Actions';

function Content(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );

  const OnClassClicked = (SelectedClass) => {
    dispatch(
      SaveUserDataAction({ ...UserSelectedData, SelectedClass: SelectedClass })
    );
    navigate('/classes');
  };

  return (
    <div>
      <BackBTN
        text={
          <div className="flex justify-center">
            <p>{t('Select your type of Classes')}</p>
          </div>
        }
      />

      <div className="grid grid-cols-1 gap-x-3 gap-y-4">
        {props.ClassTypes.data.map((itm) => {
          return (
            <div
              onClick={() => OnClassClicked(itm)}
              key={itm.id}
              className="grid grid-cols-2 h-24 rounded-xl px-3 cursor-pointer"
              style={{
                backgroundImage: `linear-gradient(80deg,#${itm.color}, #${itm.color}55)`,
              }}
            >
              <div className="flex items-center text-white px-2">
                <p>{itm.name}</p>
              </div>

              <div className="flex justify-end h-24">
                <img className="h-full" src={itm.image} alt={itm.name} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Content;
