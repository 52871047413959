import React from 'react';
import Skeleton from '@mui/material/Skeleton';

function LoadingPlaceholder(props) {
  return (
    <div className="grid grid-cols-2 md:grid-cols-3 gap-x-3 gap-y-5">
      {Array.apply(null, { length: 10 }).map((itm, i) => {
        return (
          <Skeleton
            key={i}
            variant="rectangular"
            width={'100%'}
            height={'7rem'}
          />
        );
      })}
    </div>
  );
}

export default LoadingPlaceholder;
