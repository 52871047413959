import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { GetAllVendors } from '../../../API/APIRequests';
import Footer from '../../../Components/Footer';
import Header from '../../../Components/Header';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import Content from './Content';
import MainLayout from '../../../Components/MainLayout';

function AllVendors(props) {
  const { i18n } = useTranslation();
  const { CatId } = useParams();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const [AllVendorsRes, SetAllVendorsRes] = useState({});

  useEffect(() => {
    if (UserSelectedData.Country) {
      GetAllVendors(
        {
          countryID: UserSelectedData.Country.id,
          data: {
            ...(CatId === 'null' ? {} : { category_id: CatId }),
            limit: 10,
            page: 1,
          },
        },
        (res) => {
          SetAllVendorsRes(res);
        }
      );
    }
  }, [i18n.language, UserSelectedData.Country]);

  useEffect(() => {
    console.log(AllVendorsRes);
  }, [AllVendorsRes]);

  return (
    <MainLayout Cat='Store' showCart={true}>
      {AllVendorsRes.success ? (
        <div className="px-3 md:px-0 lg:w-1/3 grow">
          <Content
            data={AllVendorsRes.data}
            pagination={AllVendorsRes.pagination}
          />
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </MainLayout>
  );
}

export default AllVendors;
