import React, { useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import StoreItemCard from '../../../Components/StoreItemCard';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import './Style.css';
import CustomButton from '../../../Components/CustomButton';
import { useNavigate } from 'react-router-dom';
import GreyLine from '../../../Components/GreyLine';
import GeneralCarousel from '../../../Components/GeneralCarousel';

function CategoryItems(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [ActiveCat, SetActiveCat] = useState(props.ActiveCat);

  useEffect(() => {
    SetActiveCat(props.ActiveCat);
  }, [props.ActiveCat]);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    Rtl: true,
    initialSlide: 0,
  };
  return (
    <div>
      <GeneralCarousel slidesArray={props.data.data.home_slider_images} />

            {props.data.data.just_added_products.length !== 0 &&
                <div className='py-8'>
                    <div className='flex justify-between gap-x-3 items-center pb-2'>
                        <p className='text-start whitespace-nowrap pb-2'>{t("Just Added")}</p>
                        <GreyLine className='w-full' />
                    </div>

                    <div className='h-32 w-full'>
                        {props.data.data.just_added_products.length <= 3 ?
                            <div className='grid grid-cols-3 gap-x-3'>
                                {props.data.data.just_added_products.map(itm => {
                                    return (
                                        <StoreItemCard data={itm} />
                                    )
                                })}
                            </div>

                            :
                            <Slider {...settings}>
                                {props.data.data.just_added_products.map(itm => {
                                    return (
                                        <div key={itm.id} className="pr-3">
                                            <StoreItemCard data={itm} />
                                        </div>
                                    )
                                })}
                            </Slider>
                        }
                    </div>
                </div>
            }

      {props.data.data.just_added_products.length !== 0 && (
        <div className="py-8">
          <div className="flex justify-between gap-x-3 items-center pb-2">
            <p className="text-start whitespace-nowrap pb-2">
              {t('Just Added')}
            </p>
            <GreyLine className="w-full" />
          </div>

          <div className="h-32 w-full">
            {props.data.data.just_added_products.length <= 3 ? (
              <div className="grid grid-cols-3 gap-x-3">
                {props.data.data.just_added_products.map((itm) => {
                  return <StoreItemCard data={itm} />;
                })}
              </div>
            ) : (
              <Slider {...settings}>
                {props.data.data.just_added_products.map((itm) => {
                  return (
                    <div key={itm.id} className="pr-3">
                      <StoreItemCard data={itm} />
                    </div>
                  );
                })}
              </Slider>
            )}
          </div>
        </div>
      )}

      {props.data.data.bestselling_products.length !== 0 && (
        <div className="py-8">
          <div className="flex justify-between gap-x-3 items-center pb-2">
            <p className="text-start whitespace-nowrap pb-2">
              {t('Best Selling')}
            </p>
            <GreyLine className="w-full" />
          </div>

          <div className="h-32 w-full">
            {props.data.data.bestselling_products.length <= 3 ? (
              <div className="grid grid-cols-3 gap-x-3">
                {props.data.data.bestselling_products.map((itm) => {
                  return <StoreItemCard data={itm} />;
                })}
              </div>
            ) : (
              <Slider {...settings}>
                {props.data.data.bestselling_products.map((itm) => {
                  return (
                    <div key={itm.id} className="pr-3">
                      <StoreItemCard data={itm} />
                    </div>
                  );
                })}
              </Slider>
            )}
          </div>
        </div>
      )}

      {/* view all products for that category */}
      {props.data.data.bestselling_products.length !== 0 ||
      props.data.data.just_added_products.length ? (
        <div className="py-5 mt-5">
          <CustomButton
            onclick={() => {
              navigate(`/AllProducts/${ActiveCat.id}`);
            }}
            BtnValue={t('View All Products')}
            ContainerClass="bg-primary_BG rounded-xl text-sm text-white cursor-pointer py-1"
          />
        </div>
      ) : (
        <></>
      )}

      {/* vendors */}

      {props.data.data.featured_vendors.length !== 0 && (
        <>
          <div className="py-8">
            <div className="flex justify-between gap-x-3 items-center pb-2">
              <p className="pb-2">{t('Vendors')}</p>
              <GreyLine className="w-full" />
            </div>

            <div className="w-full">
              {props.data.data.featured_vendors.length <= 3 ? (
                <div className="grid grid-cols-3 gap-x-3">
                  {props.data.data.featured_vendors.map((itm) => {
                    return <StoreItemCard data={itm} />;
                  })}
                </div>
              ) : (
                <Slider {...settings}>
                  {props.data.data.featured_vendors.map((itm) => {
                    return (
                      <div key={itm.id} className="pr-3 py-2">
                        <StoreItemCard vendor={true} data={itm} />
                      </div>
                    );
                  })}
                </Slider>
              )}
            </div>
          </div>

          {/* view all products for that category */}
          <div className="py-5">
            <CustomButton
              onclick={() => {
                navigate(`/AllVendors/${ActiveCat.id}`);
              }}
              BtnValue={t('View All Vendors')}
              ContainerClass="bg-primary_BG rounded-xl text-sm text-white cursor-pointer py-1"
            />
          </div>
        </>
      )}
    </div>
  );
}

export default CategoryItems;
