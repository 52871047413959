import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BackBTN from '../../../Components/BackBTN';
import Carousel from 'react-material-ui-carousel';
import SectionTitle from './SectionTitle';
import { ReactComponent as Bookings } from '../../../Assets/Icons/Bookings.svg';
import { ReactComponent as Aminities } from '../../../Assets/Icons/Aminities.svg';
import { ReactComponent as Location } from '../../../Assets/Icons/Location.svg';
import { ReactComponent as LocationMarker } from '../../../Assets/Icons/LocationMarker.svg';
import { ReactComponent as TransactionDate } from '../../../Assets/Icons/TransactionDate.svg';

import GoogleMapReact from 'google-map-react';
import CustomButton from '../../../Components/CustomButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  SaveUserDataAction,
  ToggleShowHideAction,
} from '../../../Redux/Actions';
import { ModifyDateFormat } from '../../../Utils/Utils';
import GreyLine from '../../../Components/GreyLine';
import GeneralCarousel from '../../../Components/GeneralCarousel';

export default function Content(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { VenueId } = useParams();
  const [SelectedTime, SetSelectedTime] = useState('');

  const LoggedIn = useSelector((state) => state.PersistedReducer.Loggedin);
  const GuestUser = useSelector((state) => state.PersistedReducer.GuestUser);
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );

  const HandelContinue = () => {
    // console.log(SelectedTime,VenueId)

    if (SelectedTime === '') {
      dispatch(
        ToggleShowHideAction({
          Open: true,
          Type: 'info',
          Msg: t('Please select a time to continue '),
        })
      );
    } else {
      dispatch(
        SaveUserDataAction({
          ...UserSelectedData,
          VenueData: {
            Time: SelectedTime,
            VenueId: VenueId,
          },
        })
      );
      if (LoggedIn === true || GuestUser === true) {
        navigate('/ReviewOrder', { state: { time: SelectedTime } });
      } else {
        navigate('/Login');
      }
    }
  };

  return (
    <div>
      <BackBTN />
      <div className="my-3">
        <GeneralCarousel
          slidesArray={props.Data.data.background_image_slider}
          h="6rem"
        />
      </div>

      <div className="grid grid-cols-2 mb-4">
        <div className="flex p-px">
          <img
            alt=""
            className="w-10 h-10 rounded-md"
            src={props.Data.data.vendor_logo}
            // src={require('../../Assets/Images/img-not-found-thumbNail.png')}
            onError={(e) => {
              e.onerror = null; // prevents looping
              e.target.src = require('../../../Assets/Images/img-not-found-thumbNail.png');
            }}
          />
          <div className="text-left mx-1">
            <p className="">{props.Data.data.venue_name}</p>
            <p className="text-xxs">{props.Data.data.vendor_name}</p>
          </div>
        </div>

        <div className="flex flex-col justify-between items-end h-full">
          <p className="text-primary_BG">
            {props.Data.data.price} {t('KWD')}
          </p>
          <p className="bg-SearchGrey rounded-xl px-1.5 text-xxs">
            {props.Data.data.space}
          </p>
        </div>
      </div>

      <GreyLine />

      {/* date */}
      {UserSelectedData.SelectedDate && (
        <div className="flex justify-between items-center mb-5 mt-3">
          <div className="flex gap-x-3 items-center">
            <div className="flex items-center gap-x-1 text-primary_BG font-bold">
              <TransactionDate width="16" height="18.516" />
              <p>{t('Date')}</p>
            </div>
            <div className="text-sm">
              <p>{ModifyDateFormat(UserSelectedData.SelectedDate)}</p>
            </div>
          </div>

          <button
            onClick={() => {
              navigate('/SelectDate');
            }}
            className="rounded-full bg-SearchGrey text-xs px-2 py-px"
          >
            {t('Change')}
          </button>
        </div>
      )}

      {/* bookings */}
      <SectionTitle
        icon={<Bookings width="22" height="10.515" />}
        title={t('Bookings')}
      />

      {/* times */}
      <div className="grid  grid-cols-2 md:grid-cols-3  gap-x-2 gap-y-3 justify-items-center mt-1 mb-4 bg-LighterGray rounded-md px-2 py-4 min-h-5">
        {props.Data.data.booking_times.map((item, index) => {
          if (item.active === true) {
            return (
              <div
                key={index}
                onClick={() => SetSelectedTime(item.name)}
                class={
                  SelectedTime === item.name
                    ? 'cursor-pointer rounded bg-primary_BG border border-white text-center text-xs text-white  w-fit h-fit px-2 whitespace-nowrap'
                    : 'cursor-pointer rounded bg-white border border-primary_BG text-center text-xs text-DarkBlue w-fit h-fit px-2 whitespace-nowrap'
                }
              >
                {item.name}
              </div>
            );
          } else {
            return (
              <div
                key={index}
                class="rounded bg-white text-center text-GreyBG text-xs w-fit h-fit px-2"
              >
                {item.name}
              </div>
            );
          }
        })}
      </div>

      {/* aminities */}
      <SectionTitle
        icon={<Aminities width="15.703" height="15.487" />}
        title={t('Amenities')}
      />
      <div className="grid grid-flow-col gap-x-2 gap-y-3 mt-1 mb-4 bg-LighterGray rounded-md p-2 h-24">
        {props.Data.data.amenities.map((itm) => {
          return (
            <div
              key={itm.id}
              className="flex flex-col items-center justify-center"
            >
              <img
                className="h-10 w-10"
                alt={itm.name}
                src={itm.icon}
                onError={(e) => {
                  e.onerror = null; // prevents looping
                  e.target.src = require('../../../Assets/Images/img-not-found-thumbNail.png');
                }}
              />

              <p className="text-primary_BG text-xs py-1">{itm.name}</p>
            </div>
          );
        })}
      </div>

      {/* location */}
      <SectionTitle
        icon={<Location width="9.857" height="14.899" />}
        title={t('Location')}
      />

      <div className="h-32 mb-3">
        <GoogleMapReact
          className="rounded-md"
          bootstrapURLKeys={{
            // remove the key if you want to fork
            key: 'AIzaSyChibV0_W_OlSRJg2GjL8TWVU8CzpRHRAE',
            language: 'en',
            region: 'US',
          }}
          defaultCenter={{
            lat: props.Data.data.lat
              ? parseInt(props.Data.data.lat)
              : 10.99835602,
            lng: props.Data.data.long
              ? parseInt(props.Data.data.long)
              : 77.01502627,
          }}
          defaultZoom={11}
        >
          <LocationMarker
            width="20.601"
            height="31.664"
            lat={props.Data.data.lat ? props.Data.data.lat : 10.99835602}
            lng={props.Data.data.long ? props.Data.data.long : 77.01502627}
          />
        </GoogleMapReact>
      </div>

      {/* continue btn */}
      <div className="py-5">
        <CustomButton
          onclick={() => {
            HandelContinue();
          }}
          BtnValue={t('Continue')}
          ContainerClass="bg-primary_BG rounded-xl text-sm text-white cursor-pointer py-1"
        />
      </div>
    </div>
  );
}
