import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Store } from '../../Assets/Icons/Store.svg';
import { ReactComponent as Retry } from '../../Assets/Icons/Class Category/Retry.svg';
import { useNavigate } from 'react-router-dom';

function StoreOrderFailure(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex justify-center">
        <img src={require('../../Assets/Images/Store/Fail.png')} />
      </div>

      <div className="my-2">
        <p className="text-lg">{t('We’re Sorry')}</p>
        <p className="">{t('Your purchase has failed')}</p>
      </div>

      <div class="grid grid-cols-2 gap-x-3 my-10">
        <div
          onClick={() => {
            navigate('/AllProducts/null');
          }}
          class="cursor-pointer shadow-custome rounded-2xl grid grid-rows-2 pt-3 lg:py-5 lg:px-3 lg:grid-cols-3 lg:grid-rows-none lg:items-center"
        >
          <div class="flex justify-center items-center">
            <Store width="31.05" height="31.081" />
          </div>
          <p class="text-xs text-center pt-3 lg:pt-0 col-span-2">
            {t('Shop more items')}
          </p>
        </div>

        <div
          onClick={() => {
            navigate('/ReviewOrder/Store');
          }}
          class="cursor-pointer shadow-custome rounded-2xl grid grid-rows-2 pt-3 lg:py-3 lg:px-3 lg:grid-cols-3 lg:grid-rows-none lg:items-center"
          id="SecondCard"
        >
          <div class="flex justify-center items-center">
            <Retry width="27.249" height="30.894" />
          </div>
          <p class="text-xs text-center pt-3 lg:pt-0 col-span-2">
            {t('Re-Try Booking')}
          </p>
        </div>
      </div>
    </div>
  );
}

export default StoreOrderFailure;
