import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../Components/LoadingSpinner';
import {
  GetClassesHistory,
  GetSportsHistory,
  GetSubscriptionHistory,
  GetStoreHistory,
} from '../../API/APIRequests';
import SportsHistory from './SportsHistory';
import ClassesHistory from './ClassesHistory';
import SubscriptionHistory from './SubscriptionHistory';
import StoreHistory from './StoreHistory';
import PlaceHolderSkelton from './PlaceHolderSkelton';

export default function Content() {
  const { t, i18n } = useTranslation();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const UserInfo = useSelector((state) => state.PersistedReducer.UserInfo);

  const [ActiveCategory, SetActiveCategory] = useState(
    UserSelectedData.Category
  );
  const [History, SetHistory] = useState({});

  useEffect(() => {
    switch (ActiveCategory) {
      case 'Sports':
        GetSportsHistory(
          {
            Authorization: `Bearer ${UserInfo.access_token}`,
            countryID: UserSelectedData.Country.id,
          },
          (res) => {
            SetHistory(res);
          }
        );
        break;

      case 'Classes':
        GetClassesHistory(
          {
            Authorization: `Bearer ${UserInfo.access_token}`,
            countryID: UserSelectedData.Country.id,
          },
          (res) => {
            SetHistory(res);
          }
        );
        break;

      case 'Subscriptions':
        GetSubscriptionHistory(
          {
            Authorization: `Bearer ${UserInfo.access_token}`,
            countryID: UserSelectedData.Country.id,
          },
          (res) => {
            SetHistory(res);
          }
        );
        break;

      case 'Store':
        GetStoreHistory(
          {
            Authorization: `Bearer ${UserInfo.access_token}`,
            countryID: UserSelectedData.Country.id,
          },
          (res) => {
            SetHistory(res);
          }
        );
        break;

      default:
        break;
    }
  }, [i18n.language, UserSelectedData.Country.id, ActiveCategory]);

  useEffect(() => {
    console.log(History);
  }, [History]);

  return (
    <>
      {/* categories */}
      <div className="grid grid-cols-4 gap-x-1 text-xs text-DarkGrey justify-items-center items-center py-3">
        <div
          className={
            ActiveCategory === 'Store'
              ? 'flex justify-center cursor-pointer bg-LighterGray text-primary_BG rounded-md py-1 px-2 w-fit'
              : 'flex justify-center cursor-pointer'
          }
          onClick={() => {
            SetActiveCategory('Store');
            SetHistory({});
          }}
        >
          <p>{t('Store')}</p>
        </div>

        {/* Sports */}
        <div
          className={
            ActiveCategory === 'Sports'
              ? 'flex justify-center cursor-pointer bg-LighterGray text-primary_BG rounded-md py-1 px-2 w-fit'
              : 'flex justify-center cursor-pointer'
          }
          onClick={() => {
            SetActiveCategory('Sports');
            SetHistory({});
          }}
        >
          <p>{t('Sports')}</p>
        </div>
        {/* Classes */}
        <div
          className={
            ActiveCategory === 'Classes'
              ? 'flex justify-center cursor-pointer bg-LighterGray text-primary_BG rounded-md py-1 px-2 w-fit'
              : 'flex justify-center cursor-pointer'
          }
          onClick={() => {
            SetActiveCategory('Classes');
            SetHistory({});
          }}
        >
          <p>{t('Classes')}</p>
        </div>

        {/* Subscriptions */}
        <div
          className={
            ActiveCategory === 'Subscriptions'
              ? 'flex justify-center cursor-pointer bg-LighterGray text-primary_BG rounded-md py-1 px-2 w-fit'
              : 'flex justify-center cursor-pointer'
          }
          onClick={() => {
            SetActiveCategory('Subscriptions');
            SetHistory({});
          }}
        >
          <p>{t('Subscriptions')}</p>
        </div>
      </div>
      {History.success === true ? (
        <div>
          {/* map the history here */}
          <div>
            {History.data.length === 0 ? (
              <div className="py-5">
                <p>{t('No orders found ')}</p>
              </div>
            ) : (
              <div className="py-5">
                {ActiveCategory === 'Sports' ? (
                  <SportsHistory History={History} />
                ) : ActiveCategory === 'Classes' ? (
                  <ClassesHistory History={History} />
                ) : ActiveCategory === 'Subscriptions' ? (
                  <SubscriptionHistory History={History} />
                ) : (
                  ActiveCategory === 'Store' && (
                    <StoreHistory History={History} />
                  )
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <PlaceHolderSkelton />
      )}
    </>
  );
}
