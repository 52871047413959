import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BackBTN from '../../../Components/BackBTN';

import { ReactComponent as Gender } from '../../../Assets/Icons/Class Category/Gender.svg';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SportsIcon from '@mui/icons-material/Sports';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

export default function Content(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );

  const OnClassClicked = (classId) => {
    navigate(`/ClassDetails/${classId}`);
  };

  useEffect(() => {
    console.log(props.AllClasses);
  }, []);

  return (
    <div>
      <BackBTN
        text={
          <div className="flex justify-center w-full">
            <p className="w-3/5">
              {props.AllClasses.data.length} classes found
            </p>
          </div>
        }
      />

      {/* classes */}
      {props.AllClasses.data.length === 0 ? (
        <div className="my-14">
          <p>{t('No Classes Found')}</p>
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 gap-x-4 gap-y-3">
            {props.AllClasses.data.map((itm) => {
              return (
                <div
                  className="cursor-pointer"
                  key={itm.id}
                  onClick={() => {
                    OnClassClicked(itm.id);
                  }}
                >
                  <div className="h-56">
                    <img
                      className="h-full w-full rounded-t-lg"
                      src={itm.main_image}
                    />
                  </div>

                  <div className="relative -top-10 bg-primary_BG rounded-lg text-white text-xxs p-2">
                    {/* vendor info */}
                    <div className="flex justify-between mb-2">
                      <div className="flex gap-x-1">
                        <img className="w-10 h-10" src={itm.vendor_logo} />
                        <div className="flex flex-col items-start">
                          <p className="w-fit">{itm.class_name}</p>
                          <p className="w-fit">{itm.vendor_name}</p>
                        </div>
                      </div>

                      <p>
                        {itm.class_price} {itm.currency}
                      </p>
                    </div>

                    <div className="grid grid-cols-2 gap-y-2 gap-x-1">
                      <div className="flex ">
                        {/* <ClassCats
                          width="17.864"
                          height="17.872"
                          className="w-1/6"
                        /> */}
                        <SportsIcon className="w-1/6" sx={{ color: 'white', fontSize:14 }}/>
                        <p className="break-all ltr:text-left rtl:text-right px-1">
                          {itm.categories}
                        </p>
                      </div>

                      {itm.is_capacity === 1 && (
                        <div className="flex ">
                          {/* <AvailableSeats
                            width="10.232"
                            height="12.249"
                            className="w-1/6"
                          /> */}
                          <PersonOutlineOutlinedIcon className="w-1/6" sx={{ color: 'white', fontSize:14 }}/>
                          <p className="ltr:text-left rtl:text-right px-1">
                            {itm.available_seats} {t('available seats')}
                          </p>
                        </div>
                      )}

                      <div className="flex ">
                        {/* <Calender
                          width="11.865"
                          height="13.025"
                          className="w-1/6"
                        /> */}
                        <CalendarMonthIcon className="w-1/6" sx={{ color: 'white', fontSize:14 }}/>
                        <p className="break-all ltr:text-left rtl:text-right px-1">
                          {itm.date}
                        </p>
                      </div>

                      <div className="flex ">
                        <Gender
                          width="14.683"
                          height="14.614"
                        />
                        <p className="break-all ltr:text-left rtl:text-right px-1">
                          {itm.gender}
                        </p>
                      </div>

                      <div className="flex ">
                        {/* <Clock
                          width="13.366"
                          height="13.374"
                          className="w-1/6"
                        /> */}
                        <AccessTimeIcon className="w-1/6" sx={{ color: 'white', fontSize:14 }}/>
                        <p className="break-all ltr:text-left rtl:text-right px-1">
                          {itm.time}{' '}
                        </p>
                      </div>

                      <div className="flex ">
                        {/* <WhiteLocation
                          width="11.185"
                          height="14.98"
                          className="w-1/6"
                        /> */}
                        <LocationOnIcon className="w-1/6" sx={{ color: 'white', fontSize:14 }}/>
                        <p className="break-all ltr:text-left rtl:text-right px-1">
                          {itm.area}{' '}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}
