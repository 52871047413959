import { useTranslation } from 'react-i18next';
import BackBTN from '../../../Components/BackBTN';
import { useEffect, useState } from 'react';
import {
  GetAddress,
  GetPaymentMethods,
  GetShoppingCart,
} from '../../../API/APIRequests';
import { useSelector } from 'react-redux';
import Content from './Content';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import MainLayout from '../../../Components/MainLayout';

function ReviewOrderStore() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const persistedReducer = useSelector((state) => state.PersistedReducer);
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const [Cart, SetCart] = useState({});
  const [paymentMethods, SetpaymentMethods] = useState({});
  const [Address, SetAddress] = useState({});

  useEffect(() => {
    if (UserSelectedData.Country) {
      if (UserSelectedData.StoreUserAddress) {
        GetShoppingCart(
          {
            countryID: persistedReducer.UserSelectedData.Country.id,
            data: {
              ...(persistedReducer.Loggedin && {
                user_id: persistedReducer.UserInfo.user.id,
              }),
              tempId: persistedReducer.TempID ? persistedReducer.TempID : null,
            },
          },
          (res) => {
            SetCart(res);
          }
        );

        GetPaymentMethods(
          {
            countryID: persistedReducer.UserSelectedData.Country.id,
          },
          (res) => {
            SetpaymentMethods(res);
          }
        );

        GetAddress(
          {
            countryID: persistedReducer.UserSelectedData.Country.id,
            data: { address_id: UserSelectedData.StoreUserAddress.id },
          },
          (res) => {
            SetAddress(res);
          }
        );
      } else {
        navigate('/DeliveryDetails');
      }
    }
  }, [i18n.language, UserSelectedData.Country, persistedReducer.Loggedin]);

  useEffect(() => {
    if (Cart.success) {
      if (Cart.data.length === 0) {
        navigate('/ShoppingCart');
      } else if (Cart.data.items.length === 0) {
        navigate('/ShoppingCart');
      }
    }
  }, [Cart]);

  return (
    <MainLayout showCart={true} Cat="Store">
      {Cart.success && paymentMethods.success && Address.success ? (
        <div className="px-3 md:px-0 lg:w-1/3 grow">
          <BackBTN
            text={
              <div className="mb-8">
                <p>{t(t('Review booking info'))}</p>
              </div>
            }
          />

          <Content Cart={Cart} methods={paymentMethods} add={Address} />
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </MainLayout>
  );
}

export default ReviewOrderStore;
