import React from 'react';
import Carousel from 'react-material-ui-carousel';

function GeneralCarousel({ slidesArray = [], h = '7rem' }) {
  return (
    <Carousel
      className="mt-3"
      navButtonsAlwaysInvisible={true}
      height={h}
      indicatorIconButtonProps={{
        style: {
          padding: '1px', // 1
          // color: 'blue'       // 3
        },
      }}
      activeIndicatorIconButtonProps={{
        style: {
          padding: '0.5px', // 1
          fontSize: '1px',
          // backgroundColor: 'red'
        },
      }}
      indicatorContainerProps={{
        style: {
          marginTop: '2px', // 5
        },
      }}
    >
      {slidesArray.length === 0 ? (
        <div className="flex justify-center h-full">
          <img
            className="h-full w-full rounded-md"
            src={require('../Assets/Images/img-not-found.png')}
          />
        </div>
      ) : (
        slidesArray.map((item, i) => {
          return (
            <div className="flex justify-center h-full" key={i}>
              <img
                className=" h-full w-full object-cover rounded-md"
                src={item}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = require('../Assets/Images/img-not-found.png');
                }}
              />
            </div>
          );
        })
      )}
    </Carousel>
  );
}

export default GeneralCarousel;
