import React, { useEffect, useState } from 'react';
import Footer from '../../../Components/Footer';
import Header from '../../../Components/Header';
import Content from './Content';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import { GetAllSports } from '../../../API/APIRequests';
import MainLayout from '../../../Components/MainLayout';

export default function Allsports() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const [AllSports, SetAllSports] = useState({});

  useEffect(() => {
    if (UserSelectedData.Country) {
      GetAllSports({ countryID: UserSelectedData.Country.id }, (res) => {
        SetAllSports(res);
      });
    } else {
      navigate('/home');
    }
  }, [i18n.language]);

  useEffect(() => {
    console.log(AllSports);
  }, [AllSports]);

  return (
    <MainLayout Cat='Sports'>
      {AllSports.success ? (
        <div className="mt-5 grow w-11/12 lg:w-1/3 sm:w-1/2">
          <Content AllSports={AllSports} />
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </MainLayout>
  );
}
