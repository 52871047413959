import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';

function StoreHistory(props) {
  const { t } = useTranslation();

  useEffect(() => {
    console.log(props.History);
  }, []);

  return (
    <>
      {props.History.data.map((itm, idx) => {
        return (
          <div className="rounded-md bg-HistoryBG px-3 py-1 mb-5">
            <div className="flex justify-between items-center mb-2">
              <p className="text-lg text-primary_BG">
                {t('Order')} {idx + 1}
              </p>
            </div>

            <div className="flex flex-col justify-start text-xs mb-5">
              <div className="flex ">
                <LocationOnIcon sx={{ fontSize: 14 }} />
                <p className="px-1">
                  {t('Address')} :{' '}
                  {Object.entries(itm.address)
                    .map((entry) => entry.join(': '))
                    .join(',')}
                </p>
              </div>
              <div className="flex ">
                <Grid3x3Icon sx={{ fontSize: 16 }} />
                <p className="px-1">
                  {t('Code')} : {itm.code}
                </p>
              </div>
            </div>

            {/* booked on */}
            <div className="w-full mb-2">
              <p className="text-primary_BG w-fit">{t('Bought on')}</p>
              <div className="flex justify-between text-xs">
                <p>{itm.orderedOn}</p>
                <p>{itm.total}</p>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
export default StoreHistory;
