import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BlackCalender } from '../../../Assets/Icons/BlackCalender.svg';
import { ReactComponent as BlackLocation } from '../../../Assets/Icons/BlackLocation.svg';
import { ReactComponent as BlackClock } from '../../../Assets/Icons/BlackClock.svg';

import { ReactComponent as GreenRight } from '../../../Assets/Icons/GreenRight.svg';
import CustomButton from '../../../Components/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  SaveInvoiceIdAction,
  SaveOrderStatusAction,
  ToggleShowHideAction,
} from '../../../Redux/Actions';
import { GetProceedToPayment } from '../../../API/APIRequests';
import { useNavigate } from 'react-router-dom';

function ClassOrderDetails(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const persistedState = useSelector((state) => state.PersistedReducer);
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );

  const [PaymentMethod, SetPaymentMethod] = useState('');
  const [ProceedToPaymentRes, SetProceedToPaymentRes] = useState({});
  const [SendReqLoading, SetSendReqLoading] = useState(false);

  const HandelProceedTopayment = () => {
    if (persistedState.GuestUser) {
      let restOfData = ValidateReqData();
      if (restOfData !== undefined) {
        SetSendReqLoading(true);
        GetProceedToPayment(
          {
            countryID: UserSelectedData.Country.id,
            data: {
              guest_name: persistedState.UserInfo.guest_name,
              guest_phone: persistedState.UserInfo.guest_phone,
              guest_gender: persistedState.UserInfo.guest_gender,
              ...restOfData,
            },
          },
          (res) => {
            SetProceedToPaymentRes(res);
          }
        );
      }
    } else if (persistedState.Loggedin) {
      let restOfData = ValidateReqData();
      // console.log(restOfData)
      if (restOfData !== undefined) {
        SetSendReqLoading(true);
        GetProceedToPayment(
          {
            countryID: UserSelectedData.Country.id,
            data: {
              user_id: persistedState.UserInfo.user.id,
              ...restOfData,
            },
          },
          (res) => {
            SetProceedToPaymentRes(res);
          }
        );
      }
    }
  };

  // check if data exist before req and if payment method is selected
  const ValidateReqData = () => {
    let restOfData = {};
    if (PaymentMethod !== '') {
      restOfData.payment_method = PaymentMethod;
      restOfData.class_id = UserSelectedData.ClassDetails.ClassId;
      restOfData.date = UserSelectedData.ClassDetails.Date;
      restOfData.channel = 'web';

      return restOfData;
    } else {
      dispatch(
        ToggleShowHideAction({
          Open: true,
          Type: 'info',
          Msg: t('Please select a payment method to continue'),
        })
      );
    }
  };

  // check res of proceed to payment
  useEffect(() => {
    // console.log(ProceedToPaymentRes)
    if (ProceedToPaymentRes.success === true) {
      if (PaymentMethod === 'cash') {
        // save data in orer status
        dispatch(SaveOrderStatusAction(ProceedToPaymentRes));
        navigate('/appointment/payment/success', { state: { cash: true } });
      } else {
        // save invoice id in redux
        dispatch(SaveInvoiceIdAction(ProceedToPaymentRes.data.invoice_id));
        // empty state before sending req in order status
        dispatch(SaveOrderStatusAction({}));
        window.open(ProceedToPaymentRes.data.url, '_self');
      }
    } else if (ProceedToPaymentRes.success === false) {
      dispatch(
        ToggleShowHideAction({
          Open: true,
          Type: 'error',
          Msg: t(ProceedToPaymentRes.message),
        })
      );
    }
  }, [ProceedToPaymentRes]);

  return (
    <div>
      <div className="flex flex-col justify-between rounded-md bg-primary_BG px-3 py-1 text-white mb-5 h-36">
        <div className="flex justify-between items-center mb-2">
          <div>
            <p className="text-xl text-start mb-2">
              {props.Details.data.class.class_name}
            </p>
            <div className="flex pb-1.5">
              <BlackLocation width="10.73" height="16.425" />
              <p className="px-1 text-xs">{props.Details.data.class.area}</p>
            </div>
          </div>
          <button
            onClick={() => navigate('/AllClassses')}
            className="text-white text-xs bg-TransparentWhite rounded-full px-2 py-px"
          >
            {t('Change')}
          </button>
        </div>

        {/* price and time and date  */}
        <div className="flex justify-between items-end mb-2">
          <div className="flex flex-col justify-start text-xs">
            <div className="flex mb-2">
              <BlackClock width="13.613" height="15.801" />
              <p className="px-1">{props.Details.data.class.time}</p>
            </div>

            <div className="flex">
              <BlackCalender width="13.613" height="15.801" />
              <p className="px-1">{props.Details.data.class.date}</p>
            </div>
          </div>
          <p>
            {props.Details.data.class.price} {props.Details.data.class.currency}
          </p>
        </div>
      </div>

      {/* payment section */}
      <div className="mb-5 text-sm">
        <p className="text-primary_BG w-fit mb-1">{t('Payment summary')}</p>

        <div className="bg-gray px-5 py-2 rounded-md">
          <div class="flex justify-between mb-3">
            <p>{t('Subtotal')}</p>
            <p>
              {props.Details.data.class.subtotal}{' '}
              {props.Details.data.class.currency}
            </p>
          </div>

          {UserSelectedData.Country?.tax_status === 1 ? (
            <div class="flex justify-between mb-1">
              <p>{t('Tax')}</p>
              <p>
                {UserSelectedData.Country?.tax}{' '}
                {UserSelectedData.Country?.currency}
              </p>
            </div>
          ) : (
            <></>
          )}

          <div class="flex justify-between mb-3 text-primary_BG">
            <p>{t('Total')}</p>
            <p>
              {props.Details.data.class.total}{' '}
              {props.Details.data.class.currency}
            </p>
          </div>
        </div>
      </div>

      {/* select payment method */}
      <div className="mb-5">
        <p className="mb-4">{t('Select your payment method')}</p>

        <div className="flex justify-between  gap-x-2">
          {props.Details.data.payment_methods.map((itm) => {
            return (
              <div
                onClick={() => SetPaymentMethod(itm.name)}
                key={itm.id}
                className={
                  PaymentMethod === itm.name
                    ? 'flex items-center justify-center rounded-md py-1 px-5 relative bg-gray relative'
                    : 'flex items-center justify-center rounded-md py-1 px-5 relative'
                }
              >
                {PaymentMethod === itm.name && (
                  <div className="absolute top-2 ltr:right-2">
                    <GreenRight width="15.763" height="10.44" />
                  </div>
                )}
                <div className="flex flex-col items-center">
                  <img className="w-2/3 pt-5" alt={itm.name} src={itm.image} />
                  <p className="text-sm pt-1">{itm.name}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* continue */}
      {SendReqLoading ? (
        <CustomButton
          ContainerClass="flex justify-center bg-InputGray text-white rounded-lg text-sm cursor-pointer py-2 mt-7"
          BtnValue={t('Continue')}
          ButtonClass="flex items-center gap-x-2"
          loading="true"
        />
      ) : (
        <CustomButton
          onclick={() => HandelProceedTopayment()}
          ContainerClass="bg-primary_BG text-white rounded-lg text-sm cursor-pointer py-1 mt-7"
          BtnValue={t('Continue')}
        />
      )}
    </div>
  );
}

export default ClassOrderDetails;
