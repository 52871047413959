import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CustomButton from './CustomButton';

function GoHomeBtn({containerClass=''}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <CustomButton
      onclick={() => navigate('/home')}
      ContainerClass={"bg-primary_BG rounded-lg text-sm text-white py-1 my-10 "+ containerClass}
      BtnValue={t('Home')}
    />
  );
}

export default GoHomeBtn;
