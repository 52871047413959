import Header from './../../Components/Header';
import Footer from './../../Components/Footer';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { GetStaticPage } from '../../API/APIRequests';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../Components/LoadingSpinner';
import AboutUs from './AboutUs';
import { useLocation } from 'react-router-dom';
import TermsAndConditions from './TermsAndConditions';
import MainLayout from '../../Components/MainLayout';

function StaticPages(props) {
  const { i18n } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const persistedReducer = useSelector((state) => state.PersistedReducer);
  const [StaticPagesData, SetStaticPagesData] = useState({});

  useEffect(() => {
    console.log(pathname);
    if(persistedReducer.UserSelectedData.Country){
      GetStaticPage(
        {
          countryID: persistedReducer.UserSelectedData.Country.id,
        },
        (res) => {
          SetStaticPagesData(res);
        }
      );
    }
  }, [i18n.language, persistedReducer.UserSelectedData.Country]);

  useEffect(() => {
    console.log(StaticPagesData);
  }, [StaticPagesData]);

  return (
    <MainLayout>
      {StaticPagesData.success ? (
        <div className="lg:w-1/3 mt-5 grow">
          {pathname === '/about' && StaticPagesData.data[0].status && (
            <AboutUs data={StaticPagesData} />
          )}
          {pathname === '/terms' && StaticPagesData.data[1].status && (
            <TermsAndConditions data={StaticPagesData} />
          )}
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </MainLayout>
  );
}

export default StaticPages;
