import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GetClassTypes } from '../../../API/APIRequests';
import Footer from '../../../Components/Footer';
import Header from '../../../Components/Header';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import Content from './Content';
import MainLayout from '../../../Components/MainLayout';

function ClassType(props) {
  const { i18n } = useTranslation();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const [ClassTypes, SetClassTypes] = useState({});

  useEffect(() => {
    GetClassTypes({ countryID: UserSelectedData.Country.id }, (res) => {
      SetClassTypes(res);
    });
  }, [i18n.language, UserSelectedData.Country.id]);

  // useEffect(()=>{
  //   console.log(AllSports)
  // },[AllSports])

  return (
    <MainLayout Cat='Classes'>
      {ClassTypes.success ? (
        <div className="w-11/12 lg:w-1/3 sm:w-1/2 mt-5 grow">
          <Content ClassTypes={ClassTypes} />
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </MainLayout>
  );
}

export default ClassType;
