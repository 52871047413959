import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  GetVendorDetails,
  GetVendorItemsCategories,
} from '../../../API/APIRequests';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import Content from './Content';
import MainLayout from '../../../Components/MainLayout';

function VendorDetails(props) {
  const { i18n } = useTranslation();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const [VendorDetails, SetVendorDetails] = useState({});
  const [VendorItemCats, SetVendorItemCats] = useState({});
  const { id } = useParams();

  useEffect(() => {
    if (UserSelectedData.Country) {
      GetVendorDetails(
        {
          countryID: UserSelectedData.Country.id,
          data: { vendor_id: id },
        },
        (res) => {
          SetVendorDetails(res);
        }
      );

      GetVendorItemsCategories(
        {
          countryID: UserSelectedData.Country.id,
          data: { vendor_id: id },
        },
        (res) => {
          SetVendorItemCats(res);
        }
      );
    }
  }, [i18n.language, UserSelectedData.Country]);

  useEffect(() => {
    console.log(VendorDetails);
  }, [VendorDetails]);

  return (
    <MainLayout showCart={true} Cat='Store'>
      {VendorDetails.success && VendorItemCats.success ? (
        <div className="lg:w-1/3 grow">
          <Content cats={VendorItemCats} data={VendorDetails} />
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </MainLayout>
  );
}

export default VendorDetails;
