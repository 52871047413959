import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BackBTN from '../../../Components/BackBTN';
import Footer from '../../../Components/Footer';
import Header from '../../../Components/Header';
import Content from './Content';
import './Style.css';
import MainLayout from '../../../Components/MainLayout';

function DeliveryDetails(props) {
  const { i18n, t } = useTranslation();

  return (
    <MainLayout showCart={true} Cat='Store'>
      <div className="px-3 md:px-0 lg:w-1/3 grow">
        <BackBTN />
        <div className="mb-8">
          <p>{t('Delivery details')}</p>
        </div>
        <Content />
      </div>
    </MainLayout>
  );
}

export default DeliveryDetails;
