import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Footer from '../../../Components/Footer';
import Header from '../../../Components/Header';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import Content from './Content';
import MainLayout from '../../../Components/MainLayout';

function SubscriptionDetails(props) {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );

  useEffect(() => {
    if (UserSelectedData.SelectedSubscription) {
    } else {
      navigate('/AllSubscriptions');
    }
  }, []);

  return (
    <MainLayout Cat='Subscriptions'>
      <div className="w-11/12 lg:w-1/3 sm:w-1/2 mt-5 grow">
        <Content />
      </div>
    </MainLayout>
  );
}

export default SubscriptionDetails;
