import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { GetShoppingCart } from '../../../API/APIRequests';
import BackBTN from '../../../Components/BackBTN';
import Footer from '../../../Components/Footer';
import Header from '../../../Components/Header';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import { SaveCartAction } from '../../../Redux/Actions';
// import { GetCartAction } from '../../../Redux/Actions';
import Content from './Content';
import MainLayout from '../../../Components/MainLayout';

function ShoppingCart(props) {
  const { t, i18n } = useTranslation();

  const persistedReducer = useSelector((state) => state.PersistedReducer);
  const MainReducer = useSelector((state) => state.MainReducer);
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const [Cart, SetCart] = useState({});

  useEffect(() => {
    if (UserSelectedData.Country) {
      // dispatch(GetCartAction())
      GetShoppingCart(
        {
          countryID: persistedReducer.UserSelectedData.Country.id,
          data: {
            // ...(persistedReducer.Loggedin ?
            //     {user_id:persistedReducer.UserInfo.user.id}
            //     :{tempId:persistedReducer.TempID}
            // ),

            ...(persistedReducer.Loggedin && {
              user_id: persistedReducer.UserInfo.user.id,
            }),
            tempId: persistedReducer.TempID ? persistedReducer.TempID : null,
          },
        },
        (res) => {
          SetCart(res);
        }
      );
    }
  }, [
    i18n.language,
    UserSelectedData.Country,
    MainReducer.CartChanged,
    persistedReducer.Loggedin,
  ]);

  useEffect(() => {
    console.log(Cart);
  }, [Cart]);

  return (
    <MainLayout showCart={true} Cat='Store'>
      {Cart.success ? (
        <div className="lg:w-1/3 flex grow">
          {Cart.data.length === 0 || Cart.data.items?.length === 0 ? (
            <div className="w-full">
              <BackBTN />
              <div className={`items-center justify-center`}>
                <img
                  alt="empty"
                  src={require('../../../Assets/Images/empty-cart.png')}
                  className={`object-contain h-fit`}
                />
                <p className="my-5">{t(`cart_is_empty`)}</p>
              </div>
            </div>
          ) : (
            <Content data={Cart} />
          )}
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </MainLayout>
  );
}

export default ShoppingCart;
