import React from 'react';
import Content from './Content';
import MainLayout from '../../Components/MainLayout';

export default function Login() {
  return (
    <MainLayout>
      <div className="w-11/12 lg:w-1/3 sm:w-1/2 mt-5 grow ">
        <Content />
      </div>
    </MainLayout>
  );
}
