import { types } from '../ActionTypes';
import { setLang } from '../Actions';

const initialState = {
  TempID: '',
  UserInfo: {},
  Token: '',
  Loggedin: false,
  GuestUser: false,

  UserSelectedData: {},
  InvoiceId: {},
  OrderStatus: {},
};

const PersistedReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.Save_TempID:
      return { ...state };

    case types.Save_TempID_SUCCESS:
      return { ...state, TempID: action.payload };

    case types.Save_User_Info:
      return { ...state };

    case types.Save_User_Info_SUCCESS:
      return { ...state, UserInfo: action.payload };

    case types.User_LoggedIn:
      return { ...state };

    case types.User_LoggedIn_SUCCESS:
      return { ...state, Loggedin: action.payload };

    case types.Save_Token:
      return { ...state };

    case types.Save_Token_SUCCESS:
      return { ...state, Token: action.payload };

    case types.Is_Guest_User:
      return { ...state };

    case types.Is_Guest_User_SUCCESS:
      return { ...state, GuestUser: action.payload };

    case types.LogOut:
      return { ...state };

    case types.LogOut_SUCCESS:
      return {
        ...state,
        UserInfo: {},
        Loggedin: false,
        GuestUser: false,
        Token: '',
        TempID: '',
      };

    case types.Save_Selected_Data:
      return { ...state };

    case types.Save_Selected_Data_Success:
      return { ...state, UserSelectedData: action.payload };

    case types.SET_LANG:
      return {
        ...state,
        UserSelectedData: { ...state.UserSelectedData, Lang: action.payload },
      };

    case types.Save_InvoiceId_Data:
      return { ...state };

    case types.Save_InvoiceId_Data_SUCCESS:
      return { ...state, InvoiceId: action.payload };

    case types.Save_OrderStaus_Data:
      return { ...state };

    case types.Save_OrderStaus_Data_SUCCESS:
      return { ...state, OrderStatus: action.payload };

    default:
      // for persist to work return state not distructure state
      // return {...state}
      return state;
  }
};

export default PersistedReducer;
