import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { GetAllProducts } from '../../../API/APIRequests';
import InfiniteScroll from 'react-infinite-scroll-component';
import StoreItemCard from '../../../Components/StoreItemCard';
import BackBTN from '../../../Components/BackBTN';
import LoadingPlaceholder from '../AllProducts/LoadingPlaceholder';

function Content(props) {
  const { CatId } = useParams();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );

  const [AllVendors, SetAllVendors] = useState([...props.data]);
  const [Pagination, SetPagination] = useState(props.pagination);
  const [HasMore, SetHasMore] = useState(true);
  const [AllVendorsRes, SetAllVendorsRes] = useState({});
  const Limit = 10;

  const HandelGetMoreVendors = () => {
    // console.log(Pagination.meta.page.isNext)

    if (Pagination.meta.page.isNext) {
      GetAllProducts(
        {
          countryID: UserSelectedData.Country.id,
          data: {
            ...(CatId === 'null' ? {} : { category_id: CatId }),
            limit: Limit,
            page: Pagination.meta.page.next,
          },
        },
        (res) => {
          SetAllVendorsRes(res);
        }
      );
    } else {
      SetHasMore(false);
    }
  };

  useEffect(() => {
    if (AllVendorsRes.success) {
      if (
        AllVendorsRes.pagination.meta.page.current !==
        Pagination.meta.page.current
      ) {
        SetAllVendors((prev) => [...prev, ...AllVendorsRes.data]);

        SetPagination(AllVendorsRes.pagination);
      }
    }
  }, [AllVendorsRes]);

  return (
    <div className="my-3">
      <BackBTN />

      <InfiniteScroll
        dataLength={AllVendors.length} //This is important field to render the next data
        next={HandelGetMoreVendors}
        hasMore={HasMore}
        className="grid grid-cols-2 md:grid-cols-3 gap-x-3 gap-y-5 w-full mt-5"
        loader={
          <div className="col-span-2 md:col-span-3">
            <LoadingPlaceholder />
          </div>
        }
        endMessage={
          <div className="col-span-2 md:col-span-3 py-3">
            <p>you viewed all of our vendors :)</p>
          </div>
        }
      >
        {AllVendors.map((itm) => {
          return <StoreItemCard vendor={true} data={itm} />;
        })}
      </InfiniteScroll>
    </div>
  );
}

export default Content;
