import axios from 'axios';

const mainUrl = axios.create({
  // baseURL:'https://form.testbedbynd.com/api/',
  baseURL: 'https://mybusiness.letsform.app/api',
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers':
      'X-Requested-With,Accept,Authentication,Content-Type',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});
const apiUrl = 'https://mybusiness.letsform.app/api/';
const ImgsUrls = 'https://pages-dash.testbedbynd.com/';

export { mainUrl, ImgsUrls, apiUrl };
