import React, { useEffect, useState } from 'react';
import CustomButton from '../../../Components/CustomButton';
import { ReactComponent as GreenRight } from '../../../Assets/Icons/GreenRight.svg';
import { useTranslation } from 'react-i18next';
import GreyLine from '../../../Components/GreyLine';
import { useNavigate } from 'react-router-dom';
import LocationPicker from 'react-location-picker';
import { useDispatch, useSelector } from 'react-redux';
import {
  SaveInvoiceIdAction,
  SaveOrderStatusAction,
  ToggleShowHideAction,
} from '../../../Redux/Actions';
import { StorePlaceOrder } from '../../../API/APIRequests';
import Modal from '@mui/material/Modal';

function Content({ Cart, methods, add }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const persistedState = useSelector((state) => state.PersistedReducer);

  const [PaymentMethod, SetPaymentMethod] = useState('');
  const [ShowMap, SetShowMap] = useState(false);

  const [ProceedToPaymentRes, SetProceedToPaymentRes] = useState({});

  const [SendReqLoading, SetSendReqLoading] = useState(false);

  // display addons of itemms
  const DisplayAddons = (addonarray) => {
    let addons = '';

    for (let i = 0; i < addonarray.length; i++) {
      if (i === addonarray.length - 1) {
        addons = addons.concat('+', addonarray[i].qty, ' ', addonarray[i].name);
      } else {
        addons = addons.concat(
          '+',
          addonarray[i].qty,
          ' ',
          addonarray[i].name,
          ' | '
        );
      }
    }

    return addons;
  };

  const HandelProceedToPayment = () => {
    if (persistedState.GuestUser || persistedState.Loggedin) {
      let PaymentSelected = CheckPaymentMethod();
      if (PaymentSelected) {
        let data = {
          channel: 'web',
          total: Cart.data.total,
          payment_method: PaymentMethod,

          address_id: add.data.id,
          address_type: add.data.type,
          address: add.data.address,
          ...(add.data.area_id ? { area_id: add.data.area_id } : {}),
          ...(add.data.longitude ? { longitude: add.data.longitude } : {}),
          ...(add.data.latitude ? { latitude: add.data.latitude } : {}),

          ...(persistedState.TempID ? { tempId: persistedState.TempID } : {}),

          ...(persistedState.GuestUser
            ? {
                guest_name: persistedState.UserInfo.guest_name,
                guest_phone: persistedState.UserInfo.guest_phone,
                guest_gender: persistedState.UserInfo.guest_gender,
              }
            : {}),

          ...(persistedState.Loggedin
            ? { user_id: persistedState.UserInfo.user.id }
            : {}),
        };
        // console.log(data)

        ProceedPaymentReq(data);
      }
    } else {
      // go to login
      navigate('/Login');
    }
  };

  // check payment is selected
  const CheckPaymentMethod = () => {
    if (PaymentMethod) {
      return true;
    } else {
      dispatch(
        ToggleShowHideAction({
          Open: true,
          Type: 'info',
          Msg: t('Please select a payment method to continue '),
        })
      );
      return false;
    }
  };

  // send req to payment
  const ProceedPaymentReq = (data) => {
    SetSendReqLoading(true);

    StorePlaceOrder(
      {
        countryID: UserSelectedData.Country.id,
        data: data,
      },
      (res) => {
        SetProceedToPaymentRes(res);
      }
    );
  };

  useEffect(() => {
    // console.log(ProceedToPaymentRes)
    if (ProceedToPaymentRes.success === true) {
      if (PaymentMethod === 'cash') {
        // save data in orer status
        dispatch(SaveOrderStatusAction(ProceedToPaymentRes));
        navigate('/appointment/payment/success', { state: { cash: true } });
      } else {
        // save invoice id in redux
        dispatch(SaveInvoiceIdAction(ProceedToPaymentRes.data.invoice_id));
        // empty state before sending req in order status
        dispatch(SaveOrderStatusAction({}));
        window.open(ProceedToPaymentRes.data.url, '_self');
      }
    } else if (ProceedToPaymentRes.success === false) {
      dispatch(
        ToggleShowHideAction({
          Open: true,
          Type: 'error',
          Msg: ProceedToPaymentRes.message,
        })
      );
    }
  }, [ProceedToPaymentRes]);

  return (
    <div>
      {/* blue section */}
      <div className="rounded-lg bg-primary_BG px-3 py-2 text-white mb-5">
        {Cart.data.items.map((itm, idx) => {
          return (
            <div key={idx}>
              <div className="flex justify-between">
                <div className="">
                  <p className="text-start px-px">{itm.item_name}</p>
                  <p className="text-xs text-start mt-px mb-3">
                    {DisplayAddons(itm.item_options)}
                  </p>
                </div>
                <div>
                  <p>{itm.item_price}</p>
                </div>
              </div>
              {idx + 1 !== Cart.data.items.length && (
                <GreyLine className="my-3" />
              )}
            </div>
          );
        })}
      </div>

      {/* payment summary */}
      <p className="text-primary_BG w-fit mb-1">{t('Delivery Details')}</p>

      <div className="rounded-lg bg-gray mb-5 text-sm px-5 py-3">
        <div className="mb--2 rounded-md">
          <div className="flex justify-between mb-1">
            <p className="text-primary_BG">{t('Address 1')}</p>
            <button
              onClick={() => navigate('/DeliveryDetails')}
              className="text-xs bg-slate-200 rounded-full px-2 py-px"
            >
              {t('Change')}
            </button>
          </div>
        </div>
        <div className="text-start">
          <p>
            {Object.entries(add.data.address)
              .map((entry) => entry.join(': '))
              .join(',')}
          </p>
        </div>
        <button
          onClick={() => SetShowMap(true)}
          className="rounded-full py-1 px-2 mt-7 w-full bg-primary_BG text-white"
        >
          {t(' View on Map ')}
        </button>
      </div>

      {/* map */}
      <Modal
        open={ShowMap}
        onClose={() => SetShowMap(false)}
      >
        <div
          className="w-1/2 bg-white p-3"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >

          <LocationPicker
            containerElement={ <div style={ {height: '100%'} } /> }
            mapElement={ <div style={ {height: '400px'} } /> }
            defaultPosition={{ lat: parseFloat(add.data.latitude) , lng: parseFloat(add.data.longitude)}}
          />
        </div>
      </Modal>
      

      {/* select payment method */}
      <div className="mb-5">
        <p className="mb-4">{t('Select your payment method')}</p>

        <div className="flex justify-between  gap-x-2">
          {methods.data.map((itm) => {
            return (
              <div
                onClick={() => SetPaymentMethod(itm.name)}
                key={itm.id}
                className={`flex flex-col items-center justify-center rounded-md cursor-pointer p-5 relative ${
                  PaymentMethod === itm.name && 'bg-gray'
                }`}
              >
                {PaymentMethod === itm.name && (
                  <div className="absolute top-2 ltr:right-2">
                    <GreenRight width="15.763" height="10.44" />
                  </div>
                )}

                <img src={itm.image} className="w-1/2" alt={itm.name} />
                <p className="text-sm">{itm.name}</p>
              </div>
            );
          })}
        </div>
      </div>

      {/* payment summary */}
      <div className="mb-5 text-sm">
        <p className="text-primary_BG w-fit mb-2">{t('Payment summary')}</p>

        <div className="bg-gray px-5 py-3 rounded-lg">
          <div className="flex justify-between mb-5">
            <p>{t('Subtotal')}</p>
            <p>{Cart.data.sub_total}</p>
          </div>

          {UserSelectedData.Country?.tax_status === 1 ? (
            <div class="flex justify-between mb-1">
              <p>{t('Tax')}</p>
              <p>
                {UserSelectedData.Country?.tax}{' '}
                {UserSelectedData.Country?.currency}
              </p>
            </div>
          ) : (
            <></>
          )}

          <div className="flex justify-between mb-1 text-primary_BG">
            <p>{t('Total')}</p>
            <p>{Cart.data.total}</p>
          </div>
        </div>
      </div>

      {/* continue */}

      {SendReqLoading ? (
        <CustomButton
          ContainerClass="flex justify-center bg-InputGray text-white rounded-lg text-sm cursor-pointer py-2 mt-7"
          BtnValue={t('Continue')}
          ButtonClass="flex items-center gap-x-2"
          loading="true"
        />
      ) : (
        <CustomButton
          onclick={HandelProceedToPayment}
          ContainerClass="bg-primary_BG text-white rounded-lg text-sm cursor-pointer py-2 mt-7"
          BtnValue={t('Continue')}
        />
      )}
    </div>
  );
}

export default Content;
