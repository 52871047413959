import React from 'react';
import { ReactComponent as CheckedIcon } from '../../Assets/Icons/SubscriptionCategory/BlueRight.svg';

function ModuleCard({
  onClick = {},
  cardClassName = '',
  ModuleName = '',
  ModuleImg = {},
  active = false,
}) {
  return (
    <div
      onClick={() => onClick()}
      className={
        'relative rounded-xl shadow-HomeCard flex flex-col items-center cursor-pointer p-3 ' +
        cardClassName
      }
    >
      <div className="h-14  mt-3">
        <img
          className="h-full object-contain"
          src={ModuleImg}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = require('../../Assets/Images/img-not-found-thumbNail.png');
          }}
        />
      </div>
      <div className="flex justify-center w-full px-2 pt-1 text-sm">
        <p className="pt-2 text-md">{ModuleName}</p>
      </div>

      {active === true && (
        <div className="absolute ltr:right-2 rtl:left-2 top-2">
          <CheckedIcon />
        </div>
      )}
    </div>
  );
}

export default ModuleCard;
