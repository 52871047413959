import './App.css';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import Home from './Pages/Home/Home';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import Allsports from './Pages/Venues/AllSports/Allsports';
import SportCategory from './Pages/Venues/SportCategory/SportCategory';
import SelectArea from './Pages/SelectArea/SelectArea';
import SelectDate from './Pages/SelectDate/SelectDate';
import AllVenues from './Pages/Venues/AllVenues/AllVenues';
import VenueDetails from './Pages/Venues/Venue Details/VenueDetails';
import NotFound from './Pages/NotFound';
import Login from './Pages/Login/Login';
import GuestLogin from './Pages/GuestLogin/GuestLogin';
import CreateAccount from './Pages/Create Account/CreateAccount';
import Otp from './Pages/OTP/Otp';
import ReviewOrder from './Pages/ReviewOrder/ReviewOrder';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import CustomAlert from './Components/CustomAlert';
import OrderStatus from './Pages/OrderStatus/OrderStatus';
import AccountSettings from './Pages/AccountSettings/AccountSettings';
import OrderHistory from './Pages/OrderHistory/OrderHistory';
import EnterPhone from './Pages/OtpEnterPhone/EnterPhone';
import ClassCategory from './Pages/Classes/ClassCategory/ClassCategory';
import AllClasses from './Pages/Classes/AllClasses/AllClasses';
import ClassType from './Pages/Classes/ClassType/ClassType';
import ClassDetails from './Pages/Classes/ClassDetails/ClassDetails';
import OrderReview from './Pages/Classes/OrderReview/OrderReview';
import ViewReceipt from './Pages/Classes/ViewReceipt/ViewReceipt';
import SubscriptionCategory from './Pages/Subscriptions/SubscriptionCategory/SubscriptionCategory';
import SubscriptionTypes from './Pages/Subscriptions/SubscriptionTypes/SubscriptionTypes';
import AllSubscriptions from './Pages/Subscriptions/AllSubscriptions/AllSubscriptions';
import ViewSubscription from './Pages/Subscriptions/ViewSubscription/ViewSubscription';
import SubscriptionDetails from './Pages/Subscriptions/SubscriptionDetails/SubscriptionDetails';
import StoreHome from './Pages/Store/StoreHome/StoreHome';
import AllProducts from './Pages/Store/AllProducts/AllProducts';
import AllVendors from './Pages/Store/AllVendors/AllVendors';
import VendorDetails from './Pages/Store/VendorDetails/VendorDetails';
import ProductDetails from './Pages/Store/ProductDetails/ProductDetails';
import ShoppingCart from './Pages/Store/ShoppingCart/ShoppingCart';
import StaticPages from './Pages/StaticPages/StaticPages';
import ReviewOrderStore from './Pages/Store/ReviewOrder/ReviewOrderStore';
import DeliveryDetails from './Pages/Store/DeliveryDetails/DeliveryDetails';

function App() {
  const navigate = useNavigate();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const LoggedIn = useSelector((state) => state.PersistedReducer.Loggedin);
  const GuestUser = useSelector((state) => state.PersistedReducer.GuestUser);
  const ShowAlert = useSelector((state) => state.MainReducer.ShowAlert);

  // useEffect(()=>{
  //   window.location.reload(true)
  // },[online])

  Sentry.init({
    dsn: 'https://85966980bef34be09fc1f8cd25a19c1c@o461386.ingest.sentry.io/4504175260401664',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  useEffect(() => {
    // if counter not selected go home
    if (UserSelectedData.Country) {
    } else {
      navigate('/home');
    }
  }, [UserSelectedData.Country]);

  return (
    <div className="App">
      {ShowAlert.Open && <CustomAlert />}

      <Routes>
        {/* <Route path='/' element={<Splash/>}></Route> */}
        <Route path="/" element={<Navigate to="/home" replace />} />
        <Route path="/home" element={<Home />} />
        <Route path="/sports" element={<SportCategory />} cat="Sports" />
        <Route path="/SelectSport" element={<Allsports />} cat="Sports" />
        <Route path="/SelectArea" element={<SelectArea />} />
        <Route path="/SelectDate" element={<SelectDate />} />
        <Route path="/Venues" cat="Sports" element={<AllVenues />} />
        <Route
          path="/Venues/:VenueId"
          element={<VenueDetails />}
          cat="Sports"
        />
        <Route path="/Login" element={<Login />} />
        <Route path="/GuestLogin" element={<GuestLogin />} />
        <Route path="/CreateAccount" element={<CreateAccount />} />
        <Route path="/OTP/Code" element={<Otp />} />
        <Route path="/OTP/PhoneNumber" element={<EnterPhone />} />
        <Route
          path="/ReviewOrder"
          element={LoggedIn || GuestUser ? <ReviewOrder /> : <Login />}
        />
        {/* /api/payment/success    , /api/payment/failed */}
        <Route path="/appointment/payment/:Status" element={<OrderStatus />} />
        <Route
          path="/AccountSettings"
          element={LoggedIn ? <AccountSettings /> : <Login />}
        />
        <Route
          path="/OrderHistory"
          element={LoggedIn ? <OrderHistory /> : <Login />}
        />
        {/* class routing */}
        <Route path="/classes" cat="Classes" element={<ClassCategory />} />
        <Route path="/ClassTypes" cat="Classes" element={<ClassType />} />
        <Route path="/AllClassses" element={<AllClasses />} />
        <Route path="/ClassDetails/:id" element={<ClassDetails />} />
        <Route
          path="/OrderReview"
          element={LoggedIn || GuestUser ? <OrderReview /> : <Login />}
        ></Route>
        <Route
          path="/Receipt"
          element={LoggedIn || GuestUser ? <ViewReceipt /> : <Login />}
        />
        {/* subscriprtion */}
        <Route path="/Subscriptions" element={<SubscriptionCategory />} />
        <Route path="/SubscriptionTypes" element={<SubscriptionTypes />} />
        <Route path="/AllSubscriptions" element={<AllSubscriptions />} />
        <Route path="/AllSubscriptions/:id" element={<ViewSubscription />} />
        <Route path="/SubscriptionDetails" element={<SubscriptionDetails />} />
        {/* Store */}
        <Route path="/Store" element={<StoreHome />} />
        <Route path="/AllProducts/:CatId" element={<AllProducts />} />
        <Route path="/AllVendors/:CatId" element={<AllVendors />} />
        <Route path="/ProductDetails/:id" element={<ProductDetails />} />
        <Route path="/VendorDetails/:id" element={<VendorDetails />} />
        <Route path="/about" element={<StaticPages />} />
        <Route path="/terms" element={<StaticPages />} />
        <Route path="/ShoppingCart" element={<ShoppingCart />} />
        <Route path="/DeliveryDetails" element={<DeliveryDetails />} />
        <Route path="/ReviewOrder/:Cat" element={<ReviewOrderStore />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
