import React from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../../../Components/Footer';
import Header from '../../../Components/Header';
import Content from './Content';
import MainLayout from '../../../Components/MainLayout';

function ClassCategory() {
  return (
    <MainLayout Cat='Classes'>
      <div className="w-11/12 lg:w-1/3 sm:w-1/2 mt-5 grow">
        <Content />
      </div>
    </MainLayout>
  );
}

export default ClassCategory;
