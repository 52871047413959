import { types } from '../ActionTypes';

const initialState = {
  ShowAlert: {},
  Cart: [],
  CartChanged: false,
};

const MainReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.Toggle_Hide_Show_Alert:
      return { ...state };

    case types.Toggle_Hide_Show_Alert_SUCCESS:
      return { ...state, ShowAlert: action.payload };

    case types.Save_Cart:
      return { ...state };

    case types.Save_Cart_SUCCESS:
      return { ...state, Cart: action.payload };

    case types.Set_Cart_Change_Flag:
      return { ...state };

    case types.Set_Cart_Change_Flag_SUCCESS:
      console.log(action.payload);
      return { ...state, CartChanged: action.payload };

    default:
      // for persist to work return state not distructure state
      // return {...state}
      return state;
  }
};

export default MainReducer;
