import React, { useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Content from './Content';

function SelectSubscriptionDate({ open, onclose }) {
  const [IsOpen, SetIsOpen] = useState(open);

  useEffect(() => {
    SetIsOpen(open);
  }, [open]);

  useEffect(() => {
    console.log('isoen', IsOpen);
  }, [IsOpen]);

  const HandelOnClose = () => {
    SetIsOpen(false);
    onclose();
  };

  return (
    <Drawer
      xs={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
      anchor="bottom"
      open={IsOpen}
      onClose={() => {
        HandelOnClose();
      }}
    >
      <Content closeDrawer={HandelOnClose} />
    </Drawer>
  );
}

export default SelectSubscriptionDate;
