import React from 'react';
import { useTranslation } from 'react-i18next';
import BackBTN from '../../../Components/BackBTN';
import ClassOrderDetails from './ClassOrderDetails';
import { useSelector } from 'react-redux';
import SubscriptionOrderReview from './SubscriptionOrderReview';

function Content(props) {
  const { t } = useTranslation();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );

  return (
    <div>
      <BackBTN
        text={
          <div className="flex justify-center">
            <p className="w-1/2">{t('Review booking info')}</p>
          </div>
        }
      />

      {/* blue section and payment section */}
      {UserSelectedData.Category === 'Classes' && (
        <ClassOrderDetails Details={props.OrderReview} />
      )}

      {UserSelectedData.Category === 'Subscriptions' && (
        <SubscriptionOrderReview Details={props.OrderReview} />
      )}
    </div>
  );
}

export default Content;
