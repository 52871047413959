import React from 'react';

export default function SectionTitle({ icon = {}, title = '' }) {
  return (
    <div className="flex items-center mb-2">
      {icon}
      <p className="text-primary_BG px-1 text-sm">{title}</p>
    </div>
  );
}
