import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../../Components/Header';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import Content from './Content';
import { useSelector } from 'react-redux';
import SubscriptionReceipt from './SubscriptionReceipt';
import CustomButton from '../../../Components/CustomButton';
import StoreReceipt from './StoreReceipt';
import MainLayout from '../../../Components/MainLayout';

function ViewReceipt(props) {
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );
  const ReceiptData = location.state ? location.state.Data : {};

  useEffect(() => {
    if (ReceiptData.success) {
    } else {
      navigate('/OrderReview');
    }
  }, []);

  return (
    <MainLayout showFooter={false}>
      {ReceiptData.success ? (
        <div className="w-11/12 lg:w-1/3 sm:w-1/2 mt-5 grow">
          {UserSelectedData.Category === 'Classes' ? (
            <Content data={ReceiptData} />
          ) : UserSelectedData.Category === 'Subscriptions' ? (
            <SubscriptionReceipt data={ReceiptData} />
          ) : (
            UserSelectedData.Category === 'Store' && (
              <StoreReceipt data={ReceiptData} />
            )
          )}
          <CustomButton
            onclick={() => navigate('/home')}
            ContainerClass="bg-primary_BG text-white rounded-lg text-sm cursor-pointer py-1 my-7"
            BtnValue={t('Home')}
          />
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </MainLayout>
  );
}

export default ViewReceipt;
