import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GetAllVenues } from '../../../API/APIRequests';
import BackBTN from '../../../Components/BackBTN';
import Footer from '../../../Components/Footer';
import Header from '../../../Components/Header';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import { CheckDateIsNotPassed, ModifyDateFormat } from '../../../Utils/Utils';
import Content from './Content';
import MainLayout from '../../../Components/MainLayout';

export default function AllVenues() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [venuesData, SetvenuesData] = useState({});
  const UserSelectedData = useSelector(
    (state) => state.PersistedReducer.UserSelectedData
  );

  useEffect(() => {
    if (UserSelectedData.Country) {
      if (UserSelectedData.SelectedSport && UserSelectedData.SelectedDate) {
        // console.log(UserSelectedData)
        let DateIsPassed = CheckDateIsNotPassed(UserSelectedData.SelectedDate);

        if (DateIsPassed === true) {
          navigate('/SelectDate');
        } else {
          // modify date format
          let date = ModifyDateFormat(UserSelectedData.SelectedDate);
          // console.log(date)

          GetAllVenues(
            {
              country: UserSelectedData.Country.id,
              date: date,
              sport: UserSelectedData.SelectedSport.id,
              ...(UserSelectedData.SelectedArea
                ? { area: UserSelectedData.SelectedArea.id }
                : {}),
            },
            (res) => {
              SetvenuesData(res);
            }
          );
        }
      } else {
        navigate('/sports');
      }
    } else {
      navigate('/home');
    }
  }, [i18n.language]);

  useEffect(() => {
    console.log(venuesData);
  }, [venuesData]);

  return (
    <MainLayout Cat='Sports'>
      {venuesData.success ? (
        <div className="grow mt-5 w-11/12 lg:w-1/3 sm:w-1/2">
          {venuesData.data.length === 0 ? (
            <div>
              <BackBTN />
              <p className="py-5">No Venues Found</p>
            </div>
          ) : (
            <Content venuesData={venuesData} />
          )}
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </MainLayout>
  );
}
